import {
  AfterViewInit,
  Component,
  HostListener,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { cartoncartname, cartmodel } from "src/app/model/file/cartmodel";
import { AgencyService } from "src/app/services/files/agency.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ImageuploadService } from "src/app/services/files/imageupload.service";
import { userformmodel } from "src/app/model/master/user.form.model";
import { UserserviceService } from "src/app/services/masters/userservice.service";
import { ConfirmationService, MessageService } from "primeng/api";
import { environment } from "src/environments/environment";
import { ThrowStmt } from "@angular/compiler";
import { loadAsync } from "jszip";
import { LoginService } from "src/app/services/login/login.service";
import { HrcornerService } from "src/app/services/files/hrcorner.service";
import { PermissionModel } from "src/app/model/master/Permission.model";
import { DashboardComponent } from "../dashboard/dashboard.component";
import { DashboardService } from "src/app/services/files/dashboard.service";
import { ChangeDetectorRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { WebSocketService } from "src/app/services/files/web-socket.service";

@Component({
  selector: "app-top-header",
  templateUrl: "./top-header.component.html",
  styleUrls: ["./top-header.component.css"],
})
export class TopHeaderComponent implements OnInit {
  loginuser: string;
  downloadimg: boolean = false;
  cartfinallist: cartoncartname[] = [];
  cartlist: cartmodel[] = [];
  showcartbox: boolean = false;
  results: string[] = [];
  filteredBrands: any[];
  assigntoid: string = "null";
  dataforassign: cartmodel[];
  selectedCars3: cartmodel[];
  assignpopup: boolean = false;
  profileimg: string = "";
  cols = [
    { field: "cartname", header: "Name" },
    //{ field: 'itemname', header: 'Item Name' },
  ];
  userlist: userformmodel[] = [];
  viamail: boolean = false;
  frommail: string = "";
  frompass: string = ""; //environment.mailid
  tomail: string = "";
  subject: string = "";
  link: string = "";
  linkid: string = "";
  showprocesslist: boolean = false;
  processlist: any[] = [];
  exportlist: any[] = [];
  processlowreslist: any[] = [];
  processloglist: any[] = [];
  selectedlist: string = "";
  timer: any = null;
  approvelist: any[] = [];
  clientDetails: any;
  user: any;
  licenceDate: any;
  machineId: any;
  client: any;
  uploadingFileListHR: any[] = [];
  uploadingFileStatusHR: any;
  permissionlist: PermissionModel[];
  hrCollection: number = 0;
  VideoArchive: number = 0;
  rejectedFilesList: any[] = [];
  pendingListLength: number;
  completedListLength: number;
  pendingexportlistLength: number;
  completedexportlistLength: number;
  TranscodeeportlistLength: number;
  pendingprocessLength: number;
  completedProcessLength: number;
  pendingLowresLength: number;
  completedLowresLength: number;
  ErroreportlistLength: number;
  ErrorProcessLength: number;
  processStatus: any;
  processlowresStatus: any;
  ErrorLowresLength: any;
  approveStatus: any;
  ErrorListLength: any;
  exportStatus: any;
  isHovered = false;
  activeSpan: string = "";
  videoprocessCount: number;
  audioprocessCount: number;
  documentprocessCount: number;
  imageprocessCount: number;
  activeTabIndex = 0;
  showHrFilesList: any;
  ApiServerPath: any;
  constructor(
    private confirmationService: ConfirmationService,
    private loginservice: LoginService,
    private authservice: AuthService,
    private agencyser: AgencyService,
    private hrcornerService: HrcornerService,
    private messageservice: MessageService,
    public router: Router,
    private imageservice: ImageuploadService,
    private userser: UserserviceService,
    private dashboardService: DashboardService,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    private websocketser: WebSocketService
  ) {

    this.dashboardService.customcmShow().subscribe((data:any) =>{
      this.allTaskIds = data;
      const isMediaManager = this.allTaskIds.some((task: any) => task.taskid === '200');
      this.websocketser.onMessage((data:any) =>{
        if(data == 'DownloadReqauestCounter'){
          if(isMediaManager){
            this.getNotificationApprovalforManager();
            // this.updateApprovalStatus();
          }
        } else {
          console.error("Download Request counter not updated")
        }
      });
    });

    
    
    this.dashboardService.NotificationEmitter.subscribe((data)=>{
      this.getNotificationApprovalforManager();
      this.getNotificationApprovalforUser();
    });
  }

  // ngOnChanges(changes: SimpleChanges){
  //   this.hrcornerService.fileStatus.subscribe((data:any)=>{
  //     console.log(data);

  //     console.log("uploadingFileListHR",this.uploadingFileListHR);
  //   })
  // }

  ngOnInit() {
    this.loginuser = JSON.parse(
      localStorage.getItem("userdetails")
    )[0].UserCompleteName;
    const savedApiServerPath = sessionStorage.getItem("ApiServerPath");
    if (savedApiServerPath) {
      this.ApiServerPath = savedApiServerPath;
    }
    this.profileimg =
      this.ApiServerPath +
      JSON.parse(localStorage.getItem("userdetails"))[0].UserImagePath;
    this.getuserdata();
    this.search();
    this.clientDetail();
    this.userser.updateProfileImage.subscribe((data) => {
      // console.log("update profile data",data);
      this.profileimg = data;
    });
    //uncomment this when build for india today//
    this.frommail = environment.mailid;
    //uncomment this when build for india today//

    this.uploadListHR();
    this.getNotificationApprovalforManager();
    this.getNotificationApprovalforUser();
    this.getAllTaskforUser(false);

    // getting status of file from HR Module after uploading and inserting data
    // this.hrcornerService.fileStatus.subscribe((data:any)=>{
    //   console.log(data);
    //   // this.uploadingFileStatusHR= data
    //   this.uploadingFileListHR = this.uploadingFileListHR.shift()
    //   console.log("this.uploadingFileListHR",this.uploadingFileListHR);

    //   // this.uploadingFileStatusHR= data.filename
    //   // this.uploadingFileListHR = this.uploadingFileListHR.filter(data=> data.filename != this.uploadingFileStatusHR)
    // })
    this.checkpermissions();
  }

  //logout from the main page to login page
  Logout() {
    this.authservice.logout();
  }

  uploadListHR() {
    //getting uploading file list from HR Module
    this.hrcornerService.uploadFilesName.subscribe((data: any) => {
      //   console.log(data);
      this.uploadingFileListHR = data;
      if (this.uploadingFileListHR.length > 0) {
        for (let index = 0; index < this.uploadingFileListHR.length; index++) {
          setTimeout(() => {
            // console.log("hit");
            this.uploadingFileListHR = this.uploadingFileListHR.slice(1);
            // console.log("this.uploadingFileListHR",this.uploadingFileListHR);
          }, 15000);
        }
      }
    });
  }

  // ngAfterViewInit() {

  // }

  // @HostListener('window:beforeunload', [ '$event' ])
  // beforeUnloadHandler(event) {
  //   console.log(event);
  //     // this.Logout();
  // }

  //accessing client detail

  //show cart list
  showcartlist() {
    this.cartfinallist = [];
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.agencyser.bindcart(userid).subscribe((data: any[]) => {
      if (data != null) {
        this.cartlist = data;
        this.showcartbox = true;
      }
    });
  }

  //show processed files list
  showprocessfiles(status: any) {
    this.activeSpan = status;
    this.processStatus = status;
    this.imageservice.bindprocessfiles().subscribe((data: any[]) => {
      if (data != null) {
        // this.processlist = data;
        this.showprocesslist = true;
        this.pendingprocessLength = data.filter(
          (item) =>
            item.processfile === "N" ||
            item.processfile === "T" ||
            item.processfile === "P" ||
            item.processfile === "C"
        ).length;
        this.completedProcessLength = data.filter(
          (item) => item.processfile === "Y"
        ).length;
        this.ErrorProcessLength = data.filter(
          (item) => item.processfile === "D" || item.processfile === "E"
        ).length;

        this.videoprocessCount = data.filter(
          (item) =>
            item.itemtype === "video" &&
            (item.processfile === "N" ||
              item.processfile === "T" ||
              item.processfile === "P" ||
              item.processfile === "C")
        ).length;
        this.audioprocessCount = data.filter(
          (item) =>
            item.itemtype === "audio" &&
            (item.processfile === "N" ||
              item.processfile === "T" ||
              item.processfile === "P" ||
              item.processfile === "C")
        ).length;
        this.documentprocessCount = data.filter(
          (item) =>
            item.itemtype === "documents" &&
            (item.processfile === "N" ||
              item.processfile === "T" ||
              item.processfile === "P" ||
              item.processfile === "C")
        ).length;
        this.imageprocessCount = data.filter(
          (item) =>
            item.itemtype === "image" &&
            (item.processfile === "N" ||
              item.processfile === "T" ||
              item.processfile === "P" ||
              item.processfile === "C")
        ).length;

        if (status == "Pending") {
          this.processlist = data.filter(
            (item) =>
              item.processfile === "N" ||
              item.processfile === "T" ||
              item.processfile === "P" ||
              item.processfile === "C"
          );
        } else if (status == "Complete") {
          this.processlist = data.filter((item) => item.processfile === "Y");
        } else if (status == "Error") {
          this.processlist = data.filter(
            (item) => item.processfile === "D" || item.processfile === "E"
          );
        } else if (status == "Image") {
          this.processlist = data.filter(
            (item) =>
              item.itemtype === "image" &&
              (item.processfile === "N" ||
                item.processfile === "T" ||
                item.processfile === "P" ||
                item.processfile === "C")
          );
        } else if (status == "Document") {
          this.processlist = data.filter(
            (item) =>
              item.itemtype === "documents" &&
              (item.processfile === "N" ||
                item.processfile === "T" ||
                item.processfile === "P" ||
                item.processfile === "C")
          );
        } else if (status == "Video") {
          this.processlist = data.filter(
            (item) =>
              item.itemtype === "video" &&
              (item.processfile === "N" ||
                item.processfile === "T" ||
                item.processfile === "P" ||
                item.processfile === "C")
          );
        } else if (status == "Audio") {
          this.processlist = data.filter(
            (item) =>
              item.itemtype === "audio" &&
              (item.processfile === "N" ||
                item.processfile === "T" ||
                item.processfile === "P" ||
                item.processfile === "C")
          );
        }
      }
    });
  }

  //show export files list
  showExportfiles(status: any) {
    this.activeSpan = status;
    this.exportStatus = status;
    this.imageservice.bindexportfiles().subscribe((data: any[]) => {
      if (data != null) {
        // this.exportlist = data;
        this.pendingexportlistLength = data.filter(
          (item) => item.status === "N" || item.status === "P"
        ).length;
        this.completedexportlistLength = data.filter(
          (item) => item.status === "Y"
        ).length;
        this.TranscodeeportlistLength = data.filter(
          (item) => item.status === "T"
        ).length;
        this.ErroreportlistLength = data.filter(
          (item) => item.status === "E" || item.status === "D"
        ).length;
        if (status == "Pending") {
          this.exportlist = data.filter(
            (item) => item.status === "N" || item.status === "P"
          );
        } else if (status == "Complete") {
          this.exportlist = data.filter((item) => item.status === "Y");
        } else if (status == "Transcoding") {
          this.exportlist = data.filter((item) => item.status === "T");
        } else if (status == "Error") {
          this.exportlist = data.filter(
            (item) => item.status === "E" || item.status === "D"
          );
        }
      }
    });
  }




  //show process dialog box
  showprocessdialog() {
    this.showprocessfiles("Pending");
    this.stopTimer();
    // this.timer = setInterval(() => { this.showprocessfiles('Pending') }, 5000);
  }

  downloadSelectedImage(filename, filepath, itemid){
    // const basePath = 'http://192.168.1.185/mams/';
    // const updatedFilePath = filepath.toLowerCase().replace(basePath, '');
    
    // console.log("download selected image",filename,filepath,itemid);
    
    var obj = {
      filename: filename,
      filepath: filepath,
      itemid: itemid
    }
    this.dashboardService.downloadSelectedImage(obj);
  }

  imageHiresPreview: boolean = false;
  HiresimageSrc:any;
  basepathh = environment.CommpanyBgImagePath;
  previewHiresImage(imagePath){
    // const basePath = 'http://192.168.1.185/mams/';
    // const updatedFilePath = filepath.toLowerCase().replace(basePath, '');
    // console.log("download selected image",updatedFilePath);

    this.HiresimageSrc = imagePath; 
    this.imageHiresPreview = true;
    this.displayItemApprovalDialog = false;
    this.displayApprovalDialog = false;
  }
  
  onCloseHiresPreview(): void {
    const isMediaManager = this.allTaskIds.some((task: any) => task.taskid === '200');
    this.imageHiresPreview = false; 
    if(isMediaManager){
      this.displayApprovalDialog = true;
    } else {
      this.displayItemApprovalDialog = true;
    }
  }




displayApprovalDialog: boolean = false;
displayItemApprovalDialog: boolean = false;
badgeValue: number = 0;
listofApprovals:any[] = [];

// NotificationCountManager: number;

NotificationCount: number;
getNotificationApprovalforManager() {
  this.imageservice.getNotificationApprovalforManager().subscribe((data: any[]) => {
    this.listofApprovals = data;
    this.NotificationCount = this.listofApprovals.length;
    // console.log("list of image approvalss",this.listofApprovals);
  });
}


formatActionTime(action_time: string | null): string {
  if (!action_time) {
    return 'Pending Time';
  }
  const formattedTime = this.datePipe.transform(action_time, 'shortTime');
  const formattedDate = this.datePipe.transform(action_time, 'mediumDate');
  return `${formattedDate}  ${formattedTime}`;
}


listOfImagesforUser:any[]=[];
listOfpendingImages:any[]=[];
getNotificationApprovalforUser() {
  this.imageservice.getNotificationApprovalforUser().subscribe((data: any[]) => {
    this.listOfImagesforUser = data;
    this.listOfpendingImages = data.filter(image => image.status === 'P');
    this.NotificationCount = this.listOfpendingImages.length;
    // console.log("list of image approvals for normal user",this.listOfImagesforUser,"and",this.listOfpendingImages);
  });
}

allTaskIds:any;
getAllTaskforUser(openDialog:boolean = true){
  this.dashboardService.customcmShow().subscribe((data:any) =>{
    this.allTaskIds = data;

    const isMediaManager = this.allTaskIds.some((task: any) => task.taskid === '200');
    // console.log("is media manager in all tasks in user",isMediaManager);
    
    // console.log("all task ids",this.allTaskIds);
    if(isMediaManager){
      this.getNotificationApprovalforManager();
      if(openDialog){
        this.displayApprovalDialog = true;
      }
    } else {
      this.getNotificationApprovalforUser();
      if(openDialog){
        this.displayItemApprovalDialog = true;
      }
    }
  });
}

displayRejectDialog: boolean = false;
rejectionRemark: string = '';
helper: any;
imgtranid:any;

updateApprovalStatus(transaction_id: string, status: string,imageId:string) {
  if(status === 'R'){
    this.displayRejectDialog = true;
    this.helper = imageId;
    this.imgtranid = transaction_id;
    return;
  }
  if(status=='submit'){
    this.helper="";
    this.displayRejectDialog = false;
    status='R';
  }
  // this.rejectionRemark = '';
  // console.log("rejection remark",this.rejectionRemark);
  // var tid = this.
  
  this.imageservice.updateImageApprovalStatus(transaction_id, status,this.rejectionRemark).subscribe((response) => { 
    // console.log("aprroval status updated",response);
    
    if(status == 'A'){
      this.messageservice.add({
        key: "message",
        severity: "success",
        summary: "Success",
        detail: `Image Approved`,
    });
  } else if (status == 'R'){
      this.messageservice.add({
        key: "message",
        severity: "success",
        summary: "Success",
        detail: `Image Rejected`,
    });
    }
    this.listofApprovals = this.listofApprovals.filter(approval => approval.transaction_id !== transaction_id);
    this.cdr.markForCheck();
    this.getNotificationApprovalforManager();
    // var tempstatus = ""
    // if (status == "A"){
    //   tempstatus = "Image Approved"
    // } else if (status == "R"){
    //   tempstatus = "Image rejected"
    // }
    // this.dashboardService.createlog(
    //   imageId,
    //   tempstatus,
    //   JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
    //   "imageapproval"
    // ).subscribe();

  }, (error) => {
    console.error("Failed to update image status:", error);
  });
}


  //show panel data
  showpaneldata(e) {
    if (e.index == 0) {
      this.showprocessfiles("Pending");
      this.stopTimer();
      // console.log(this.processfilesCompeted);
      this.timer = setInterval(() => {
        if (this.processStatus == "Pending") {
          this.showprocessfiles("Pending");
        } else if (this.processStatus == "Complete") {
          this.showprocessfiles("Complete");
        } else if (this.processStatus == "Error") {
          this.showprocessfiles("Error");
        }
      }, 5000);
    } else if (e.index == 1) {
      this.showprocesslowresfiles("Pending");
      this.stopTimer();
      this.timer = setInterval(() => {
        if (this.processlowresStatus == "Pending") {
          this.showprocesslowresfiles("Pending");
        } else if (this.processlowresStatus == "Complete") {
          this.showprocesslowresfiles("Complete");
        } else if (this.processlowresStatus == "Error") {
          this.showprocesslowresfiles("Error");
        }
      }, 5000);
    } else if (e.index == 2) {
      this.showapprovefiles("Pending");
      this.stopTimer();
      this.timer = setInterval(() => {
        if (this.approveStatus == "Pending") {
          this.showapprovefiles("Pending");
        } else if (this.approveStatus == "Complete") {
          this.showapprovefiles("Complete");
        } else if (this.approveStatus == "Error") {
          this.showapprovefiles("Error");
        }
      }, 5000);
    } else if (e.index == 3) {
      this.showExportfiles("Pending");
      this.stopTimer();
      this.timer = setInterval(() => {
        if (this.exportStatus == "Pending") {
          this.showExportfiles("Pending");
        } else if (this.exportStatus == "Complete") {
          this.showExportfiles("Complete");
        } else if (this.exportStatus == "Transcoding") {
          this.showExportfiles("Transcoding");
        } else if (this.exportStatus == "Error") {
          this.showExportfiles("Error");
        } else {
        }
      }, 5000);
    } else if (e.index == 4) {
      this.showHrFilesListData();
      this.stopTimer();
      // this.timer = setInterval(() => { this.showHrFilesListData() }, 5000);
    } else if (e.index == 5) {
      this.showRejectedFiles();
      this.stopTimer();
      // this.timer = setInterval(() => { this.showRejectedFiles() }, 5000);
    } else {
      this.stopTimer();
      this.showprocesslogiles();
    }
  }
  setActiveTabIndex(index: number) {
    this.activeTabIndex = index;
  }
  stopTimer() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  refreshprosseslist() {
    this.showprocesslogiles();
  }

  //show processed log files list
  showprocesslogiles() {
    this.imageservice.bindprocesslogfiles().subscribe((data: any[]) => {
      if (data != null) {
        this.processloglist = data;
      }
    });
  }

  //show processed lowres files list
  showprocesslowresfiles(status: any) {
    this.activeSpan = status;
    this.processlowresStatus = status;
    this.imageservice.bindprocesslowresfiles().subscribe((data: any[]) => {
      if (data != null) {
        // this.processlowreslist = data;
        this.pendingLowresLength = data.filter(
          (item) => item.lowres_status === "N" || item.lowres_status === "P"
        ).length;
        this.completedLowresLength = data.filter(
          (item) => item.lowres_status === "Y"
        ).length;
        this.ErrorLowresLength = data.filter(
          (item) => item.lowres_status === "E"
        ).length;
        if (status == "Pending") {
          this.processlowreslist = data.filter(
            (item) => item.lowres_status === "N" || item.lowres_status === "P"
          );
        } else if (status == "Complete") {
          this.processlowreslist = data.filter(
            (item) => item.lowres_status === "Y"
          );
        } else if (status == "Error") {
          this.processlowreslist = data.filter(
            (item) => item.lowres_status === "E"
          );
        }
      }
    });
  }

  //show list of approve files list
  showapprovefiles(status: any) {
    this.activeSpan = status;
    this.approveStatus = status;
    this.imageservice.bindapporvefiles().subscribe((data: any[]) => {
      if (data != null) {
        // this.approvelist = data;
        this.pendingListLength = data.filter(
          (item) => item.sendstatus === "N" || item.sendstatus === "P"
        ).length;
        this.completedListLength = data.filter(
          (item) => item.sendstatus === "Y"
        ).length;
        this.ErrorListLength = data.filter(
          (item) => item.sendstatus === "E"
        ).length;
        if (status == "Pending") {
          this.approvelist = data.filter(
            (item) => item.sendstatus === "N" || item.sendstatus === "P"
          );
        } else if (status == "Complete") {
          this.approvelist = data.filter((item) => item.sendstatus === "Y");
        } else if (status == "Error") {
          this.approvelist = data.filter((item) => item.sendstatus === "E");
        }
      }
    });
  }

  showRejectedFiles() {
    this.hrcornerService.showRejectedFilesAPI().subscribe((data: any) => {
      // console.log('',data);
      this.rejectedFilesList = data;
    });
  }

  showHrFilesListData() {
    this.hrcornerService.showHrFilesListAPI().subscribe((data: any) => {
      this.showHrFilesList = data;
    });
  }

  downloadRejectedFile(file_name: any) {
    this.hrcornerService.downloadRejectedFile(file_name).subscribe((data) => {
      // if(data.length>0){
      // console.log(data.size);

      this.messageservice.add({
        key: "message",
        severity: "success",
        summary: "Success Message",
        detail: "Download Successfully",
      });
      saveAs(data, file_name);
      // }
    });
  }

  deleteRejectedFile(itemid: any) {
    this.hrcornerService.deleteRejectedFile(itemid).subscribe((data) => {
      if (data.length > 0) {
        this.messageservice.add({
          key: "message",
          severity: "success",
          summary: "Success Message",
          detail: "Delete Successfully",
        });
        this.showRejectedFiles();
      }
    });
  }

  //get cart data
  getcartdata(id) {
    this.showcartbox = false;
    this.router.navigate([
      "/cart",
      id + "~" + JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
    ]);
  }

  //remove cart from cart list
  removefromcart(cartdata) {
    this.imageservice.removefromcart(cartdata).subscribe((data: any[]) => {
      this.showcartlist();
    });
  }
  hidecartbox() {
    this.showcartbox = false;
    this.selectedCars3 = null;
  }

  search() {
    this.results = [];
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.imageservice.getResults(userid).subscribe((data: any[]) => {
      if (data != null) {
        for (let i = 0; i < data.length; i++) {
          this.results.push(data[i].cartname);
        }
      }
    });
  }

  filterBrands(event) {
    this.filteredBrands = [];
    for (let i = 0; i < this.results.length; i++) {
      let brand = this.results[i];
      if (brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        this.filteredBrands.push(brand);
      }
    }
  }
  onRowReorder(e) {
    var valid = this.processlist;
    // console.log(this.processlist);
    this.imageservice
      .reorderprocesslist(this.processlist)
      .subscribe((data: any[]) => {
        if (data != null) {
          this.messageservice.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail: "Assign successfully",
          });
        }
      });
  }
  onRowReorderDownloadList(e) {
    // console.log('sujeet',event)
    var valid = this.exportlist;
    this.imageservice
      .onRowReorderDownloadList(this.exportlist)
      .subscribe((data: any[]) => {
        if (data != null) {
          this.messageservice.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail: "Priority changed successfully",
          });
        }
      });
  }
  onRowReorderApprove(e) {
    this.imageservice
      .onRowReorderApprove(this.approvelist)
      .subscribe((data: any[]) => {
        if (data != null) {
          this.messageservice.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail: "Priority changed successfully",
          });
        }
      });
  }
  onRowReorderlowres(e) {
    var valid = this.processlowreslist;

    this.imageservice
      .reorderlowreslist(this.processlowreslist)
      .subscribe((data: any[]) => {
        if (data != null) {
          this.messageservice.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail: "Assign successfully",
          });
        }
      });
  }

  copyToClipboard(text: string): void {
    try {
      if (text.length > 0) {
        const tempTextArea = document.createElement("textarea");
        tempTextArea.value = text.replace(/<br>/g, "\n");
        document.body.appendChild(tempTextArea);

        tempTextArea.select();
        document.execCommand("copy");
        document.body.removeChild(tempTextArea);
        this.messageservice.add({
          key: "message",
          severity: "info",
          detail: "Filename Copied",
        });
      }
    } catch {}
  }
  //delete duplicate files list
  dupfiledelete(id, name) {
    this.confirmationService.confirm({
      message: "Are you sure that you want to Delete?",
      header: "Confirmation1",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.imageservice
          .deleteduplicatelist(id, name)
          .subscribe((data: any[]) => {
            if (data != null) {
              this.messageservice.add({
                key: "message",
                severity: "success",
                summary: "Success Message",
                detail: "Delete successfully",
              });
              this.showprocessfiles(this.processStatus);
            }
          });
      },
      reject: () => {},
    });
  }
  Downloaddelete(id) {
    this.confirmationService.confirm({
      message: "Are you sure that you want to Delete?",
      header: "Delete",
      key: "topHeader",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.imageservice.Downloaddeletelist(id).subscribe((data: any[]) => {
          // console.log(data);
          if (data != null) {
            this.messageservice.add({
              key: "message",
              severity: "success",
              summary: "Success Message",
              detail: "Delete successfully",
            });
            this.showExportfiles("Pending");
            this.stopTimer();
            this.timer = setInterval(() => {
              this.showExportfiles("Pending");
            }, 5000);
          }
        });
      },
      reject: () => {
        this.messageservice.add({
          key: "message",
          severity: "warn",
          summary: "Cancelled",
          detail: "You have cancelled",
        });
      },
    });
  }
  dupapprovedelete(id, name) {
    this.confirmationService.confirm({
      message: "Are you sure that you want to Delete?",
      header: "Confirmation1",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.imageservice
          .deleteapprovelist(id, name)
          .subscribe((data: any[]) => {
            if (data != null) {
              this.messageservice.add({
                key: "message",
                severity: "success",
                summary: "Success Message",
                detail: "Delete successfully",
              });
              // this.stopTimer();
              // this.timer = setInterval(() => { this.showapprovefiles('Pending') }, 5000);
            }
          });
      },
      reject: () => {},
    });
  }
  approveDelete(id) {
    this.confirmationService.confirm({
      message: "Are you sure that you want to Delete?",
      header: "Delete",
      key: "topHeader",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.imageservice.approveDelete(id).subscribe((data: any[]) => {
          // console.log(data)
          if (data != null) {
            this.messageservice.add({
              key: "message",
              severity: "success",
              summary: "Success Message",
              detail: "Delete successfully",
            });
            this.showapprovefiles("Pending");
          }
        });
      },
      reject: () => {
        this.messageservice.add({
          key: "message",
          severity: "warn",
          summary: "Cancelled",
          detail: "You have cancelled",
        });
      },
    });
  }
  assign(cartdata) {
    // if (cartdata.length > 0) {
    //   this.dataforassign = cartdata
    //   this.assignpopup = true
    // }
    if (cartdata != null) {
      this.dataforassign = cartdata;
      this.assignpopup = true;
    }
  }

  //share cart to the other user
  assigncarttouser() {
    if (this.assigntoid != "null") {
      if (this.dataforassign.length != undefined) {
        for (let i = 0; i < this.dataforassign.length; i++) {
          var finaldata = this.dataforassign[i];
          this.imageservice
            .assigncart(finaldata, this.assigntoid)
            .subscribe((data: any[]) => {
              if (data != null) {
                this.messageservice.add({
                  key: "message",
                  severity: "success",
                  summary: "Success Message",
                  detail: "Cart assign successfully",
                });
              }
            });
        }
      } else {
        var finaldata1 = this.dataforassign;
        this.imageservice
          .assigncart(finaldata1, this.assigntoid)
          .subscribe((data: any[]) => {
            if (data != null) {
              this.messageservice.add({
                key: "message",
                severity: "success",
                summary: "Success Message",
                detail: "Cart assign successfully",
              });
            }
          });
      }

      this.hideassigndrop();
    }
  }

  hideassigndrop() {
    this.assignpopup = false;
    this.assigntoid = "null";
    this.dataforassign = null;
    this.selectedCars3 = null;
  }

  getuserdata() {
    this.userser.getuserdata().subscribe((data: userformmodel[]) => {
      if (data != null) {
        this.userlist = data;
        for (let i = 0; i < data.length; i++) {
          if (data[i].LoginId.toLowerCase() == "admin") {
            this.userlist.splice(i, 1);
          }
        }
      }
    });
  }

  //download multiple cart at single click
  downloadmultiplecart(data) {
    //this.leftmenu.downloadinggif=1
    var x = document.querySelector(".downloadinggif1");
    x["style"].display = "block";
    var y = document.querySelector(".downloadback");
    //x.classList.add("downloadinggif")
    y["style"].display = "block";
    //x.className.replace("downloadinggif","")
    if (data != null) {
      if (data.length != undefined) {
        var id = "";
        for (let i = 0; i < data.length; i++) {
          id += data[i].cartid.toString() + "~";
          if (i == data.length - 1) {
            this.downloadcart(id);
          }
        }
      } else {
        this.downloadcart(data);
      }
    }
  }

  //download cart
  downloadcart(data1) {
    // console.log("cart download func",data1);
    var x = document.querySelector(".downloadinggif1");
    x["style"].display = "block";
    var y = document.querySelector(".downloadback");
    //x.classList.add("downloadinggif")
    y["style"].display = "block";
    this.imageservice
      .downloadcart(data1.toString())
      .subscribe((data: any[]) => {
        // console.log("download api response",data);
        if (data != null) {
          this.downloadzipflder(data);
        }
      });
  }

  downloadzipflder(filename) {
    // console.log("downloading zip folder",filename);
    var cartname = filename;
    //filename = filename + ".zip"
    filename = filename + ".zip";
    var filepath = this.ApiServerPath + filename;
    // this.imageservice.testzipdownload(filename).subscribe((data: any) => {
    var x = document.querySelector(".downloadinggif1");
    x["style"].display = "none";
    var y = document.querySelector(".downloadback");
    y["style"].display = "none";
    // if (data != null) {
    //   var b64Data1 = data.split(";")[1]
    //   const byteCharacters = atob(b64Data1);

    //   const byteNumbers = new Array(byteCharacters.length);
    //   for (let i = 0; i < byteCharacters.length; i++) {
    //     byteNumbers[i] = byteCharacters.charCodeAt(i);
    //   }
    //   const byteArray = new Uint8Array(byteNumbers);
    //   const blob = new Blob([byteArray], { type: "application/x-zip-compressed" });
    //   //let byteCharacters = atob(data);

    //   var url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    a.href = filepath;
    a.download = "Assets";
    a.click();
    //window.URL.revokeObjectURL(url);
    a.remove(); // remove the element
    //}
    // this.imageservice.deletezip(cartname).subscribe((data: any[]) => {

    // })

    // })
  }

  showmaildia(id) {
    this.linkid = id;
    // console.log("this.linkid",this.linkid);
    this.viamail = true;
  }

  hidemailbox() {
    this.viamail = false;
    this.frompass = "";
    this.frommail = "";
    this.tomail = "";
    this.subject = "";
    this.link = "";
    this.linkid = "";
  }

  //mail service of the cart
  mail() {
    if (this.tomail == "") {
      this.messageservice.add({
        key: "message",
        severity: "error",
        summary: "Info Message",
        detail: "Please fill all details",
      });
    } else {
      this.link =
        environment.cartweblinkadd +
        this.linkid +
        "~" +
        JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
      this.userser
        .mail(
          this.frommail,
          this.frompass,
          this.tomail,
          this.subject,
          this.link,
          this.linkid
        )
        .subscribe((data: any[]) => {
          if (data != null) {
            this.messageservice.add({
              key: "message",
              severity: "info",
              summary: "Info Message",
              detail: data.toString(),
            });
            this.hidemailbox();
          }
        });
    }
  }

  clientDetail() {
    this.loginservice.clientDetail().subscribe((data: any) => {
      this.clientDetails = data;
      // console.log("this.clientDetail",this.clientDetails);
      this.clientDetails = this.clientDetails.split("~");
      this.user = this.clientDetails[0];
      this.licenceDate = this.clientDetails[1];
      this.machineId = this.clientDetails[2];
      this.client = this.clientDetails[3];
      // console.log("client name",this.client);
    });
  }
 shareViaMailEnable:boolean=false;
  async checkpermissions() {
    this.permissionlist = JSON.parse(localStorage.getItem("userpermissions"));
    for (let i = 0; i < this.permissionlist.length; i++) {
      if (this.permissionlist[i].taskid == 39) {
        this.VideoArchive = 1;
      }
      if (this.permissionlist[i].taskid == 134) {
        this.hrCollection = 1;
      }

      if (this.permissionlist[i].taskid == 148) {
        this.shareViaMailEnable = true;
      }
    }
  }
}
