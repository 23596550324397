import {
  Component,
  ElementRef,
  HostListener,
  NgZone,
  OnInit,
  ViewChild,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import {
  FileSystemDirectoryEntry,
  FileSystemFileEntry,
  NgxFileDropEntry,
} from "ngx-file-drop";
import {
  ConfirmationService,
  MenuItem,
  MessageService,
  SortEvent,
  TreeNode,
} from "primeng/api";
import { attachmentFormModel } from "src/app/model/file/attachmentFormModel";
import { audiouploadModel } from "src/app/model/file/audiouploadModel";
import { DocumentUploadFormModel } from "src/app/model/file/documentUploadModel";
import { downloadimagesmodel } from "src/app/model/file/downloadimagemodel";
import { exportConfigurationModel } from "src/app/model/file/exportconfigurationmodel";
import { ImageUploadFormModel } from "src/app/model/file/imgaeuploadformModel";
import { StoryFormModel } from "src/app/model/file/storyformModel";
import { VideoUploadModel } from "src/app/model/file/VideoUploadModel";
import { PermissionModel } from "src/app/model/master/Permission.model";
import { AudiouplaodService } from "src/app/services/files/audiouplaod.service";
import { DashboardService } from "src/app/services/files/dashboard.service";
import { DocumentuploadService } from "src/app/services/files/documentupload.service";
import { ImageuploadService } from "src/app/services/files/imageupload.service";
import { StoryserviceService } from "src/app/services/files/storyservice.service";
import { VideouploadService } from "src/app/services/files/videoupload.service";
import { environment } from "src/environments/environment";
import {
  DataViewModule,
  DataViewLayoutOptions,
  DataView,
} from "primeng/dataview";
import { ThrowStmt } from "@angular/compiler";
import { Tree } from "primeng/tree";
import { file } from "jszip";
import { ContextMenu, Dialog } from "primeng/primeng";
import { UserserviceService } from "src/app/services/masters/userservice.service";
import { Lightbox } from "ngx-lightbox";
import {
  NgxGalleryAnimation,
  NgxGalleryImage,
  NgxGalleryOptions,
} from "ngx-gallery";
import { saveAs } from "file-saver";
import { TreeDragDropService } from "primeng/api";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { data } from "jquery";
@Component({
  selector: "app-treeviewcom",
  templateUrl: "./treeviewcom.component.html",
  styleUrls: ["./treeviewcom.component.css"],
  providers: [TreeDragDropService],
})
export class TreeviewcomComponent implements OnInit {
  @ViewChild("ytplayer", null) ytPlayer: ElementRef;
  @ViewChild("ytplayer1", null) ytPlayer1: ElementRef;
  public files: NgxFileDropEntry[] = [];
  val: string = "";
  tconfig: any;
  files1: TreeNode[];
  files3: TreeNode[];
  showfulltree: number = 0;
  prct: string;
  displayProcessBar: boolean = false;
  files2: any[] = [];
  @ViewChild("cropimagecanvas", { static: false }) cropimagecanvas: ElementRef;
  @ViewChild("audio", { static: true }) Player: ElementRef;
  @ViewChild("dv", { static: false }) dataview1: any;
  @ViewChild("tr", { static: false }) tr: Tree;
  ctx: CanvasRenderingContext2D;
  audioimage: string = "assets/image/Wave_bg.jpg";
  elementarr: ImageUploadFormModel[];
  selectedIndexs: ImageUploadFormModel[] = [];
  selectedIndexstype: any[] = [];
  selectedIndexsid: any[] = [];
  permissionlist: PermissionModel[];
  downloaditems: MenuItem[];
  showfilter: boolean = false;
  downloadbtn: number = 0;
  imgcrppbtn: number = 0;
  imgdownloadbtn: number = 0;
  videoexportbtn: number = 0;
  videocropbtn: number = 0;
  storydownloadbtn: number = 0;
  audiodownloadbtn: number = 0;
  pdfimages: any[];
  viewDocument: boolean = false;
  glbDeletedoc: number = 0;
  downloadDocumentList: downloadimagesmodel[] = [];
  showDocumentDownload: boolean = false;
  text: string;
  fullDocumentpathlist: DocumentUploadFormModel;
  ratingitemdoc: any;
  downloadAudioList: downloadimagesmodel[] = [];
  showAudioDownload: boolean = false;
  fullAudiolist: audiouploadModel;
  ratingitemaudio: any;
  Audiopreviewpath: string;
  viewAudio: boolean = false;
  private cropper: Cropper;
  public imageDestination = "";
  ButtonStatusimg = "Download";
  ApiServerPath = environment.ApiServerPath;
  @ViewChild("image", { static: false }) public imageElement: ElementRef;
  @ViewChild("checkinfileup", { static: false })
  public checkinfileup: ElementRef;
  @ViewChild("checkinfileupfl", { static: false })
  public checkinfileupfl: ElementRef;

  downloadListimg: downloadimagesmodel[] = [];
  showDownloadimg: boolean = false;
  fullpathlist: ImageUploadFormModel;
  ratingitemimg: any;
  imagePath: any;
  view: boolean = false;
  @ViewChild("video", { static: true }) private videoElement: ElementRef;
  @ViewChild("videogif", { static: true }) private videogif: ElementRef;
  ratingitemvideo: any;
  fullvideolist: VideoUploadModel;
  viewVideo: boolean = false;
  Buttons: string = "Play";
  durationarray = [];
  ButtonStatus: string = "MarkIn";
  playStatus: string = "Pause";
  volumeStatus: string = "unmute";
  markinvl = 1;
  markoutval = 1;
  MarkInValue;
  MarkOutValue;
  totalDuration;
  markinArray = [];
  totaldurarray = [];
  minutes: any;
  minutes_d: any;
  ExportList: exportConfigurationModel[] = [];
  downloadVideoList: downloadimagesmodel[] = [];
  exportitemid: number;
  exportitemname: string;
  videopreviewpath: string;
  video: string;
  showVideoDownload: boolean = false;
  fullstorylist: StoryFormModel;
  headline: string;
  ratingitemstory: any;
  viewStory: boolean = false;
  selectedAttachmentlist: attachmentFormModel[] = [];
  attachmentlist: attachmentFormModel[] = [];
  srchtext: string = "";
  brightness: number = 0;
  contrast: number = 0;
  saturate: number = 0;
  watermark: string = "India Today";
  metadatadiv: number = 1;
  editdiv: number = 0;
  grin: string = "";
  totalcount: string;
  totalfcount: string;
  advnsrchfil: any;
  loading: boolean = false;
  watermarkposition: string = "topright";
  showeditor: boolean = false;
  iframepath: string = "";
  idforimageedit: string = "";
  edittable: string = "";
  docfileforup: string = "";
  docidforedit: string = "";
  edittablefordoc: string = "";
  DocumentUpdated: boolean = false;
  ImageUpdated: boolean = false;
  imagenameforedit: string = "";
  contextmenuitem: MenuItem[];
  pdfpath: string = "";
  showmax: number = 0;
  showmin: number = 1;
  cartname1: string;
  cartitemid1: string;
  cartitemtype1: string;
  showcart: boolean = false;
  showcartbox: boolean = false;
  results: string[] = [];
  filteredBrands: any[];
  addtocartimage: number = 0;
  addtocartstory: number = 0;
  addtocartaudio: number = 0;
  addtocartvideo: number = 0;
  addtocartdoc: number = 0;
  editdocmeta: number = 0;
  editimgmeta: number = 0;
  audiodelete: number = 0;
  videodelete: number = 0;
  imagedelete: number = 0;
  docdelete: number = 0;
  storydelete: number = 0;

  selectedIndexspermis: any[] = [];
  bindthumb: string = "0";
  folderdata: any[] = [];
  bditems: MenuItem[];
  treeactivekey: string;
  tcmitem: MenuItem[];
  checkinpop: boolean = false;
  tcmnodekey: any;
  tcmnodepath: any;
  newcolpop: boolean = false;
  editcolpop: boolean = false;
  newcollname: string = "";
  dropedvalue: any;
  checkinfldrpop: boolean = false;
  filesforuploadglb: any = [];
  pathforupload: any = "";
  uploadform: boolean = false;
  itemtype: string = "";
  ismenuclick: boolean = false;
  rightclickmenuopen: boolean = false;
  selectedcollpath: string = "";
  foldersrchtxt: string = "";
  tablestyle: string = "list";
  rowperpage: Number = 18;
  imagecount: string = "";
  doccount: string = "";
  videocount: string = "";
  audiocount: string = "";
  storycount: string = "";
  glbadvancefilter: string = "";
  treedropvisible: boolean = false;
  videoupdate: boolean = false;
  videoidforedit: string = "";
  videofileforup: string = "";
  treecolumn: any[] = [];
  selectedtreecolumn: any[] = [];
  @ViewChild("conetxtmenudiv", { static: false })
  public conetxtmenudiv: ContextMenu;
  @ViewChild("tcm", { static: false }) tcm: ContextMenu;

  @ViewChild("editdialog", { static: true }) editdialog: Dialog;
  filenamecol: boolean = false;
  datecol: boolean = false;
  descriptioncol: boolean = false;
  createdcol: boolean = false;
  uploadbycol: boolean = false;
  lastmodifycol: boolean = false;
  lastmodifiercol: boolean = false;
  actioncol: boolean = false;
  pagenocol: boolean = false;
  noofpagescol: boolean = false;
  collectioncol: boolean = false;
  keywordcol: boolean = false;
  colperferencediv: boolean = false;
  autoCompletArr: any[] = [];
  orgname: string = "";
  duplicatelist: any[] = [];
  missedFiles: any[] = [];
  showduplicate: boolean = false;
  missedFilePopup: boolean = false;
  searchin: string = "";
  relationlist: string[] = [];
  filteredrelation: any[];
  showrelation: boolean = false;
  relationname: string;
  fulltextdata: string = "";
  treeusers: any[] = [];
  treeperpopup: boolean = false;
  userlist: any[] = [];
  selectedkey: string = "";
  treeloader: boolean = true;
  treeloader1: boolean = false;
  galleryImages: NgxGalleryImage[];
  galleryOptions: NgxGalleryOptions[];
  images: any[];
  _album: any[] = [];
  grouplist: any[] = [];
  filepath: string = "";
  filepending: boolean = false;
  logcolumns: any[] = [];

  columnsset: any[] = [];
  selectedCars3: any[] = [];

  authorcol: boolean = false;
  magsectioncol: boolean = false;
  sourcecol: boolean = false;
  bysubjectcol: boolean = false;
  titlecol: boolean = false;
  photographercol: boolean = false;
  Yearcol: boolean = false;
  selectedFile: TreeNode;
  treeDataNull: number = 0;
  insertFile: number = 0;
  insertFolder: number = 0;
  createFolder: number = 0;
  indexPrev: any = null;
  indexCurrent: any;
  progressBarValue: number = 0;
  selectedMonth: string;
  selectedYear: string;
  newMonthcolpop: boolean = false;
  newYearcolpop: boolean = false;
  editYearcolpop: boolean = false;
  editMonthcolpop: boolean = false;
  months = [
    { label: 'January', value: 'January' },
    { label: 'February', value: 'February' },
    { label: 'March', value: 'March' },
    { label: 'April', value: 'April' },
    { label: 'May', value: 'May' },
    { label: 'June', value: 'June' },
    { label: 'July', value: 'July' },
    { label: 'August', value: 'August' },
    { label: 'September', value: 'September' },
    { label: 'October', value: 'October' },
    { label: 'November', value: 'November' },
    { label: 'December', value: 'December' }
];
years = [
  { label: '2013', value: '2013' },
  { label: '2014', value: '2014' },
  { label: '2015', value: '2015' },
  { label: '2016', value: '2016' },
  { label: '2017', value: '2017' },
  { label: '2018', value: '2018' },
  { label: '2019', value: '2019' },
  { label: '2020', value: '2020' },
  { label: '2021', value: '2021' },
  { label: '2022', value: '2022' },
  { label: '2023', value: '2023' },
  { label: '2024', value: '2024' },
];


  constructor(
    private _lightbox: Lightbox,
    private ngZone: NgZone,
    private dashboarservice: DashboardService,
    private documentservice: DocumentuploadService,
    private confirmationService: ConfirmationService,
    private messageservice: MessageService,
    private route: ActivatedRoute,
    public audiouploadservice: AudiouplaodService,
    public imageservice: ImageuploadService,
    public sanitizer: DomSanitizer,
    public videoservice: VideouploadService,
    private storyservice: StoryserviceService,
    public userser: UserserviceService
  ) {
    //this.ngOnInit()
    window["angularComponentReference"] = {
      component: this,
      zone: this.ngZone,
      loadAngularFunction: (arr, pathkey) =>
        this.checkdataforupload(arr, pathkey),
    };
    //this.bindtreeview()
    this.gettreejson("");
    let cartlistsub = this.dashboarservice
      .getcartlistdata()
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          this.results = data;
        }
        cartlistsub.unsubscribe();
      });
    let relationlistsub = this.dashboarservice
      .getrelationlist()
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          this.relationlist = data;
        }
        relationlistsub.unsubscribe();
      });
    var y = document.querySelector(".content-wrapper");
    y.setAttribute("style", "margin:0");
    var x = document.querySelector(".main-sidebar");
    x.remove();
    document
      .querySelector(".sidebar-toggle")
      .setAttribute("style", "display:none");
    document
      .querySelector(".navbar-custom-menu")
      .setAttribute("style", "display:none");
  }

  ngAfterViewInit() {
    let cartlistsub = this.dashboarservice
      .getcartlistdata()
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          this.results = data;
        }
        cartlistsub.unsubscribe();
      });
    let relationlistsub = this.dashboarservice
      .getrelationlist()
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          this.relationlist = data;
        }
        relationlistsub.unsubscribe();
      });
    let getusersub = this.dashboarservice
      .getuserlist()
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].UserId != "53") {
              this.userlist.push({
                value: data[i].UserId,
                label: data[i].UserCompleteName,
              });
            }
          }
        }
        getusersub.unsubscribe();
      });
  }

  ngOnInit() {
    this.cmshow();
    window["angularComponentReference"] = {
      component: this,
      zone: this.ngZone,
      loadAngularFunction: (arr, pathkey) =>
        this.checkdataforupload(arr, pathkey),
    };
    //this.bindtreeview()
    //this.gettreejson("")
    this.tconfig = {
      hasAllCheckBox: false,
      hasFilter: false,
      hasCollapseExpand: false,
      decoupleChildFromParent: false,
      maxHeight: 500,
    };
    this.dashboarservice.visibletreedrop(false);
    this.checkpermissions();
    //this.search()
    this.totalcount = localStorage.getItem("totalcount");
    const savedApiServerPath = sessionStorage.getItem("ApiServerPath");
    if (savedApiServerPath) {
      this.ApiServerPath = savedApiServerPath;
    }
    this.srchtext = this.route.snapshot.paramMap.get("text");
    if (this.srchtext == null) {
      this.srchtext = "";
    }
    //this.blindsearchwithdata('');
    this.fullDocumentpathlist = new DocumentUploadFormModel();
    this.fullAudiolist = new audiouploadModel();
    this.fullpathlist = new ImageUploadFormModel();
    this.fullvideolist = new VideoUploadModel();
    this.fullstorylist = new StoryFormModel();
    this.editdialog.onShow.subscribe({
      next: this.handleOnShow.bind(this),
    });
    let cartlistsub = this.dashboarservice
      .getcartlistdata()
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          this.results = data;
        }
        cartlistsub.unsubscribe();
      });
    let relationlistsub = this.dashboarservice
      .getrelationlist()
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          this.relationlist = data;
        }
        relationlistsub.unsubscribe();
      });
    
    this.contextmenuitem = [
      {
        label: "Download",
        icon: "fa fa-download",
        command: (event) => {
          this.downloadmultiple();
        },
      },
      // {
      //   label: "Add to cart",
      //   icon: "fa fa-shopping-cart",
      //   command: (event) => {
      //     this.showmulcartdiv();
      //   },
      // },
      // {
      //   label: "Edit",
      //   icon: "fa fa-edit",
      //   command: (event) => {
      //     this.multipleedit();
      //   },
      // },
      // {
      //   label: "Set Relationship",
      //   icon: "fa fa-link",
      //   command: (event) => {
      //     this.showmulrelationpopup();
      //   },
      // },
      // {
      //   label: 'Delete',
      //   icon: 'fa fa-trash',
      //   command: (event) => { this.deletefiles(); }
      // }
    ];
    this.bditems = [];

    this.bindtreecntxtmenu();

    this.dashboarservice.gettreedropstatus().subscribe((data: boolean) => {
      this.loading = data;
    });

    let getusersub = this.dashboarservice
      .getuserlist()
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].UserId != "53") {
              this.userlist.push({
                value: data[i].UserId,
                label: data[i].UserCompleteName,
              });
            }
          }
        }
        getusersub.unsubscribe();
      });
    this.treecolumn = [
      { label: "Filename", value: "filenamecol" },
      { label: "Date", value: "datecol" },
      { label: "Description", value: "descriptioncol" },
      { label: "Keywords", value: "keywordcol" },
      { label: "CreatedDate", value: "createdcol" },
      { label: "uploadBy", value: "uploadbycol" },
      { label: "LastModifyDate", value: "lastmodifycol" },
      { label: "LastModifyBy", value: "lastmodifiercol" },
      { label: "PageNo", value: "pagenocol" },
      { label: "NoOfPages", value: "noofpagescol" },
      { label: "Path", value: "collectioncol" },
      { label: "Action", value: "actioncol" },
      { label: "Author", value: "authorcol" },
      { label: "MagSection", value: "magsectioncol" },
      { label: "Source", value: "sourcecol" },
      { label: "By Subject", value: "bysubjectcol" },
      { label: "Title", value: "titlecol" },
      { label: "Photographer", value: "photographercol" },
      { label: "Year", value: "Yearcol" },
    ];

    this.selectedtreecolumn = JSON.parse(
      JSON.parse(localStorage.getItem("userperference"))[0].tablecol
    );

    this.checktableperfrence();

    $(document).on("click", function (event) {
      if (!$(event.target).closest("#spnauto").length) {
        $("#ulautocomplete").hide();
      } else {
        // $('#ulautocomplete').show();
      }
    });
    this.galleryOptions = [
      {
        image: false,
        width: "100%",
        height: "100px",
        thumbnailsColumns: 8,
        previewDownload: true,
      },
      { breakpoint: 700, width: "100%" },
    ];

    // console.log("ng on it tree view");
    
    this.route.queryParams.subscribe(params => {
      const companyID = params.company;

      if (companyID) {
        setTimeout(() => {
          $($("p-treenode .ui-corner-all")[0]).parent().find(".pi-caret-right")[0].click()
          setTimeout(() => {
            for(let i=0;i<$("p-treenode .ui-corner-all").length;i++){
              if($("p-treenode .ui-corner-all")[i].innerText==companyID){
                $($("p-treenode .ui-corner-all")[i]).parent().find(".pi-caret-right")[0].click()
                  $("p-treenode .ui-corner-all")[i].click()
              }
            } 
          }, 100);
        }, 100);
      }
    });

  }

  open(index: number): void {
    this._lightbox.open(this._album, index);
  }

  close1(): void {
    this._lightbox.close();
  }
  getgroupimage(groupid) {
    this.images = [];
    this.videoservice.GetAllgroupimage(groupid).subscribe((data: any[]) => {
      if (data.length > 0) {
        this.grouplist = data;
        this.images = [];
        this._album = [];
        for (let i = 0; i < this.grouplist.length; i++) {
          this.filepath = this.grouplist[i].thumbpath;
          var filename = this.grouplist[i].orgfilename;
          const album = {
            src: this.ApiServerPath + this.grouplist[i].hirespath,
            caption: filename,
            thumb: this.ApiServerPath + this.grouplist[i].hirespath,
          };

          this._album.push(album);
          this.galleryImages.push({
            small: this.ApiServerPath + this.filepath,
            medium: this.ApiServerPath + this.grouplist[i].hirespath,
            big: this.ApiServerPath + this.grouplist[i].hirespath,
            description: filename,
          });
          // this.images.push({source:[this.ApiServerPath+this.filepath], alt:'', title:filename});
        }
      }
    });
  }
  imageclick(index) {
    var selectfile = index.path[0].src;
    var path1 = decodeURIComponent(selectfile.replace(this.ApiServerPath, ""));
    var index1 = this.grouplist.findIndex((p) => p.thumbpath == path1);
    this.open(index1);
    //alert(index1)
    //document.getElementById('imgModal').style.display = "block";
  }

  checktableperfrence() {
    if (
      JSON.parse(localStorage.getItem("userperference"))[0].tablecol.indexOf(
        "filenamecol"
      ) > -1
    ) {
      this.filenamecol = true;
    }
    if (
      JSON.parse(localStorage.getItem("userperference"))[0].tablecol.indexOf(
        "datecol"
      ) > -1
    ) {
      this.datecol = true;
    }
    if (
      JSON.parse(localStorage.getItem("userperference"))[0].tablecol.indexOf(
        "descriptioncol"
      ) > -1
    ) {
      this.descriptioncol = true;
    }
    if (
      JSON.parse(localStorage.getItem("userperference"))[0].tablecol.indexOf(
        "keywordcol"
      ) > -1
    ) {
      this.keywordcol = true;
    }
    if (
      JSON.parse(localStorage.getItem("userperference"))[0].tablecol.indexOf(
        "createdcol"
      ) > -1
    ) {
      this.createdcol = true;
    }
    if (
      JSON.parse(localStorage.getItem("userperference"))[0].tablecol.indexOf(
        "uploadbycol"
      ) > -1
    ) {
      this.uploadbycol = true;
    }
    if (
      JSON.parse(localStorage.getItem("userperference"))[0].tablecol.indexOf(
        "lastmodifycol"
      ) > -1
    ) {
      this.lastmodifycol = true;
    }
    if (
      JSON.parse(localStorage.getItem("userperference"))[0].tablecol.indexOf(
        "lastmodifiercol"
      ) > -1
    ) {
      this.lastmodifiercol = true;
    }
    if (
      JSON.parse(localStorage.getItem("userperference"))[0].tablecol.indexOf(
        "pagenocol"
      ) > -1
    ) {
      this.pagenocol = true;
    }
    if (
      JSON.parse(localStorage.getItem("userperference"))[0].tablecol.indexOf(
        "noofpagescol"
      ) > -1
    ) {
      this.noofpagescol = true;
    }
    if (
      JSON.parse(localStorage.getItem("userperference"))[0].tablecol.indexOf(
        "collectioncol"
      ) > -1
    ) {
      this.collectioncol = true;
    }

    if (
      JSON.parse(localStorage.getItem("userperference"))[0].tablecol.indexOf(
        "authorcol"
      ) > -1
    ) {
      this.authorcol = true;
    }
    if (
      JSON.parse(localStorage.getItem("userperference"))[0].tablecol.indexOf(
        "magsectioncol"
      ) > -1
    ) {
      this.magsectioncol = true;
    }
    if (
      JSON.parse(localStorage.getItem("userperference"))[0].tablecol.indexOf(
        "sourcecol"
      ) > -1
    ) {
      this.sourcecol = true;
    }
    if (
      JSON.parse(localStorage.getItem("userperference"))[0].tablecol.indexOf(
        "bysubjectcol"
      ) > -1
    ) {
      this.bysubjectcol = true;
    }
    if (
      JSON.parse(localStorage.getItem("userperference"))[0].tablecol.indexOf(
        "titlecol"
      ) > -1
    ) {
      this.titlecol = true;
    }
    if (
      JSON.parse(localStorage.getItem("userperference"))[0].tablecol.indexOf(
        "photographercol"
      ) > -1
    ) {
      this.photographercol = true;
    }
    if (
      JSON.parse(localStorage.getItem("userperference"))[0].tablecol.indexOf(
        "yearcol"
      ) > -1
    ) {
      this.Yearcol = true;
    }
    if (
      JSON.parse(localStorage.getItem("userperference"))[0].tablecol.indexOf(
        "actioncol"
      ) > -1
    ) {
      this.actioncol = true;
    }
  }

  bindtreecntxtmenu() {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    var RolesId = JSON.parse(localStorage.getItem("userdetails"))[0].RolesId;
    // console.log("Role is", RolesId);
    if (userid == "53" || RolesId == "1225") {
      this.tcmitem = [
        {
          label: "Check In File",
          icon: "fa fa-upload",
          command: (event) => {
            this.showcheckin();
          },
        },
        {
          label: "Check In Folder",
          icon: "fa fa-upload",
          command: (event) => {
            this.showcheckinfldr();
          },
        },
        {
          label: "New Company",
          icon: "fa fa-folder",
          command: (event) => {
            this.showcollpop();
          },
        },
        {
          label: "Edit Company",
          icon: "fa fa-folder",
          command: (event) => {
            this.showeditcollpop();
          },
        },
        {
          label: "Delete Company",
          icon: "fa fa-folder",
          command: (event) => {
            this.deletecoll();
          },
        },
        // {
        //   label: "Search In",
        //   icon: "fa fa-search",
        //   command: (event) => {
        //     this.searchincoll();
        //   },
        // },
        {
          label: "Assign Permission",
          icon: "fa fa-gear",
          command: (event) => {
            this.showperpopup();
          },
        },
      ];
    } else {
      this.tcmitem = [
       {
         label: "Check In File",
         icon: "fa fa-upload",
         command: (event) => {
           this.showcheckin();
         },
       },
       {
         label: "Check In Folder",
         icon: "fa fa-upload",
         command: (event) => {
           this.showcheckinfldr();
         },
       },
       {
         label: "New Company",
         icon: "fa fa-folder",
         command: (event) => {
           this.showcollpop();
         },
       },
       {
         label: "Edit Company",
         icon: "fa fa-folder",
         command: (event) => {
           this.showeditcollpop();
         },
       },
       {
         label: "Delete Company",
         icon: "fa fa-folder",
         command: (event) => {
           this.deletecoll();
         },
       },
      //  {
      //    label: "Search In",
      //    icon: "fa fa-search",
      //    command: (event) => {
      //      this.searchincoll();
      //    },
      //  },
     ];
    }
     }

  handleOnShow() {
    this.editdialog.contentViewChild.nativeElement.addEventListener(
      "scroll",
      this.scroll.bind(this)
    );
  }

  scroll(e: Event) {
    //alert("scroll")
    this.dashboarservice.visibletreedrop(false);
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files2 = [];
    if (this.val == "") {
      alert("Please select a directory");
    } else {
      var date = new Date();
      var timeString = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[1];
      var timeformat = timeString.split(".")[0];
      var timeforup =
        timeformat.split(":")[0] +
        timeformat.split(":")[1] +
        timeformat.split(":")[2];
      this.files2 = files;
      this.files = files;
      files = [];
      var idx: number = 0;
      this.displayProcessBar = true;
      for (const droppedFile of this.files) {
        if (droppedFile.fileEntry.isFile) {
          idx = idx + 1;
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            var x = droppedFile.relativePath;
            var filext = droppedFile.fileEntry.name;
            //alert(x)
            var y = "";
            var a = "";
            if (x.trim().indexOf("/") == 0) {
              y = x.replace("/", "");
            } else {
              y = x;
            }
            if (x.trim().indexOf("/") == -1) {
              //a=y.substring(0,y.lastIndexOf("."))+"_"+this.val+"."+y.substring(y.lastIndexOf(".")+1,y.length);
              a = "";
            } else {
              //a=y.substring(0,y.indexOf("/"))+"_"+this.val+"/"+y.substring(y.indexOf("/")+1,y.length);
              a = y;
            }

            const formData = new FormData();
            formData.append("photo", file);

            var xhr = new XMLHttpRequest();

            // your url upload
            xhr.open(
              "post",
              environment.ApiUrl +
                "treeviewupload/api/upload?fpath=" +
                a +
                "&pathkey=" +
                this.val +
                "&filext=" +
                filext,
              true
            );
            var prct1 = "";
            xhr.upload.onprogress = function (e) {
              if (e.lengthComputable) {
                var percentage = (e.loaded / e.total) * 100;
                prct1 = percentage + "%";
                // console.log("droppedFile.relativePath", droppedFile.relativePath);

                document.getElementById(droppedFile.relativePath).style.width =
                  prct1;
                document
                  .getElementById(droppedFile.relativePath)
                  .setAttribute("aria-valuenow", prct1);
                document.getElementById(droppedFile.relativePath).innerText =
                  prct1;
                // console.log(percentage + "%");
              }
            };
            //this.prct=prct1;
            xhr.onerror = function (e) {
              // console.log('Error');
              // console.log(e);
            };
            xhr.onload = function () {
              // console.log(this.statusText);
            };

            xhr.send(formData);
            // Headers
            //const param = new HttpParams
            //param.set("path",droppedFile.relativePath)

            // this.http.post("http://localhost:5000/treeviewupload/api/upload?fpath="+a, formData)
            // .subscribe(data => {
            //   //console.log(data)
            //   // Sanitized logo returned from backend
            // })
          });
        } else {
          const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
          // console.log(droppedFile.relativePath, fileEntry);
        }
      }
    }
  }
  deletetempfilestatus() {
    this.dashboarservice
      .deletetempstatusfile(this.selectedCars3)
      .subscribe((data: any) => {
        if (data == "Exist") {
          this.messageservice.add({
            key: "message",
            severity: "warning",
            summary: "Warning Message",
            detail: "File Error",
          });
        } else {
          this.messageservice.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail: "File Deleted",
          });
          this.historyinfofile();
        }
      });
  }

  historyinfofile() {
    this.dashboarservice.getfilestauts().subscribe((data: any) => {
      this.logcolumns = data;
    });
  }
  showpendingfile() {
    this.filepending = true;
    this.historyinfofile();
  }

  //getting tree data of folders to render but refreshing each time while creating folder in collection
  gettreejson(e) {
    this.treeloader = true;
    this.treeloader1 = false;
    this.dashboarservice
      .gettreejson("0", "1", "", "")
      .subscribe((data: any) => {
        // console.log("tree",data);

        if (data != 0) {
          this.files1 = JSON.parse(data.toString());
          //console.log(this.files1);
          this.treeloader = false;
          this.treeloader1 = true;
        } else {
          this.treeloader = false;
          this.treeloader1 = true;
          this.treeDataNull = 1;
        }
        //this.treeloader=false
        //this.treeloader1=true
      });
  }


  //getting tree data of folders to render but not refreshing each time while creating folder in collection
  gettreejson1(e) {
    // console.log("gettreejson1 event",e);
    try {
      if (e.node.children == undefined) {
        e.node.parent.expanded = false;
        e.node.parent.expanded = true;
      } else {
        var ln = e.node.children[0].data.split("/").length;
        var temppath = "";
        if (e.node.data.indexOf("/") > -1) {
          temppath = e.node.data + "/";
        } else {
          temppath = e.node.data;
        }
        // this.dashboarservice.gettreejson(ln - 1, ln, temppath, e.node.children[0].key).subscribe((data: any) => {
        //   if (data != "") {
        //     e.node.children = JSON.parse(data.toString())
        //   }
        // })
      }
    } catch (err) {}
  }

  // this.tcmitem = [
  //   {
  //     label: "Check In File",
  //     icon: "fa fa-upload",
  //     command: (event) => {
  //       this.showcheckin();
  //     },
  //   },
  //   {
  //     label: "Check In Folder",
  //     icon: "fa fa-upload",
  //     command: (event) => {
  //       this.showcheckinfldr();
  //     },
  //   },
  //   {
  //     label: "New Company",
  //     icon: "fa fa-folder",
  //     command: (event) => {
  //       this.showcollpop();
  //     },
  //   },
  //   {
  //     label: "Edit Company",
  //     icon: "fa fa-folder",
  //     command: (event) => {
  //       this.showeditcollpop();
  //     },
  //   },
  //   {
  //     label: "Delete Company",
  //     icon: "fa fa-folder",
  //     command: (event) => {
  //       this.deletecoll();
  //     },
  //   },
  //   {
  //     label: "Search In",
  //     icon: "fa fa-search",
  //     command: (event) => {
  //       this.searchincoll();
  //     },
  //   },
  // ];
  //right click on folder to view the options
  
  treerigclck(e,data) {
    // console.log("right click event",e);
    
    if(e.node.Level == 0){
      this.tcmitem = [
        {
          label: "Add New Company",
          icon: "fa fa-folder",
          command: (event) => {
            this.showcollpop();
          },
        },
      ];
    } else if (e.node.Level == 1 && !e.node.children) {
      this.tcmitem = [
        {
          label: "Add New Year",
          icon: "fa fa-folder",
          command: (event) => {
            this.newYearcolpopfunc();
          },
        },
        {
          label: "Delete Company",
          icon: "fa fa-folder",
          command: (event) => {
            this.deletecoll();
          },
        },
        {
          label: "Edit Company",
          icon: "fa fa-folder",
          command: (event) => {
            this.showeditcollpop();
          },
        },
        // {
        //   label: "Search In",
        //   icon: "fa fa-search",
        //   command: (event) => {
        //     this.searchincoll();
        //   },
        // },
      ];
    } else if(e.node.Level == 1 && e.node.children){
      this.tcmitem = [
        {
          label: "Add New Year",
          icon: "fa fa-folder",
          command: (event) => {
            this.newYearcolpopfunc();
          },
        },
        // {
        //   label: "Edit Company",
        //   icon: "fa fa-folder",
        //   command: (event) => {
        //     this.showeditcollpop();
        //   },
        // },
        // {
        //   label: "Search In",
        //   icon: "fa fa-search",
        //   command: (event) => {
        //     this.searchincoll();
        //   },
        // },
      ];
    }
    else if (e.node.Level == 2 && !e.node.children){
      this.tcmitem = [
        {
          label: "Add New Month",
          icon: "fa fa-folder",
          command: (event) => {
            this.newMonthcolpopfunc();
          },
        },
        {
          label: "Edit Year",
          icon: "fa fa-folder",
          command: (event) => {
            this.editYearcolpopfunc();
          },
        },
        {
          label: "Delete Year",
          icon: "fa fa-folder",
          command: (event) => {
            this.deletecoll();
          },
        },
        // {
        //   label: "Search In",
        //   icon: "fa fa-search",
        //   command: (event) => {
        //     this.searchincoll();
        //   },
        // },
      ];
    } 
    else if(e.node.Level == 2 && e.node.children){
      this.tcmitem = [
        {
          label: "Add New Month",
          icon: "fa fa-folder",
          command: (event) => {
            this.newMonthcolpopfunc();
          },
        },
        // {
        //   label: "Edit Year",
        //   icon: "fa fa-folder",
        //   command: (event) => {
        //     this.editYearcolpopfunc();
        //   },
        // },
        // {
        //   label: "Search In",
        //   icon: "fa fa-search",
        //   command: (event) => {
        //     this.searchincoll();
        //   },
        // },
      ];
    } 
    else {
      const key = e.node.key1;
      const path = e.node.data;
      this.dashboarservice.getfolderdata(key, path).subscribe((data: any) => {
        if(data[1].length > 0){
          this.tcmitem = [
            {
              label: "Check In File",
              icon: "fa fa-upload",
              command: (event) => {
                this.showcheckin();
              },
            },
            {
              label: "Check In Folder",
              icon: "fa fa-upload",
              command: (event) => {
                this.showcheckinfldr();
              },
            },
            // {
            //   label: "Edit Month",
            //   icon: "fa fa-folder",
            //   command: (event) => {
            //     this.editMonthcolpopfunc();
            //   },
            // },
          ];
        } else {
          this.tcmitem = [
            {
              label: "Check In File",
              icon: "fa fa-upload",
              command: (event) => {
                this.showcheckin();
              },
            },
            {
              label: "Check In Folder",
              icon: "fa fa-upload",
              command: (event) => {
                this.showcheckinfldr();
              },
            },
            {
              label: "Edit Month",
              icon: "fa fa-folder",
              command: (event) => {
                this.editMonthcolpopfunc();
              },
            },
            {
              label: "Delete Month",
              icon: "fa fa-folder",
              command: (event) => {
                this.deletecoll();
              },
            },
          ];
        }
      });
      
    }
    
    
    this.tcmnodekey = e;
    this.tcmnodepath = e;
    this.tcmitem[3].disabled = false;
    this.tcmitem[4].disabled = false;
    this.selectedkey = e.node.key.split("/")[e.node.key.split("/").length - 1];
    if (e.node.children != undefined) {
      if (e.node.children.length > 0) {
        this.tcmitem[3].disabled = true;
        this.tcmitem[4].disabled = true;
      }
    } else {
      var key = e.node.key.split("/")[e.node.key.split("/").length - 1];
      this.selectedkey = key;
      this.dashboarservice.checkdir(key).subscribe((data: any) => {
        if (data != "") {
          this.tcmitem[3].disabled = true;
          this.tcmitem[4].disabled = true;
        }
      });
    }
  }

  hideshowcol(e) {
    //alert(e.itemValue)
    //var x=document.querySelectorAll("."+e.itemValue)
    // for(let i=0;i<x.length;i++){
    //   if(x[i]["style"].display=="none"){
    //     x[i]["style"].display="table-cell"
    //   }
    //   else{
    //     x[i]["style"].display="none"
    //   }
    // }
    //alert(this.filenamecol)
    //alert(this[e.itemValue])
    this[e.itemValue] = !this[e.itemValue];
  }

  showcheckin() {
    if (this.insertFile == 1) {
      this.checkinpop = true;
    }
  }

  clearcheckin() {
    this.checkinpop = false;
    this.checkinfileup.nativeElement.value = "";
  }

  //search folder in the collection from folder tree is not working currently
  searchfolder() {
    this.srchtext = "";
    this.glbadvancefilter = "";
    // console.log("foldersrchtxt", this.foldersrchtxt);

    if (this.foldersrchtxt != "") {
      this.dashboarservice
        .searchfolder(this.foldersrchtxt.replace(new RegExp("'", "g"), "''"))
        .subscribe((data: any) => {
          // console.log("data", data);

          if (data != "") {
            var arr = [];
            if (data.length > 0) {
              for (let i = 0; i < data.length; i++) {
                var Treeview_Key = data[i]["TreeViewId"].toString();
                var Parent_Key = data[i]["ParentTreeId"].toString();
                var Parent_Path = data[i]["CollectionPath"].toString();
                var Parent_name = Parent_Path.split("/")
                  [Parent_Path.split("/").length - 1].replace(
                    new RegExp("'", "g"),
                    "\\'"
                  )
                  .replace(new RegExp('"', "g"), '\\"')
                  .replace(new RegExp("//", "g"), "\\\\");
                if (
                  Parent_name.toLowerCase().indexOf(this.foldersrchtxt) > -1
                ) {
                  var key = "";
                  for (
                    let j = 0;
                    j <
                    Parent_Path.replace(/\\/g, "\\\\")
                      .replace(new RegExp("'", "g"), "\\'")
                      .replace(new RegExp('"', "g"), '\\"')
                      .replace(new RegExp("//", "g"), "\\\\")
                      .split("/").length -
                      1;
                    j++
                  ) {
                    key += "/";
                  }
                  arr.push({
                    label: Parent_name,
                    data: Parent_Path.replace(/\\/g, "\\\\")
                      .replace(new RegExp("'", "g"), "\\'")
                      .replace(new RegExp('"', "g"), '\\"')
                      .replace(new RegExp("//", "g"), "\\\\"),
                    key: key + Treeview_Key,
                    expandedIcon: "pi pi-folder-open",
                    collapsedIcon: "pi pi-folder",
                  });
                }
              }
            }
            // this.showfulltree = 1
            // this.files3 = arr
            this.files1 = arr;
          }
        });
    } else {
      // this.showfulltree = 0
      this.files3 = this.files1;
      //  this.gettreejson("")
    }
  }

  showcheckinfldr() {
    if (this.insertFolder == 1) {
      this.checkinfldrpop = true;
    }
  }

  clearcheckinfldr() {
    this.checkinfldrpop = false;
    this.checkinfileupfl.nativeElement.value = "";
  }

  checkinlistfnfldr(e) {
    var arr = [];

    for (let i = 0; i < e.target.files.length; i++) {
      arr.push({
        file: e.target.files[i],
        relativePath: e.target.files[i].webkitRelativePath,
      });
      if (i == e.target.files.length - 1) {
        this.checkdataforupload(arr, this.tcmnodekey);
      }
    }
    this.clearcheckinfldr();
  }

  showcollpop() {
    if (this.createFolder == 1) {
      this.newcolpop = true;
    }
  }
  showeditcollpop() {
    this.editcolpop = true;
  }

  hideeditcollpop() {
    this.editcolpop = false;
    this.newcollname = "";
  }

  hidecollpop() {
    this.newcolpop = false;
    this.newcollname = "";
  }

  newYearcolpopfunc(){
    this.newYearcolpop = true;
    this.newcollname = this.selectedYear;
  }
  newMonthcolpopfunc(){
    this.newMonthcolpop = true;
    this.newcollname = this.selectedMonth;
  }
  editYearcolpopfunc(){
    this.editYearcolpop = true;
    this.newcollname = this.selectedYear;
  }
  editMonthcolpopfunc(){
    this.editMonthcolpop = true;
    this.newcollname = this.selectedMonth;
  }


  savecoll(dialogType:string) {

    if (dialogType === 'company') {

      if (!this.newcollname || this.newcollname.trim() === "") {
        this.messageservice.add({
          key: "message",
          severity: "warning",
          summary: "Warning Message",
          detail: "Company name cannot be empty.",
        });
        return;
      }
    } else if (dialogType === 'year') {
      this.newcollname = this.selectedYear;
      if (!this.newcollname) {
        this.messageservice.add({
          key: "message",
          severity: "warning",  
          summary: "Warning Message",
          detail: "Please select a year.",
        });
        return;
      }
    } else if (dialogType === 'month') {
      this.newcollname = this.selectedMonth;
      if (!this.newcollname) {
        this.messageservice.add({
          key: "message",
          severity: "warning",
          summary: "Warning Message",
          detail: "Please select a month.",
        });
        return;
      }
    }

    if (this.newcollname != "" || this.newcollname != undefined) {
      // console.log("tcmnodekey",this.tcmnodekey);
      // console.log("tcmnodepath",this.tcmnodepath);
      // console.log("newcollname value",this.newcollname);
      

      var pkey =
        this.tcmnodekey.node.key.split("/")[
          this.tcmnodekey.node.key.split("/").length - 1
        ];
      var path =
        this.tcmnodepath.node.data +
        "/" +
        this.newcollname
        .trim()
        .replace(/[`~!@#$%^&*|+\-=?;:'".<>\{\}\[\]\\\/]/gi, " ")
        .trim();
      this.dashboarservice
        .createcoll(pkey, path.trim(),null)
        .subscribe((data: any) => {
          if (data == "Exist") {
            this.messageservice.add({
              key: "message",
              severity: "warning",
              summary: "Warning Message",
              detail: "Data Allready Exist",
            });
          } else {
            this.messageservice.add({
              key: "message",
              severity: "success",
              summary: "Success Message",
              detail: "Company Added",
            });
            //this.gettreejson1(this.tcmnodekey);
            this.apendFolderColl(data, path);
            this.hidecollpop();
            this.newYearcolpop = false;
            this.newMonthcolpop = false;
          }
        });
    } else {
      this.messageservice.add({
        key: "message",
        severity: "warning",
        summary: "Warning Message",
        detail: "Do not use any special characters... ",
      });
    }
  }


  apendFolderColl(key, colpath1) {
    var tmpchild = [];
    if (this.tcmnodekey.node.children) {
      var tmpchild1 = this.tcmnodekey.node.children[0];
      tmpchild["Level"] = tmpchild1.Level;
      tmpchild["ParentTreeId"] = tmpchild1.ParentTreeId;
      tmpchild["TreeViewId"] = key;
      tmpchild["UserPermissions"] = tmpchild1.UserPermissions;
      tmpchild["collapsedIcon"] = tmpchild1.collapsedIcon;
      tmpchild["data"] = colpath1;
      tmpchild["expandedIcon"] = tmpchild1.expandedIcon;
      tmpchild["key"] =
        tmpchild1.key.substring(0, tmpchild1.key.lastIndexOf("/") + 1) + key;
      tmpchild["key1"] = key;
      tmpchild["label"] = this.newcollname;
      tmpchild["parent"] = tmpchild1.parent;
      //console.log(tmpchild)
      this.tcmnodekey.node.children.push(tmpchild);
    } else {
      var tmpchild1 = this.tcmnodekey.node;
      tmpchild.push({
        Level: tmpchild1.Level + 1,
        ParentTreeId: tmpchild1.TreeViewId,
        TreeViewId: key,
        UserPermissions: tmpchild1.UserPermissions,
        collapsedIcon: tmpchild1.collapsedIcon,
        data: colpath1,
        expandedIcon: tmpchild1.expandedIcon,
        key: tmpchild1.key + "/" + key,
        key1: key,
        label: this.newcollname,
        parent: this.tcmnodekey.node,
      });
      //console.log(tmpchild)
      this.tcmnodekey.node["children"] = tmpchild;
    }
  }

  
  //editing name of the folder in collection
  editcoll(dialogType:string) {
    if (dialogType === 'company') {

      if (!this.newcollname || this.newcollname.trim() === "") {
        this.messageservice.add({
          key: "message",
          severity: "warning",
          summary: "Warning Message",
          detail: "Company name cannot be empty.",
        });
        return;
      }
    } else if (dialogType === 'year') {
      this.newcollname = this.selectedYear;
      if (!this.newcollname) {
        this.messageservice.add({
          key: "message",
          severity: "warning",
          summary: "Warning Message",
          detail: "Please select a year.",
        });
        return;
      }
    } else if (dialogType === 'month') {
      this.newcollname = this.selectedMonth;
      if (!this.newcollname) {
        this.messageservice.add({
          key: "message",
          severity: "warning",
          summary: "Warning Message",
          detail: "Please select a month.",
        });
        return;
      }
    }

    if (this.newcollname != "" || this.newcollname != undefined) {
      // console.log("edit company",this.tcmnodekey);
      
      // this.newcollname.replace(/[~`!@#$%^&*()+={}\[\];:\'\"<>.,\/\\\?-_]/g, '')
      var pkey =
        this.tcmnodekey.node.key.split("/")[
          this.tcmnodekey.node.key.split("/").length - 1
        ];
      var path =
        this.tcmnodepath.node.data.substring(
          0,
          this.tcmnodepath.node.data.lastIndexOf("/")
        ) +
        "/" +
        this.newcollname;
      this.tcmnodepath.node.label = this.newcollname;
      this.dashboarservice
        .editcoll(
          pkey,
          path,
          this.newcollname
            .trim()
            .replace(/[`~!@#$%^&*_|+\-=?;:'".<>\{\}\[\]\\\/]/gi, " ")
            .trim()
        )
        .subscribe((data: any) => {
          if (data == "Exist") {
            this.messageservice.add({
              key: "message",
              severity: "warning",
              summary: "Warning Message",
              detail: "Company Allready Exist",
            });
          } else {
            this.messageservice.add({
              key: "message",
              severity: "success",
              summary: "Success Message",
              detail: "Company Updated",
            });
          }
        });
        this.gettreejson1(this.tcmnodekey);
        this.hideeditcollpop();
        this.editYearcolpop = false;
        this.editMonthcolpop = false;
    } else {
      this.messageservice.add({
        key: "message",
        severity: "warning",
        summary: "Warning Message",
        detail: "Do not use any special characters... ",
      });
    }
  }
  

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field.split(",")[0]];
      if (value1 == undefined) {
        value1 = data1[event.field.split(",")[1]];
      }
      let value2 = data2[event.field.split(",")[0]];
      if (value2 == undefined) {
        value2 = data2[event.field.split(",")[1]];
      }
      let result = null;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === "string" && typeof value2 === "string")
        result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order * result;
    });
  }

//   delete from treeviewstructure where TreeViewId='${key}';
// I have this query to delete a row but I want to check the CollectionPath, for all 

  //delete folder from the collection
  deletecoll() {
    this.confirmationService.confirm({
      message: "Are you sure that you want to Delete?",
      header: "Confirmation",
      key: "treeviewkey",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        var pkey =
          this.tcmnodekey.node.key.split("/")[
            this.tcmnodekey.node.key.split("/").length - 1
          ];
        var pname = this.tcmnodekey.node.data;
        this.dashboarservice.dltcoll(pkey, pname).subscribe((data: any) => {
          this.messageservice.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail: "Company Deleted",
          });
          this.gettreejson1(this.tcmnodekey);
          for (
            let i = 0;
            i < this.tcmnodekey.node.parent.children.length;
            i++
          ) {
            if (this.tcmnodekey.node.parent.children[i].TreeViewId == pkey) {
              this.tcmnodekey.node.parent.children.splice(i, 1);
            }
          }
        });
      },
      reject: () => {},
    });
  }



  

  //accesssing existing folders and data of the folder
  getfolderdata(key, name, label) {
    //  console.log("getfolderdata", key, name, label)

    this.indexPrev = null;
    this.removeselection();

    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    this.srchtext = "";
    this.glbadvancefilter = "";
    var tmpkeyarr = key.split("/");
    var tmpamearr = name.split("/");
    var str = key.split("/")[key.split("/").length - 1];
    if (str == "") {
      this.loading = false;
      return;
    }
    var tmpname = "";
    var tmpkey = "";
    this.bditems = [];
    for (let j = 0; j < tmpamearr.length; j++) {
      tmpname = tmpname + "/" + tmpamearr[j];
      tmpkey = tmpkey + "/" + tmpkeyarr[j];
      if (j == tmpamearr.length - 1) {
        tmpamearr[j] = label;
      }

      this.bditems.push({
        label: tmpamearr[j],
        icon: "fa fa-folder",
        id: tmpkey.replace("/", ""),
        target: tmpname.replace("/", ""),
        command: (event) => {
          this.getfolderdata(
            event.item.id,
            event.item.target,
            event.item.label
          );
        },
      });
    }
    this.treeactivekey = key + "/";

    this.loading = true;

    this.dashboarservice.getfolderdata(str, name).subscribe((data: any) => {
      if (data.length > 0) {
        //  console.log("folder data",data);

        this.folderdata = data[0];
        data[1].sort((a, b) => b.Udate1.localeCompare(a.Udate1));
        this.elementarr = data[1];
        // this.selectedIndexs = []
        // console.log("folder data elementarr",this.elementarr);

        this.loading = false;
        this.totalcount = this.elementarr.length.toString();
        this.totalfcount = this.folderdata.length.toString();
        // console.log("elementarr getfolderdata",this.elementarr);
        
      } else {
        return;
      }
      this.loading = false;
      // this.customCmshow();
    });
  }

  alertdemo() {
    alert("succes");
    //(click)='getfolderdata(\""+jsdata[i]["TreeViewId"]+"\")'
    //onclick='Window.myComponent.getfolderdata(\""+jsdata[i]["TreeViewId"]+"\")'
  }

  onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  onDragLeave1(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  drop(event) {
    // console.log("drop", event);
    const items = event.dataTransfer.items;
    //this.items1=items;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.kind === "file") {
        const entry = item.webkitGetAsEntry();
        if (entry.isFile) {
        } else if (entry.isDirectory) {
          this.parseDirectoryEntry(entry);
        }
      }
    }
  }

  parseFileEntry(fileEntry) {
    return new Promise((resolve, reject) => {
      fileEntry.file(
        (file) => {
          resolve(file);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  parseDirectoryEntry(directoryEntry) {
    const directoryReader = directoryEntry.createReader();
    return new Promise((resolve, reject) => {
      directoryReader.readEntries(
        (entries) => {
          resolve(entries);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  filesPicked(files) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const path = file.webkitRelativePath.split("/");
      // upload file using path
    }
  }

  public fileOver(event) {
    // console.log(event);
  }

  public fileLeave(event) {
    // console.log(event);
  }

  bindtreeview() {
    this.files1 = [
      {
        label: "C:",
        data: "C:",
        key: "26",
        expandedIcon: "pi pi-folder-open",
        collapsedIcon: "pi pi-folder",
      },

      {
        label: "D:",
        data: "D:",
        key: "1",
        expandedIcon: "pi pi-folder-open",
        collapsedIcon: "pi pi-folder",
        children: [
          {
            label: "D:\\Indi Today1",
            data: "D:\\Indi Today1",
            key: "25",
            expandedIcon: "pi pi-folder-open",
            collapsedIcon: "pi pi-folder",
          },
          {
            label: "D:\\India Today",
            data: "D:\\India Today",
            key: "2",
            expandedIcon: "pi pi-folder-open",
            collapsedIcon: "pi pi-folder",
          },
          {
            label: "D:\\Mail Today",
            data: "D:\\Mail Today",
            key: "23",
            expandedIcon: "pi pi-folder-open",
            collapsedIcon: "pi pi-folder",
          },
          {
            label: "D:\\TV Today",
            data: "D:\\TV Today",
            key: "24",
            expandedIcon: "pi pi-folder-open",
            collapsedIcon: "pi pi-folder",
          },
        ],
      },

      {
        label: "E:",
        data: "E:",
        key: "27",
        expandedIcon: "pi pi-folder-open",
        collapsedIcon: "pi pi-folder",
      },
    ];
  }

  onSelectedChange(e) {
    this.val = e.node.key;
    //this.blindsearchwithdata('');
    this.getfolderdata(e.node.key, e.node.data, e.node.label);
  }

  nodeexpand(e) {
    alert(e.node.key);
  }

  changethumb(e) {
    // console.log(e)
  }

  hideuploadpop() {
    this.displayProcessBar = false;
    //.gettreejson1(this.dropedvalue)
  }

  //this function is calling from tree.js (primeng/component line 1175) file to upload the file on drag and drop in collection
  checkdataforupload(files, pathkey) {
    //  console.log("checkdataforupload");

    for (let j = 0; j < files.length; j++) {
      let relativePathFile = files[j].relativePath;
      let fileNameFile = files[j].file.name;
      if (relativePathFile.charAt(0) != "." && fileNameFile.charAt(0) != ".") {
        let relative_path = relativePathFile.split(".").filter((e) => e != "");
        let relativeFileExtension = relative_path.pop();
        let newRelativeFileName = relative_path
          .join("")
          .replace(/[^a-zA-Z0-9-_]/g, " ")
          .replace(/\s+/g, " ")
          .trim();

        let file_name = fileNameFile.split(".").filter((e) => e != "");
        let fileExtension = file_name.pop();
        let newFileName = file_name
          .join("")
          .replace(/[^a-zA-Z0-9-_]/g, " ")
          .replace(/\s+/g, " ")
          .trim();

        relativePathFile = `${newRelativeFileName}.${relativeFileExtension}`;
        fileNameFile = `${newFileName}.${fileExtension}`;
        // console.log(relativePathFile,fileNameFile);

        if (
          (environment.downloadpathLocal + pathkey + relativePathFile).length >
          230
        ) {
          this.messageservice.add({
            key: "message",
            severity: "warn",
            summary: "Warning Message",
            detail: "Some selected files have too long path and filename..",
          });
          return;
        }
      }
    }
    this.filesforuploadglb = files;
    for (let i = 0; i < files.length; i++) {
      if (
        files[i].file.name.substring(
          files[i].file.name.lastIndexOf(".") + 1,
          files[i].file.name.length
        ) != "pdf"
      ) {
        this.itemtype = "other";
        break;
      } else {
        this.itemtype = "pdf";
      }
    }
    this.pathforupload = pathkey;
    this.uploadform = true;
  }

  hideuploadform() {
    this.itemtype = "";
  }

  //drag and drop of file to upload in collection (not working currently)
  public droppedontree1(dataforup) {
    var files = this.filesforuploadglb;
    var pathkey = this.pathforupload;
    this.uploadform = false;
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.dropedvalue = pathkey;
    var tempdata = pathkey.node.data;
    pathkey = pathkey.node.key;
    pathkey = pathkey.split("/")[pathkey.split("/").length - 1];
    this.files2 = [];
    var filesforupload = [];
    var date = new Date();
    var timeString = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
      .toISOString()
      .split("T")[1];
    var timeformat = timeString.split(".")[0];
    var timeforup =
      timeformat.split(":")[0] +
      timeformat.split(":")[1] +
      timeformat.split(":")[2];
    for (let j = 0; j < files.length; j++) {
      var tmppath = tempdata;
      this.files2.push({ relativePath: files[j].relativePath });
      var x1 = files[j].relativePath;
      var filext1 = files[j].file.name;
      var y1 = "";
      var a1 = "";
      if (x1.trim().indexOf("/") == 0) {
        y1 = x1.replace("/", "");
      } else {
        y1 = x1;
      }
      if (y1.trim().indexOf("/") == -1) {
        a1 = "";
      } else {
        a1 = y1;
      }
      if (a1 == "") {
        tmppath = tmppath + "/" + a1 + "/" + filext1;
      } else {
        tmppath = tmppath + "/" + a1;
      }

      this.displayProcessBar = true;

      filesforupload.push(files[j]);
      //}
      if (j == files.length - 1) {
        var idx: number = 0;

        (function loop() {
          if (idx < filesforupload.length) {
            var x = filesforupload[idx].relativePath;
            var filext = filesforupload[idx].file.name;
            var y = "";
            var a = "";
            if (x.trim().indexOf("/") == 0) {
              y = x.replace("/", "");
            } else {
              y = x;
            }
            if (y.trim().indexOf("/") == -1) {
              a = "";
            } else {
              a = y;
            }
            const formData = new FormData();
            formData.append("photo", filesforupload[idx].file);

            var xhr = new XMLHttpRequest();
            xhr.open(
              "post",
              environment.ApiUrl +
                "treeviewupload/api/upload?fpath=" +
                a +
                "&pathkey=" +
                pathkey +
                "&filext=" +
                filext +
                "&userid=" +
                userid +
                "&dataforup=" +
                JSON.stringify(dataforup).replace(/null/g, '""'),
              true
            );
            var prct1 = "";
            xhr.upload.onprogress = function (e) {
              if (e.lengthComputable) {
                var percentage = (e.loaded / e.total) * 100;
                prct1 = percentage + "%";
                // console.log("filesforupload[idx].relativePath", filesforupload[idx].relativePath);

                document.getElementById(
                  filesforupload[idx].relativePath
                ).style.width = prct1;
                document
                  .getElementById(filesforupload[idx].relativePath)
                  .setAttribute("aria-valuenow", prct1);
                document.getElementById(
                  filesforupload[idx].relativePath
                ).innerText = prct1;
                //console.log(percentage + "%");
                if (percentage == 100) {
                }
              }
            };
            xhr.onerror = function (e) {};
            xhr.onload = function () {
              //console.log(this.statusText);
              var status = JSON.parse(this.response).success;
              if (status == "Duplicate") {
                // console.log("files[idx].relativePath", files[idx].relativePath);

                document.getElementById(files[idx].relativePath).style.width =
                  "100%";
                document.getElementById(
                  files[idx].relativePath
                ).style.background = "red";
                document
                  .getElementById(files[idx].relativePath)
                  .setAttribute("aria-valuenow", "100%");
                document.getElementById(files[idx].relativePath).innerText =
                  "Duplicate";
                idx++;
                loop();
              } else {
                if (this.statusText == "OK") {
                  idx++;
                  loop();
                }
              }
            };
            xhr.send(formData);
          }
        })();
      }
    }
  }

  checkinlistfn(e) {
    var arr = [];

    for (let i = 0; i < e.target.files.length; i++) {
      arr.push({
        file: e.target.files[i],
        relativePath: e.target.files[i].name,
      });
      if (i == e.target.files.length - 1) {
        this.checkdataforupload(arr, this.tcmnodekey);
        // console.log(arr);
      }
    }
    this.clearcheckin();
  }

  //drag and drop of file to upload in collection
  droppedontree(dataforup) {
    // console.log("upload image func droppedontree");
    this.duplicatelist = [];
    this.missedFiles = [];
    var files = this.filesforuploadglb;
    var pathkey = this.pathforupload;
    var parentTreeid = this.pathforupload.node.ParentTreeId;
    var companyIdKey = (this.pathforupload.node.key).split("/");
    var companyIdValue = companyIdKey[1];
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.dropedvalue = pathkey;
    var tempdata = pathkey.node.data;
    pathkey = pathkey.node.key;
    pathkey = pathkey.split("/")[pathkey.split("/").length - 1];
    this.files2 = [];
    var filesforupload = [];
    const formData = new FormData();
    let filenamearray: any[] = [];
    let filepatharray: any[] = [];
    let filesizearray: any = [];
    let filesizearraySum = 0;

    for (let j = 0; j < files.length; j++) {
      let newRelativeFileNameArray: any[] = [];
      let newRelativeFileName: any = "";
      let relativePathFile = files[j].relativePath;
      let fileNameFile = files[j].file.name;

      let relative_path = relativePathFile.split(".").filter((e) => e != "");
      let relativeFileExtension = relative_path.pop().trim();
      let newTrimRelaFileName = relative_path
        .join("")
        .replace(/[^a-zA-Z0-9-/\_]/g, " ")
        .replace(/\s+/g, " ")
        .split("/");

      for (let i = 0; i < newTrimRelaFileName.length; i++) {
        newRelativeFileNameArray.push(newTrimRelaFileName[i].trim());
      }

      newRelativeFileName = newRelativeFileNameArray.join("/");
      // newTrimRelativeFileName.forEach(element => {
      //   element.trim()
      //   newRelativeFileName =+ `${element}/`
      // });
      let file_name = fileNameFile.split(".").filter((e) => e != "");
      let fileExtension = file_name.pop().trim();
      let newFileName = file_name
        .join("")
        .trim()
        .replace(/[^a-zA-Z0-9-_]/g, " ")
        .trim()
        .replace(/\s+/g, " ")
        .trim();

      relativePathFile = `${newRelativeFileName}.${relativeFileExtension}`;
      fileNameFile = `${newFileName}.${fileExtension}`;
      filesizearray.push(files[j].file.size);
      if (
        relativePathFile.startsWith(".") == false &&
        fileNameFile.startsWith(".") == false
      ) {
        var tmppath = tempdata;
        this.files2.push({ relativePath: relativePathFile });
        var relativepath = relativePathFile.trim();
        var filename = fileNameFile.trim();
        var y1 = "";
        var a1 = "";
        if (relativepath.trim().indexOf("/") == 0) {
          y1 = relativepath.replace("/", "");
        } else {
          y1 = relativepath;
        }
        if (y1.trim().indexOf("/") == -1) {
          a1 = "";
        } else {
          a1 = y1;
        }
        if (a1 == "") {
          tmppath = tmppath + "/" + a1 + "/" + filename;
        } else {
          tmppath = tmppath + "/" + a1;
        }
        if ((a1 + filename).indexOf("~") > -1) {
        } else {
          filepatharray.push(a1);
          filenamearray.push(filename);
          formData.append(
            "photo",
            new File([files[j].file], filename),
            tmppath.trim()
          );
        }
        if (j == files.length - 1) {
          // console.log("dataforup", dataforup);
          filesizearraySum = filesizearray.reduce(
            (partialSum, a) => partialSum + a,
            0
          );
          // console.log(filesizearraySum);
          this.uploadform = false;
          this.displayProcessBar = true;
          // setInterval(() => this.manageProgress(), 50 * files.length);
          this.dashboarservice
            .uploadfiles(tmppath, formData, filenamearray.length)
            .subscribe((event: HttpEvent<any>) => {
              switch (event.type) {
                case HttpEventType.Sent:
                  console.log("Request has been made!");
                  break;
                case HttpEventType.ResponseHeader:
                  console.log("Response header has been received!");
                  break;
                case HttpEventType.UploadProgress:
                  this.progressBarValue = Math.round(
                    Math.round((event.loaded / event.total!) * 100)
                  );
                  console.log(`Uploaded! ${this.progressBarValue}%`);
                  break;
                case HttpEventType.Response:
                  console.log("Files Uploaded Successfully!");
                  // this.gettreejson("");
                  this.gettreejson1("");
                  this.getfolderdata(pathkey,tempdata,this.dropedvalue.node.expandedIcon);
                  this.displayProcessBar = false;
                  this.insertUploadFilesData(
                    filepatharray,
                    filenamearray,
                    userid,
                    dataforup,
                    pathkey,
                    parentTreeid,
                    companyIdValue
                  );
              }
            });
        }
      } else {
        this.uploadform = false;
        this.displayProcessBar = false;
        this.messageservice.add({
          key: "message",
          severity: "warn",
          summary: "Warn Message",
          detail: "File Name Not Correct..",
        });
      }
    }
  }


  // droppedontree(dataforup) {
  //   console.log("upload image func droppedontree");
  //   this.duplicatelist = [];
  //   this.missedFiles = [];
  //   var files = this.filesforuploadglb;
  //   var pathkey = this.pathforupload;
  //   var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
  //   this.dropedvalue = pathkey;
  //   var tempdata = pathkey.node.data;
  //   pathkey = pathkey.node.key;
  //   pathkey = pathkey.split("/")[pathkey.split("/").length - 1];
  //   this.files2 = [];
  //   var filesforupload = [];
  //   const formData = new FormData();
  //   let filenamearray: any[] = [];
  //   let filepatharray: any[] = [];
  //   let filesizearray: any = [];
  //   let filesizearraySum = 0;
  
  //   for (let j = 0; j < files.length; j++) {
  //     // Use relativePath and fileName from the file directly without heavy manipulation
  //     let relativePathFile = files[j].relativePath.trim(); // Just trimming whitespace
  //     let fileNameFile = files[j].file.name.trim(); // Trimming whitespace
  
  //     // Logging for debugging purposes
  //     console.log("Uploading File:", relativePathFile, fileNameFile);
  
  //     // Check if the file name or path starts with a dot, skip those files
  //     if (!relativePathFile.startsWith(".") && !fileNameFile.startsWith(".")) {
  //       var tmppath = tempdata;
  //       this.files2.push({ relativePath: relativePathFile });
  
  //       // Construct the path for the upload
  //       var relativepath = relativePathFile.trim();
  //       var filename = fileNameFile.trim();
  //       var y1 = "";
  //       var a1 = "";
  
  //       // Clean up the path
  //       if (relativepath.indexOf("/") == 0) {
  //         y1 = relativepath.replace("/", "");
  //       } else {
  //         y1 = relativepath;
  //       }
  
  //       if (y1.indexOf("/") == -1) {
  //         a1 = "";
  //       } else {
  //         a1 = y1;
  //       }
  
  //       // Combine file path and name
  //       if (a1 == "") {
  //         tmppath = tmppath + "/" + a1 + "/" + filename;
  //       } else {
  //         tmppath = tmppath + "/" + a1;
  //       }
  
  //       // Only append if valid (does not contain '~')
  //       if ((a1 + filename).indexOf("~") == -1) {
  //         filepatharray.push(a1);
  //         filenamearray.push(filename);
  //         formData.append("photo", new File([files[j].file], filename), tmppath.trim());
  //       }
  
  //       // Handle the upload at the last file iteration
  //       if (j == files.length - 1) {
  //         filesizearraySum = filesizearray.reduce(
  //           (partialSum, a) => partialSum + a,
  //           0
  //         );
  //         console.log("Total Upload Size:", filesizearraySum);
  //         this.uploadform = false;
  //         this.displayProcessBar = true;
  
  //         // Initiate file upload
  //         this.dashboarservice
  //           .uploadfiles(tmppath, formData, filenamearray.length)
  //           .subscribe((event: HttpEvent<any>) => {
  //             switch (event.type) {
  //               case HttpEventType.Sent:
  //                 console.log("Request has been made!");
  //                 break;
  //               case HttpEventType.ResponseHeader:
  //                 console.log("Response header has been received!");
  //                 break;
  //               case HttpEventType.UploadProgress:
  //                 this.progressBarValue = Math.round(
  //                   Math.round((event.loaded / event.total!) * 100)
  //                 );
  //                 console.log(`Uploaded! ${this.progressBarValue}%`);
  //                 break;
  //               case HttpEventType.Response:
  //                 console.log("Files Uploaded Successfully!");
  //                 this.gettreejson("");
  //                 this.displayProcessBar = false;
  //                 this.insertUploadFilesData(
  //                   filepatharray,
  //                   filenamearray,
  //                   userid,
  //                   dataforup,
  //                   pathkey
  //                 );
  //             }
  //           });
  //       }
  //     } else {
  //       this.uploadform = false;
  //       this.displayProcessBar = false;
  //       this.messageservice.add({
  //         key: "message",
  //         severity: "warn",
  //         summary: "Warn Message",
  //         detail: "File Name Not Correct..",
  //       });
  //     }
  //   }
  // }
  

  insertUploadFilesData(
    filepatharray,
    filenamearray,
    userid,
    dataforup,
    pathkey,
    parentTreeid,
    companyIdValue
  ) {
    this.dashboarservice
      .uploaddata(
        filepatharray,
        filenamearray,
        userid,
        JSON.stringify(dataforup).replace(/null/g, '""').replace(/\\/g, "\\\\"),
        pathkey,
        parentTreeid,
        companyIdValue
      )
      .subscribe((data: any) => {
        // console.log("upload data",data, "duplicate",data.duplicate);
        this.displayProcessBar = false;
        setTimeout(() => {
          this.progressBarValue = 0;
        }, 1500);
        // if (data.files > 0) {

        if (data.duplicate.length > 0) {
          this.messageservice.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail: `${
              data.files - data.duplicate.length
            } Files are successfully uploaded`,
          });
          this.showduplicate = true;
          this.duplicatelist = data.duplicate;
        } else {
          // confirm(`     ${data.files} Files are successfully uploaded`)
          // if (data[1].success.length > 0) {
          //   this.missedFiles = data[1].success
          //   this.missedFilePopup = true
          // }
          this.messageservice.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail: `${
              data.files - data.duplicate.length
            } Files are successfully uploaded`,
          });
        }
        this.gettreejson1(this.dropedvalue);
        // }
      });
  }

  //check permission of user
  async checkpermissions() {
    this.permissionlist = JSON.parse(localStorage.getItem("userpermissions"));

    for (let i = 0; i < this.permissionlist.length; i++) {
      if (this.permissionlist[i].taskid == 34) {
        this.downloadbtn = 1;
      }
      if (this.permissionlist[i].taskid == 51) {
        this.imgdownloadbtn = 1;
      }
      if (this.permissionlist[i].taskid == 44) {
        this.imgcrppbtn = 1;
      }
      if (this.permissionlist[i].taskid == 45) {
        this.videoexportbtn = 1;
      }
      if (this.permissionlist[i].taskid == 46) {
        this.videocropbtn = 1;
      }
      if (this.permissionlist[i].taskid == 56) {
        this.storydownloadbtn = 1;
      }
      if (this.permissionlist[i].taskid == 48) {
        this.audiodownloadbtn = 1;
      }
      if (this.permissionlist[i].taskid == 55) {
        this.addtocartdoc = 1;
      }
      if (this.permissionlist[i].taskid == 54) {
        this.addtocartimage = 1;
      }
      if (this.permissionlist[i].taskid == 49) {
        this.addtocartaudio = 1;
      }
      if (this.permissionlist[i].taskid == 50) {
        this.addtocartvideo = 1;
      }
      if (this.permissionlist[i].taskid == 57) {
        this.addtocartstory = 1;
      }
      if (this.permissionlist[i].taskid == 14) {
        this.editdocmeta = 1;
      }
      if (this.permissionlist[i].taskid == 11) {
        this.editimgmeta = 1;
      }
      if (this.permissionlist[i].taskid == 6) {
        this.audiodelete = 1;
      }
      if (this.permissionlist[i].taskid == 9) {
        this.videodelete = 1;
      }
      if (this.permissionlist[i].taskid == 12) {
        this.imagedelete = 1;
      }
      if (this.permissionlist[i].taskid == 15) {
        this.docdelete = 1;
      }
      if (this.permissionlist[i].taskid == 18) {
        this.storydelete = 1;
      }
      if (this.permissionlist[i].taskid == 127) {
        this.insertFile = 1;
      }
      if (this.permissionlist[i].taskid == 128) {
        this.insertFolder = 1;
      }
      if (this.permissionlist[i].taskid == 129) {
        this.createFolder = 1;
      }
      if(this.permissionlist[i].taskid == 200){

      }
    }
  }

  // customCmshow(){
  //   this.dashboarservice.customcmShow().subscribe((data:any) => {
  //     this.allTask = data;
  //     console.log("custom cm show task ids", this.allTask);
  //   });
  // }
  
  imagesApprovalList:any[]=[];
sendRqstfordownload() {
  this.ismenuclick = true;

  const lst: any[] = this.selectedIndexs;
  const rtnarr = [];

  if (lst.length > 0) {
    for (let i = 0; i < lst.length; i++) {
      const imageid = lst[i].toString();
      const iid = document.getElementById(imageid);
      let fname = iid && iid.children[2] && iid.children[3].innerHTML.trim();
      let filepath = iid && iid.children[3] && iid.children[3].innerHTML.trim();

      if (fname && filepath) {
        filepath = filepath.replace("lowres/", "raw/");
        rtnarr.push({ filepath, filename: fname, itemid: imageid });
      }
    }
  }

  this.dashboarservice.sendRqstfordownload(rtnarr).subscribe((data: any) => {
    this.imagesApprovalList = data;
    // console.log("Images for approval sent:", this.imagesApprovalList);
  });
}



// getNotificationApproval() {
//   this.imageservice.getNotificationApproval().subscribe((data: any[]) => {
//     this.listofApprovals = data;
//     console.log("list of image approvals",this.listofApprovals);
//   });
//   this.displayApprovalDialog = true;
// }



 selectContextMenu(hasTask51,hasTask54,hasApprovedStatus){
  if (hasTask51 && hasTask54) {
    if(hasApprovedStatus){
      this.contextmenuitem = [
        {
          label: "Download",
          icon: "fa fa-download",
          command: (event) => {
            this.downloadmultiple();
          },
        },
        // {
        //   label: "Add to cart",
        //   icon: "fa fa-shopping-cart",
        //   command: (event) => {
        //     this.showmulcartdiv();
        //   },
        // },
        // {
        //   label: "Edit",
        //   icon: "fa fa-edit",
        //   command: (event) => {
        //     this.multipleedit();
        //   },
        // },
      ];
    } else {
      this.contextmenuitem = [
        {
          label: "Request for Approval",
          icon: "fa fa-download",
          command: (event) => {
            this.sendRqstfordownload();
          }
        }
      ];
    }
  } else if(hasTask51 && !hasTask54){
    if(hasApprovedStatus){
      this.contextmenuitem = [
        {
          label: "Download",
          icon: "fa fa-download",
          command: (event) => {
            this.downloadmultiple();
          },
        },
        {
          label: "Edit",
          icon: "fa fa-edit",
          command: (event) => {
            this.multipleedit();
          },
        },
      ];
    } else {
      this.contextmenuitem = [
        {
          label: "Request for Approval",
          icon: "fa fa-download",
          command: (event) => {
            this.sendRqstfordownload();
          }
        }
      ];
    }
  }
  else if (hasTask54 && !hasTask51) {
    this.contextmenuitem = [
      // {
      //   label: "Add to cart",
      //   icon: "fa fa-shopping-cart",
      //   command: (event) => {
      //     this.showmulcartdiv();
      //   },
      // },
    ];
  } else {
    this.contextmenuitem = [
      {
        label: "Request for Download",
        icon: "fa fa-download",
        command: (event) => {
          this.sendRqstfordownload();
        },
      },
    ];
  }
  
 }

  allTask:any;
  imageApproval:any;

  onImageRightClick(event: MouseEvent, imageId: string) {
    event.preventDefault(); // Prevent default context menu
    const hasTask51 = this.allTask.some((task: any) => task.taskid === '51');  //download image permission
    const hasTask54 = this.allTask.some((task: any) => task.taskid === '54');  // add to cart permission
    let isFind=false;
    this.imageApproval.forEach((item: any) => {
      if(imageId==item.imageId){
        isFind=true;
        if(item.status === 'A'){
          this.selectContextMenu(hasTask51,hasTask54,true);
        }else{
          this.selectContextMenu(hasTask51,hasTask54,false);
        }
      }

      if(!isFind){
        this.selectContextMenu(hasTask51,hasTask54,false);
      }
    });
    

  }


  cmshow() {
    // console.log("cmshow clicked");

    this.dashboarservice.customcmShow().subscribe((data:any) => {
      this.allTask = data;
      // console.log("all task",this.allTask);
      
      const hasTask51 = this.allTask.some((task: any) => task.taskid === '51');  //download image permission
      const hasTask54 = this.allTask.some((task: any) => task.taskid === '54');  // add to cart permission

      
      this.imageservice.getNotificationApproval().subscribe((data:any[]) => {
        this.imageApproval = data;
        // console.log("image approval in cmshow",this.imageApproval);

        const hasApprovedStatus: { [key: string]: boolean } = {};

        this.imageApproval.forEach((item: any) => {
          hasApprovedStatus[item.imageId] = item.status === 'A';

          // console.log("hastak51",hasTask51,"hastask54",hasTask54,"hasapprovedstatus",hasApprovedStatus);

          this.selectContextMenu(hasTask51,hasTask54,hasApprovedStatus);
        });
        
        

    
          this.rightclickmenuopen = true;
          this.contextmenuitem[0].disabled = false;
          this.contextmenuitem[1].disabled = false;
          this.contextmenuitem[2].disabled = false;
          this.contextmenuitem[3].disabled = false;
          if (
            this.downloadbtn == 0 ||
            this.imgdownloadbtn == 0 ||
            this.storydownloadbtn == 0 ||
            this.audiodownloadbtn == 0 ||
            this.videocropbtn == 0
          ) {
            this.contextmenuitem[0].disabled = false;
            for (let i = 0; i < this.selectedIndexstype.length; i++) {
              if (this.selectedIndexstype[i].toLowerCase().trim() == "image") {
                if (this.imgdownloadbtn == 0) {
                  this.contextmenuitem[0].disabled = true;
                  break;
                }
              } else if (this.selectedIndexstype[i].toLowerCase().trim() == "video") {
                if (this.videocropbtn == 0) {
                  this.contextmenuitem[0].disabled = true;
                  break;
                }
              } else if (this.selectedIndexstype[i].toLowerCase().trim() == "audio") {
                if (this.audiodownloadbtn == 0) {
                  this.contextmenuitem[0].disabled = true;
                  break;
                }
              } else if (this.selectedIndexstype[i].toLowerCase().trim() == "story") {
                if (this.storydownloadbtn == 0) {
                  this.contextmenuitem[0].disabled = true;
                  break;
                }
              } else {
                if (this.downloadbtn == 0) {
                  this.contextmenuitem[0].disabled = true;
                  break;
                }
              }
            }
          }
          if (
            this.addtocartdoc == 0 ||
            this.addtocartimage == 0 ||
            this.addtocartaudio == 0 ||
            this.addtocartvideo == 0 ||
            this.addtocartstory == 0
          ) {
            this.contextmenuitem[1].disabled = false;
            for (let i = 0; i < this.selectedIndexstype.length; i++) {
              if (this.selectedIndexstype[i].toLowerCase().trim() == "image") {
                if (this.addtocartimage == 0) {
                  this.contextmenuitem[1].disabled = true;
                  break;
                }
              } else if (this.selectedIndexstype[i].toLowerCase().trim() == "video") {
                if (this.addtocartvideo == 0) {
                  this.contextmenuitem[1].disabled = true;
                  break;
                }
              } else if (this.selectedIndexstype[i].toLowerCase().trim() == "audio") {
                if (this.addtocartaudio == 0) {
                  this.contextmenuitem[1].disabled = true;
                  break;
                }
              } else if (this.selectedIndexstype[i].toLowerCase().trim() == "story") {
                if (this.addtocartstory == 0) {
                  this.contextmenuitem[1].disabled = true;
                  break;
                }
              } else {
                if (this.addtocartdoc == 0) {
                  this.contextmenuitem[1].disabled = true;
                  break;
                }
              }
            }
          }
          if (this.editdocmeta == 0 || this.editimgmeta == 0) {
            this.contextmenuitem[2].disabled = false;
            for (let i = 0; i < this.selectedIndexstype.length; i++) {
              if (this.selectedIndexstype[i].toLowerCase().trim() == "image") {
                if (this.editimgmeta == 0) {
                  this.contextmenuitem[2].disabled = true;
                  break;
                }
              } else {
                if (this.editdocmeta == 0) {
                  this.contextmenuitem[2].disabled = true;
                  break;
                }
              }
            }
          }
          if (
            this.audiodelete == 0 ||
            this.videodelete == 0 ||
            this.imagedelete == 0 ||
            this.docdelete == 0 ||
            this.storydelete == 0
          ) {
            this.contextmenuitem[4].disabled = false;
            for (let i = 0; i < this.selectedIndexstype.length; i++) {
              if (this.selectedIndexstype[i].toLowerCase().trim() == "image") {
                if (this.imagedelete == 0) {
                  this.contextmenuitem[4].disabled = true;
                  break;
                }
              } else if (this.selectedIndexstype[i].toLowerCase().trim() == "video") {
                if (this.videodelete == 0) {
                  this.contextmenuitem[4].disabled = true;
                  break;
                }
              } else if (this.selectedIndexstype[i].toLowerCase().trim() == "audio") {
                if (this.audiodelete == 0) {
                  this.contextmenuitem[4].disabled = true;
                  break;
                }
              } else if (this.selectedIndexstype[i].toLowerCase().trim() == "story") {
                if (this.storydelete == 0) {
                  this.contextmenuitem[4].disabled = true;
                  break;
                }
              } else {
                if (this.docdelete == 0) {
                  this.contextmenuitem[4].disabled = true;
                  break;
                }
              }
            }
          }
          for (let i = 0; i < this.selectedIndexspermis.length; i++) {
            if (this.selectedIndexspermis[i] == 0) {
              this.contextmenuitem[0].disabled = true;
              this.contextmenuitem[1].disabled = true;
              this.contextmenuitem[2].disabled = true;
              this.contextmenuitem[3].disabled = true;
              break;
            }
          }

          
      });
    });

    // console.log("cmshow clicked");
  
  // Fetch all tasks to check permissions
  // this.dashboarservice.customcmShow().subscribe((data: any) => {
  //   this.allTask = data;
  //   const hasTask51 = this.allTask.some((task: any) => task.taskid === '51');  // download image permission
  //   const hasTask54 = this.allTask.some((task: any) => task.taskid === '54');  // add to cart permission

  //   // Fetch image approval data and filter for the selected image
  //   this.imageservice.getNotificationApproval().subscribe((imageApprovals: any[]) => {
  //     this.imageApproval = imageApprovals;
  //     console.log("image approvals",this.imageApproval);
      
  //     // Check the status for the selected image only
  //     const selectedImageApproval = this.imageApproval.find((item: any) => item.imageId === selectedImage.imageId);
  //     const hasApprovedStatus = selectedImageApproval && selectedImageApproval.status === 1;

  //     console.log("Permissions:", hasTask51, hasTask54, "Approval status:", hasApprovedStatus);

  //     // Configure the context menu based on permissions and status
  //     if (hasTask51 && hasTask54) {
  //       if (hasApprovedStatus) {
  //         this.contextmenuitem = [
  //           {
  //             label: "Download",
  //             icon: "fa fa-download",
  //             command: () => this.downloadmultiple(),
  //           },
  //           {
  //             label: "Add to cart",
  //             icon: "fa fa-shopping-cart",
  //             command: () => this.showmulcartdiv(),
  //           },
  //           {
  //             label: "Edit",
  //             icon: "fa fa-edit",
  //             command: () => this.multipleedit(),
  //           },
  //         ];
  //       } else {
  //         this.contextmenuitem = [
  //           {
  //             label: "Has permission but can't download",
  //             icon: "fa fa-download",
  //             command: () => {},
  //           },
  //         ];
  //       }
  //     } else if (hasTask51 && !hasTask54) {
  //       if (hasApprovedStatus) {
  //         this.contextmenuitem = [
  //           {
  //             label: "Download",
  //             icon: "fa fa-download",
  //             command: () => this.downloadmultiple(),
  //           },
  //           {
  //             label: "Edit",
  //             icon: "fa fa-edit",
  //             command: () => this.multipleedit(),
  //           },
  //         ];
  //       } else {
  //         this.contextmenuitem = [
  //           {
  //             label: "Has permission but can't download",
  //             icon: "fa fa-download",
  //             command: () => {},
  //           },
  //         ];
  //       }
  //     } else if (hasTask54 && !hasTask51) {
  //       this.contextmenuitem = [
  //         {
  //           label: "Add to cart",
  //           icon: "fa fa-shopping-cart",
  //           command: () => this.showmulcartdiv(),
  //         },
  //       ];
  //     } else {
  //       this.contextmenuitem = [
  //         {
  //           label: "Request for Download",
  //           icon: "fa fa-download",
  //           command: () => this.sendRqstfordownload(),
  //         },
  //       ];
  //     }


  //     this.rightclickmenuopen = true;
  //         this.contextmenuitem[0].disabled = false;
  //         this.contextmenuitem[1].disabled = false;
  //         this.contextmenuitem[2].disabled = false;
  //         this.contextmenuitem[3].disabled = false;
  //         if (
  //           this.downloadbtn == 0 ||
  //           this.imgdownloadbtn == 0 ||
  //           this.storydownloadbtn == 0 ||
  //           this.audiodownloadbtn == 0 ||
  //           this.videocropbtn == 0
  //         ) {
  //           this.contextmenuitem[0].disabled = false;
  //           for (let i = 0; i < this.selectedIndexstype.length; i++) {
  //             if (this.selectedIndexstype[i].toLowerCase().trim() == "image") {
  //               if (this.imgdownloadbtn == 0) {
  //                 this.contextmenuitem[0].disabled = true;
  //                 break;
  //               }
  //             } else if (this.selectedIndexstype[i].toLowerCase().trim() == "video") {
  //               if (this.videocropbtn == 0) {
  //                 this.contextmenuitem[0].disabled = true;
  //                 break;
  //               }
  //             } else if (this.selectedIndexstype[i].toLowerCase().trim() == "audio") {
  //               if (this.audiodownloadbtn == 0) {
  //                 this.contextmenuitem[0].disabled = true;
  //                 break;
  //               }
  //             } else if (this.selectedIndexstype[i].toLowerCase().trim() == "story") {
  //               if (this.storydownloadbtn == 0) {
  //                 this.contextmenuitem[0].disabled = true;
  //                 break;
  //               }
  //             } else {
  //               if (this.downloadbtn == 0) {
  //                 this.contextmenuitem[0].disabled = true;
  //                 break;
  //               }
  //             }
  //           }
  //         }
  //         if (
  //           this.addtocartdoc == 0 ||
  //           this.addtocartimage == 0 ||
  //           this.addtocartaudio == 0 ||
  //           this.addtocartvideo == 0 ||
  //           this.addtocartstory == 0
  //         ) {
  //           this.contextmenuitem[1].disabled = false;
  //           for (let i = 0; i < this.selectedIndexstype.length; i++) {
  //             if (this.selectedIndexstype[i].toLowerCase().trim() == "image") {
  //               if (this.addtocartimage == 0) {
  //                 this.contextmenuitem[1].disabled = true;
  //                 break;
  //               }
  //             } else if (this.selectedIndexstype[i].toLowerCase().trim() == "video") {
  //               if (this.addtocartvideo == 0) {
  //                 this.contextmenuitem[1].disabled = true;
  //                 break;
  //               }
  //             } else if (this.selectedIndexstype[i].toLowerCase().trim() == "audio") {
  //               if (this.addtocartaudio == 0) {
  //                 this.contextmenuitem[1].disabled = true;
  //                 break;
  //               }
  //             } else if (this.selectedIndexstype[i].toLowerCase().trim() == "story") {
  //               if (this.addtocartstory == 0) {
  //                 this.contextmenuitem[1].disabled = true;
  //                 break;
  //               }
  //             } else {
  //               if (this.addtocartdoc == 0) {
  //                 this.contextmenuitem[1].disabled = true;
  //                 break;
  //               }
  //             }
  //           }
  //         }
  //         if (this.editdocmeta == 0 || this.editimgmeta == 0) {
  //           this.contextmenuitem[2].disabled = false;
  //           for (let i = 0; i < this.selectedIndexstype.length; i++) {
  //             if (this.selectedIndexstype[i].toLowerCase().trim() == "image") {
  //               if (this.editimgmeta == 0) {
  //                 this.contextmenuitem[2].disabled = true;
  //                 break;
  //               }
  //             } else {
  //               if (this.editdocmeta == 0) {
  //                 this.contextmenuitem[2].disabled = true;
  //                 break;
  //               }
  //             }
  //           }
  //         }
  //         if (
  //           this.audiodelete == 0 ||
  //           this.videodelete == 0 ||
  //           this.imagedelete == 0 ||
  //           this.docdelete == 0 ||
  //           this.storydelete == 0
  //         ) {
  //           this.contextmenuitem[4].disabled = false;
  //           for (let i = 0; i < this.selectedIndexstype.length; i++) {
  //             if (this.selectedIndexstype[i].toLowerCase().trim() == "image") {
  //               if (this.imagedelete == 0) {
  //                 this.contextmenuitem[4].disabled = true;
  //                 break;
  //               }
  //             } else if (this.selectedIndexstype[i].toLowerCase().trim() == "video") {
  //               if (this.videodelete == 0) {
  //                 this.contextmenuitem[4].disabled = true;
  //                 break;
  //               }
  //             } else if (this.selectedIndexstype[i].toLowerCase().trim() == "audio") {
  //               if (this.audiodelete == 0) {
  //                 this.contextmenuitem[4].disabled = true;
  //                 break;
  //               }
  //             } else if (this.selectedIndexstype[i].toLowerCase().trim() == "story") {
  //               if (this.storydelete == 0) {
  //                 this.contextmenuitem[4].disabled = true;
  //                 break;
  //               }
  //             } else {
  //               if (this.docdelete == 0) {
  //                 this.contextmenuitem[4].disabled = true;
  //                 break;
  //               }
  //             }
  //           }
  //         }
  //         for (let i = 0; i < this.selectedIndexspermis.length; i++) {
  //           if (this.selectedIndexspermis[i] == 0) {
  //             this.contextmenuitem[0].disabled = true;
  //             this.contextmenuitem[1].disabled = true;
  //             this.contextmenuitem[2].disabled = true;
  //             this.contextmenuitem[3].disabled = true;
  //             break;
  //           }
  //         }

  //   });
  // });
  }





  // edit metadata of multple files
  multipleedit() {
    this.ismenuclick = true;

    var itemid = "";
    var nameforedit1 = "";
    var path = "normal";
    var orgname1 = "";
    var type = this.selectedIndexstype[0];
    for (let i = 0; i < this.selectedIndexs.length; i++) {
      itemid += this.selectedIndexsid[i] + "~";
      orgname1 +=
        document
          .getElementById(this.selectedIndexs[i].toString())
          .children[2].innerHTML.trim() + "~";
      if (type != this.selectedIndexstype[i]) {
        this.messageservice.add({
          key: "message",
          severity: "warn",
          summary: "Warning Message",
          detail: "Selected files are not similar..",
        });
        break;
      }
      type = this.selectedIndexstype[i];
      if (i == this.selectedIndexs.length - 1) {
        if (type.toLowerCase().trim() == "image") {
          this.ImageUpdated = true;
          this.idforimageedit = itemid;
          this.orgname = orgname1;
          this.imagenameforedit = nameforedit1;
        } else if (type.toLowerCase().trim() == "audio") {
        } else if (type.toLowerCase().trim() == "video") {
          this.videoupdate = true;
          this.videoidforedit = itemid;
          this.orgname = orgname1;
          this.videofileforup = nameforedit1;
        } else if (type.toLowerCase().trim() == "story") {
        } else {
          this.DocumentUpdated = true;
          this.docidforedit = itemid;
          this.orgname = orgname1;
          this.docfileforup = nameforedit1;
        }
      }
      if (i == this.selectedIndexs.length - 1) {
        this.rightclickmenuopen = false;
        this.ismenuclick = false;
      }
    }
  }

  //accessing all data through this function which is rendering on templete file
  blindsearch(type) {
    this.loading = true;
    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    if (this.srchtext != "") {
      this.blindsearchwithdata(type);
    } else {
      //environment.globalsearchtext=""
      //environment.glbsearchstring=""
      var tbastr = localStorage.getItem("tabperstring");
      // this.dashboarservice.blindsearch(tbastr,'0~0~0~0~0').subscribe((data:any[])=>{
      //   if(data!=null){
      //     data.sort((a,b) => b.Udate1.localeCompare(a.Udate1));
      //     this.elementarr=data;
      //     this.loading=false
      //     this.totalcount=this.elementarr.length.toString()
      //     this.totalfcount=this.folderdata.length.toString()
      //   }
      // })
      this.loading = false;
    }
    this.loading = false;
  }

  public ExtractSearchingKeyword(_TextVal: string): string {
    let MergeParamater: string = "";
    let Paramater: string = "";
    let split_Parma: string[];
    let startval: number = 1;
    let startind: number = 1;
    let endindex: number = 1;
    let Searchingparam: string = "";
    try {
      Paramater = _TextVal;
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\,/gi, " ");
      Paramater = Paramater.replace(/\+/gi, " ");
      if (Paramater.indexOf('"') >= 0) {
        Paramater = Paramater.replace(/\"/gi, "~");
        while (1) {
          startind = Paramater.indexOf("~", startval - 1) + 1;
          if (startind <= 0) {
            break;
          }

          endindex = Paramater.indexOf("~", startind) + 1;
          if (endindex <= 0) {
            break;
          }

          if (Searchingparam.trim() == "") {
            Searchingparam = Paramater.substring(
              startind - 1,
              startind + (endindex - startind + 1)
            ).trim();
            Paramater = Paramater.split(Searchingparam).join("");
            Paramater = Paramater.trim();
          } else {
            Searchingparam = (
              Searchingparam +
              "+" +
              Paramater.substring(
                startind - 1,
                startind + (endindex - startind + 1)
              )
            ).trim();
            Paramater = Paramater.split(
              Paramater.substring(
                startind - 1,
                startind + (endindex - startind + 1)
              )
            ).join("");
          }

          // startval = endindex
        }

        if (Searchingparam != "") {
          MergeParamater = Searchingparam.replace(/\~/gi, "");
        }

        if (Paramater.trim() != "") {
          if (MergeParamater == "") {
            MergeParamater = Paramater.trim().replace(/\ /gi, "+");
          } else {
            MergeParamater =
              MergeParamater + ("+" + Paramater.trim().replace(/\ /gi, "+"));
          }
        }

        MergeParamater = MergeParamater.split("+++").join("+");
        MergeParamater = MergeParamater.split("++").join("+");
        //  split_Parma = MergeParamater.Split("+")
      } else {
        MergeParamater = Paramater.replace(/\ /gi, "+");
        MergeParamater = MergeParamater.split("+++").join("+");
        MergeParamater = MergeParamater.split("++").join("+");
        // split_Parma = Paramater.Split(" ")
      }
    } catch (ex /*:Exception*/) {
      MergeParamater = _TextVal;
    }

    return MergeParamater;
  }

  //accesing data while search happened with search item
  blindsearchwithdata(type) {
    this.imagecount = "";
    this.doccount = "";
    this.videocount = "";
    this.audiocount = "";
    this.storycount = "";
    this.bditems = [];
    this.loading = true;
    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    if (this.srchtext == "undefined" || this.srchtext == undefined) {
      this.srchtext = "";
      this.newadvancesearch("");
    } else {
      this.srchtext = this.srchtext.trim();
      if (this.srchtext.trim() != "") {
        //environment.globalsearchtext=this.srchtext
        //environment.glbsearchstring=""
        var srtxt = this.ExtractSearchingKeyword(this.srchtext);
        if (this.selectedcollpath != "") {
          srtxt = srtxt + "ֆ" + this.selectedcollpath;
        }
        var tbastr = localStorage.getItem("tabperstring");
        this.dashboarservice
          .getacounttree(srtxt, this.searchin, tbastr)
          .subscribe((data2: any[]) => {
            if (data2 != null) {
              this.totalcount = data2.toString();
            }
          });
        this.dashboarservice
          .blindsearchwithdatatree(
            srtxt,
            "",
            tbastr,
            "0~0~0~0~0",
            this.searchin
          )
          .subscribe((data: any[]) => {
            if (data != null) {
              if (type == "") {
                var data1 = JSON.parse(
                  data.toString().split("~")[0].replace(/\ʁ/g, "~")
                );
                data1.sort((a, b) => b.Udate1.localeCompare(a.Udate1));
                this.elementarr = data1;
                this.folderdata = [];
                this.totalfcount = "";
                this.imagecount = data.toString().split("~")[1];
                this.doccount = data.toString().split("~")[2];
                this.videocount = data.toString().split("~")[3];
                this.audiocount = data.toString().split("~")[4];
                this.storycount = data.toString().split("~")[5];
                this.loading = false;
              } else {
                this.totalcount = data[0];
              }

              //this.totalcount=this.elementarr.length.toString()
            }
          });
      } else {
        if (environment.glbsearchstring != "") {
          //this.newadvancesearch(environment.glbsearchstring)
        }
        // else if (environment.globalsearchtext !="") {
        //   this.srchtext=environment.globalsearchtext
        //   var srtxt=this.ExtractSearchingKeyword(this.srchtext)
        //   var tbastr=localStorage.getItem("tabperstring")
        //   this.dashboarservice.getacount(srtxt).subscribe((data2:any[])=>{
        //     if(data2 !=null){
        //       this.totalcount=data2.toString()
        //     }
        //   })
        //   this.dashboarservice.blindsearchwithdata(srtxt,'',tbastr,"0~0~0~0~0").subscribe((data:any[])=>{
        //     if(data!=null){
        //       if(type==""){
        //         var data1=JSON.parse(data.toString().split("~")[0].replace(/\ʁ/g, "~"))
        //         data1.sort((a,b) => b.Udate1.localeCompare(a.Udate1));
        //         this.elementarr=data1;
        //         this.imagecount=data.toString().split("~")[1]
        //   this.doccount=data.toString().split("~")[2]
        //   this.videocount=data.toString().split("~")[3]
        //   this.audiocount=data.toString().split("~")[4]
        //   this.storycount=data.toString().split("~")[5]
        //        // this.totalcount=this.elementarr.length.toString()
        //         this.loading=false
        //       }
        //       else{
        //         //this.totalcount=data[0]
        //       }
        //       //this.totalcount=this.elementarr.length.toString()
        //     }
        //   })
        // }
        else {
          this.blindsearch("");
        }
      }
    }
    this.loading = false;
  }

  //seaching the data inside a PDF file, this is optional where frontend has specified button
  fulltextsrch(type, data) {
    this.hideadvncsrch();
    this.imagecount = "";
    this.doccount = "";
    this.videocount = "";
    this.audiocount = "";
    this.storycount = "";
    this.bditems = [];
    this.loading = true;
    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }

    data = data.trim();
    this.fulltextdata = data;
    if (data.trim() != "") {
      //environment.globalsearchtext=this.srchtext
      //environment.glbsearchstring=""
      var srtxt = this.ExtractSearchingKeyword(data);
      if (this.selectedcollpath != "") {
        srtxt = srtxt + "ֆ" + this.selectedcollpath;
      }
      var tbastr = localStorage.getItem("tabperstring");
      this.dashboarservice
        .getfulltxttreecount(srtxt)
        .subscribe((data2: any[]) => {
          if (data2 != null) {
            this.totalcount = data2[0].rowcount.toString();
          }
        });
      this.dashboarservice
        .getfulltxttree(srtxt, "", tbastr, "0~0~0~0~0")
        .subscribe((data: any[]) => {
          if (data != null) {
            if (type == "") {
              var data1 = JSON.parse(
                data.toString().split("~")[0].replace(/\ʁ/g, "~")
              );
              data1.sort((a, b) => b.Udate1.localeCompare(a.Udate1));
              this.elementarr = data1;
              this.folderdata = [];
              this.totalfcount = "";
              this.imagecount = data.toString().split("~")[1];
              this.doccount = data.toString().split("~")[2];
              this.videocount = data.toString().split("~")[3];
              this.audiocount = data.toString().split("~")[4];
              this.storycount = data.toString().split("~")[5];
              this.loading = false;
            } else {
              this.totalcount = data[0];
            }

            //this.totalcount=this.elementarr.length.toString()
          }
        });
    } else {
      if (environment.glbsearchstring != "") {
        //this.newadvancesearch(environment.glbsearchstring)
      } else {
        this.blindsearch("");
      }
    }

    this.loading = false;
  }
  settooltip(e) {
    $(function () {
      (<any>$('[data-toggle="tooltip"]')).tooltip();
    });
  }

  //counting the number of data is founded
  getcount() {
    var tbastr = localStorage.getItem("tabperstring");
    this.dashboarservice
      .getncount(this.srchtext, tbastr, this.selectedcollpath)
      .subscribe((data: any[]) => {
        if (data != null) {
          this.totalcount = data.toString();
        }
      });
  }

  //clear search bar through tab or backspace and hit enter button to again get all data
  clearfilters() {
    environment.glbsearchstring = "";
    this.srchtext = "";
    this.glbadvancefilter = "";
    environment.globalsearchtext = "";
    // this.blindsearch('')
    this.selectedcollpath = "";
    this.totalcount = localStorage.getItem("totalcount");
    this.searchin = "";
  }

  searchalldata(formvalues, fn) {
    //this.advnsrchfil=formvalues
    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    this.srchtext = "";
    //environment.globalsearchtext=""
    if (formvalues.itemkeyword != null) {
      formvalues.itemkeyword = formvalues.itemkeyword.replace(
        new RegExp(",", "g"),
        " "
      );
    }
    this.hideadvncsrch();
    if (formvalues.CategoryId == "null") {
      formvalues.CategoryId = null;
      formvalues.SubCategoryId = null;
    }
    if (fn != "") {
      this.dashboarservice
        .advancesearch(formvalues)
        .subscribe((data: any[]) => {
          if (data.length > 0) {
            data.sort((a, b) => b.Udate1.localeCompare(a.Udate1));
            this.elementarr = data;
            if (formvalues.totalcount != "") {
              if (formvalues.totalcount != undefined) {
                this.totalcount = formvalues.totalcount;
              } else {
                this.totalcount = this.elementarr.length.toString();
              }
            } else {
              this.totalcount = this.elementarr.length.toString();
            }
          } else {
            this.elementarr = [];
          }
        });
    } else {
      this.blindsearch("");
    }

    this.loading = false;
  }

  //search in specified folder, when we right click on folder and choose option of "search in"
  searchincoll() {
    //passing path of the folder on which user clicked
    this.selectedcollpath = this.tcmnodekey.node.data;
    // console.log(this.selectedcollpath);

    //alert(this.selectedcollpath)
    this.advanceSearch();
  }

  advanceSearch() {
    this.showfilter = true;
    var el = document.querySelector(".downbtnadvnc");
    el["style"].display = "none";
    var el1 = document.querySelector(".upbtnadvnc");
    el1["style"].display = "block";
  }

  hideadvncsrch() {
    this.showfilter = false;
    var el = document.querySelector(".downbtnadvnc");
    el["style"].display = "block";
    var el1 = document.querySelector(".upbtnadvnc");
    el1["style"].display = "none";
  }

  newadvancesearch(srctxt) {
    this.imagecount = "";
    this.doccount = "";
    this.videocount = "";
    this.audiocount = "";
    this.storycount = "";
    this.bditems = [];
    this.loading = true;
    this.glbadvancefilter = srctxt;
    //environment.glbsearchstring=srctxt
    this.hideadvncsrch();
    var tbastr = localStorage.getItem("tabperstring");
    this.dashboarservice
      .getncount(srctxt, tbastr, this.selectedcollpath)
      .subscribe((data2: any[]) => {
        if (data2 != null) {
          this.totalcount = data2.toString();
        }
      });
    this.dashboarservice
      .newadvancesearchtree(srctxt, tbastr, "0~0~0~0~0", this.selectedcollpath)
      .subscribe((data: any[]) => {
        if (data != null) {
          var data1 = JSON.parse(
            data.toString().split("~")[0].replace(/\ʁ/g, "~")
          );
          data1.sort((a, b) => b.Udate1.localeCompare(a.Udate1));
          this.elementarr = data1;
          this.folderdata = [];
          this.totalfcount = "";
          this.imagecount = data.toString().split("~")[1];
          this.doccount = data.toString().split("~")[2];
          this.videocount = data.toString().split("~")[3];
          this.audiocount = data.toString().split("~")[4];
          this.storycount = data.toString().split("~")[5];
          //this.totalcount=this.elementarr.length.toString()
        } else {
          this.elementarr = [];
        }
        this.loading = false;
      });
  }

  public setRow(_index, e) {
    _index = _index.trim();
    if (e.ctrlKey == true || e.metaKey == true) {
      if (this.selectedIndexs.indexOf(_index) === -1) {
        this.selectedIndexs.push(_index);
        this.selectedIndexstype.push(
          document
            .getElementById(_index)
            .children[0].innerHTML.toString()
            .split("~")[1]
            .trim()
        );
        this.selectedIndexsid.push(
          document
            .getElementById(_index)
            .children[0].innerHTML.toString()
            .split("~")[0]
            .trim()
        );
        var per = document.getElementById(_index).children[1].innerHTML.trim();
        this.selectedIndexspermis.push(Number(per.trim()));
        document.getElementById(_index).style.background = "gainsboro";
      } else {
        let index = this.selectedIndexs.indexOf(_index);
        this.selectedIndexs.splice(index, 1);
        this.selectedIndexstype.splice(index, 1);
        this.selectedIndexsid.splice(index, 1);
        this.selectedIndexspermis.splice(index, 1);
        document.getElementById(_index).style.background = "transparent";
      }
    }
    if (e.shiftKey == true) {
      if (this.selectedIndexs.length > 0) {
        var tmpid = this.selectedIndexs[0];
        this.removeselection();
        this.selectionwithshift(tmpid);
        if (this.checknext(tmpid, _index)) {
          var $that = this;
          $(document.getElementById(tmpid.toString()))
            .nextUntil(document.getElementById(_index.toString()))
            .each(function () {
              $that.selectionwithshift(this.id);
            });
          this.selectionwithshift(_index);
        } else if (this.checkprev(tmpid, _index)) {
          var $that = this;
          $(document.getElementById(tmpid.toString()))
            .prevUntil(document.getElementById(_index.toString()))
            .each(function () {
              $that.selectionwithshift(this.id);
            });
          this.selectionwithshift(_index);
        }
      } else {
        this.selectionwithshift(_index);
      }
    }
  }

  checknext(id1, id2) {
    var result = false;
    $(document.getElementById(id1.toString()))
      .nextAll()
      .each(function () {
        if (this.id == id2) {
          result = true;
          return false;
        }
      });
    return result;
  }
  checkprev(id1, id2) {
    var result = false;
    $(document.getElementById(id1.toString()))
      .prevAll()
      .each(function () {
        if (this.id == id2) {
          result = true;
          return false;
        }
      });
    return result;
  }

  selectionwithshift(_index) {
    if (this.selectedIndexs.indexOf(_index) === -1) {
      this.selectedIndexs.push(_index);
      var per = document.getElementById(_index).children[1].innerHTML.trim();
      this.selectedIndexspermis.push(Number(per));
      document.getElementById(_index).style.background = "gainsboro";
    } else {
      // let index = this.selectedIndexs.indexOf(_index);
      // this.selectedIndexs.splice(index, 1);
      // this.selectedIndexspermis.splice(index, 1)
      // document.getElementById(_index).style.background = "transparent"
    }
  }

  public setRow1(_index, e) {
    if (this.selectedIndexs.length < 2) {
      _index = _index.trim();
      this.removeselection();
      if (this.selectedIndexs.indexOf(_index) === -1) {
        this.selectedIndexs.push(_index);
        this.selectedIndexstype.push(
          document
            .getElementById(_index)
            .children[0].innerHTML.toString()
            .split("~")[1]
            .trim()
        );
        this.selectedIndexsid.push(
          document
            .getElementById(_index)
            .children[0].innerHTML.toString()
            .split("~")[0]
            .trim()
        );
        var per = document.getElementById(_index).children[1].innerHTML.trim();
        this.selectedIndexspermis.push(Number(per.trim()));
        document.getElementById(_index).style.background = "gainsboro";
      } else {
        let index = this.selectedIndexs.indexOf(_index);
        this.selectedIndexs.splice(index, 1);
        this.selectedIndexstype.splice(index, 1);
        this.selectedIndexsid.splice(index, 1);
        this.selectedIndexspermis.splice(index, 1);
        document.getElementById(_index).style.background = "transparent";
      }
    }
  }

  async deletefiles() {
    this.ismenuclick = true;
    this.confirmationService.confirm({
      message: "Are you sure that you want to Delete?",
      header: "Confirmation",
      key: "treeviewkey",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        var lst: any[] = this.selectedIndexs;
        var lstid = this.selectedIndexsid;
        var lsttype = this.selectedIndexstype;
        var rtnarr = [];
        if (lst.length > 0) {
          for (let i = 0; i < lst.length; i++) {
            //await this.downloadonebyone(lst[i])
            //await this.sleep(2000)
            rtnarr.push({
              filepath: lst[i],
              filename: document
                .getElementById(lst[i])
                .children[2].innerHTML.trim(),
              itemid: lstid[i],
              filetype: lsttype[i],
            });
            document.getElementById(lst[i]).remove();
            if (i == lst.length - 1) {
              await this.sleep(1000);
              this.documentservice
                .deletecollectionfiles(rtnarr)
                .subscribe((data: any[]) => {
                  let returnMsg: any = data;
                  if (returnMsg == "Success") {
                    this.messageservice.add({
                      key: "message",
                      severity: "success",
                      summary: "Success Message",
                      detail: "File Delete Successfully",
                    });
                  }
                });
            }
          }
        } else {
          this.messageservice.add({
            key: "message",
            severity: "warn",
            summary: "Warn Message",
            detail: "File Not Deleted",
          });
        }
        this.rightclickmenuopen = false;
        this.ismenuclick = false;
        this.selectedIndexs = [];
        this.selectedIndexsid = [];
        this.selectedIndexstype = [];
      },
      reject: () => {},
    });
  }

  async downloadmultiple() {
    this.ismenuclick = true;
    // var x = document.querySelector(".downloadinggif1")
    // x["style"].display = "block";
    // var y = document.querySelector(".downloadback")
    // y["style"].display = "block";
    var lst: any[] = this.selectedIndexs;
    // var lstid = this.selectedIndexsid
    var rtnarr = [];
    var imageid = "";
    if (lst.length > 1) {
      for (let i = 0; i < lst.length; i++) {
        imageid = lst[i].toString();
        var iid = document.getElementById(imageid);
        var fname = iid.children[2].innerHTML.trim();
        var filepath = iid.children[3].innerHTML.trim();
        //this.downloadFullImage(fname,filepath)
        filepath = filepath.replace("lowres/", "raw/");
        //await this.downloadonebyone(lst[i])
        //await this.sleep(2000)
        rtnarr.push({ filepath: filepath, filename: fname, itemid: imageid });
        if (i == lst.length - 1) {
          await this.sleep(1000);
          this.downloadmultiplezip(rtnarr);
        }
      }
    } else {
      this.downloadonebyone(this.selectedIndexs[0]);
    }
    this.rightclickmenuopen = false;
    this.ismenuclick = false;
  }

  downloadmultiplezip(arr) {
    this.documentservice.downloadmultiplefn(arr).subscribe((data: any) => {
      if (data.size > 0) {
        saveAs(data, `${data.size}.zip`);
      }
      // if (data.size > 0) {
      //   this.downloadzipflder(data)
      // }
    });
  }

  async downloadonebyone(itemid) {
    var fname = document.getElementById(itemid).children[2].innerHTML.trim();
    var filepath = document.getElementById(itemid).children[3].innerHTML.trim();
    this.messageservice.add({
      key: "message",
      severity: "info",
      summary: "Success Message",
      detail: "File Downloaded Will Start Soon",
    });

    let unsub = this.storyservice
      .Testdownload(fname, filepath.trim(), itemid)
      .subscribe(async (data: any) => {
        //await this.sleep(5000)
        if (data.size > 0) {
          saveAs(data, fname);
          this.messageservice.add({
            key: "message",
            severity: "info",
            summary: "Success Message",
            detail: "File Downloaded...",
          });
        } else {
          this.messageservice.add({
            key: "message",
            severity: "warn",
            summary: "Warning Message",
            detail: "Original File Does Not Exist.",
          });
        }
        unsub.unsubscribe();
        // var x = document.querySelector(".downloadinggif1")
        // x["style"].display = "none";
        // var y = document.querySelector(".downloadback")
        // y["style"].display = "none";
        this.removeselection();
        if (
          document
            .getElementById(itemid)
            .children[0].innerHTML.split("~")[1]
            .trim() == "image"
        ) {
          this.dashboarservice
            .createlog(
              itemid.trim(),
              "Download",
              JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
              "transaction_image"
            )
            .subscribe((data: any) => {});
        } else {
          this.dashboarservice
            .createlog(
              itemid.trim(),
              "Download",
              JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
              "transaction_document"
            )
            .subscribe((data: any) => {});
        }
        //this.downloadentry(itemid)
      });
  }
  sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  downloadzipflder(filename) {
    var cartname = filename;
    filename = filename + ".zip";
    var filepath = this.ApiServerPath + "downloadzip/" + filename;

    //this.imageservice.testzipdownload(filename).subscribe((data: any) => {
    var x = document.querySelector(".downloadinggif1");
    x["style"].display = "none";
    var y = document.querySelector(".downloadback");
    y["style"].display = "none";
    this.removeselection();
    // if (data != null) {
    //   var b64Data1 = data.split(";")[1]
    //   const byteCharacters = atob(b64Data1);

    //   const byteNumbers = new Array(byteCharacters.length);
    //   for (let i = 0; i < byteCharacters.length; i++) {
    //     byteNumbers[i] = byteCharacters.charCodeAt(i);
    //   }
    //   const byteArray = new Uint8Array(byteNumbers);
    //   const blob = new Blob([byteArray], { type: "application/x-zip-compressed" });
    //   //let byteCharacters = atob(data);

    //   var url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    a.href = filepath;
    a.download = "Assets";
    a.click();
    //window.URL.revokeObjectURL(url);
    a.remove(); // remove the element
    //}
    this.imageservice.deletezip(cartname).subscribe((data: any[]) => {});

    //})
  }

  removeselection() {
    if (this.selectedIndexs.length > 0) {
      for (let i = 0; i < this.selectedIndexs.length; i++) {
        document.getElementById(
          this.selectedIndexs[i].toString()
        ).style.background = "transparent";
        if (i == this.selectedIndexs.length - 1) {
          this.selectedIndexs.splice(0, this.selectedIndexs.length);
          this.selectedIndexstype.splice(0, this.selectedIndexstype.length);
          this.selectedIndexsid.splice(0, this.selectedIndexsid.length);
          this.selectedIndexspermis.splice(0, this.selectedIndexspermis.length);
        }
      }
      this.rightclickmenuopen = false;
      this.ismenuclick = false;
    }
  }

  switchview(type) {
    this.tablestyle = type;
  }

  pagenochange(e) {
    this.indexPrev = null;
    // setTimeout(() => {
    this.removeselection();
    if (Number(this.dataview1.totalRecords) >= Number(this.totalcount)) {
    } else {
      if (
        this.fulltextdata != "" &&
        this.fulltextdata != null &&
        this.fulltextdata != undefined
      ) {
        var x = document.querySelector(".ui-paginator-bottom");
        x["style"].cursor = "not-allowed";
        x["style"]["pointer-events"] = "none";
        this.loading = true;
        var tbastr = localStorage.getItem("tabperstring");
        var count =
          this.imagecount +
          "~" +
          this.doccount +
          "~" +
          this.videocount +
          "~" +
          this.audiocount +
          "~" +
          this.storycount;
        this.dashboarservice
          .getfulltxttree(this.fulltextdata, "", tbastr, count)
          .subscribe((data: any[]) => {
            if (data != null) {
              if (
                Number(this.dataview1.totalRecords) >= Number(this.totalcount)
              ) {
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              } else {
                var data1 = JSON.parse(
                  data.toString().split("~")[0].replace(/\ʁ/g, "~")
                );
                data1.sort((a, b) => b.Udate1.localeCompare(a.Udate1));
                this.dataview1.totalRecords =
                  this.dataview1.totalRecords + data1.length;
                var len = data1.length;
                for (let i = 0; i < len; i++) {
                  this.dataview1._value.push(data1[i]);
                }
                this.imagecount = (
                  Number(data.toString().split("~")[1]) +
                  Number(this.imagecount)
                ).toString();
                this.doccount = (
                  Number(data.toString().split("~")[2]) + Number(this.doccount)
                ).toString();
                this.videocount = (
                  Number(data.toString().split("~")[3]) +
                  Number(this.videocount)
                ).toString();
                this.audiocount = (
                  Number(data.toString().split("~")[4]) +
                  Number(this.audiocount)
                ).toString();
                this.storycount = (
                  Number(data.toString().split("~")[5]) +
                  Number(this.storycount)
                ).toString();
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              }
            } else {
              x["style"].cursor = "pointer";
              x["style"]["pointer-events"] = "auto";
              this.loading = false;
              //this.elementarr=[]
            }
          });
      } else {
        var x = document.querySelector(".ui-paginator-bottom");
        x["style"].cursor = "not-allowed";
        x["style"]["pointer-events"] = "none";
        this.loading = true;
        var tbastr = localStorage.getItem("tabperstring");
        var count =
          this.imagecount +
          "~" +
          this.doccount +
          "~" +
          this.videocount +
          "~" +
          this.audiocount +
          "~" +
          this.storycount;
        if (this.glbadvancefilter != "") {
          this.dashboarservice
            .newadvancesearchtree(
              this.glbadvancefilter,
              tbastr,
              count,
              this.selectedcollpath
            )
            .subscribe((data: any[]) => {
              if (data != null) {
                if (
                  Number(this.dataview1.totalRecords) >= Number(this.totalcount)
                ) {
                  x["style"].cursor = "pointer";
                  x["style"]["pointer-events"] = "auto";
                  this.loading = false;
                } else {
                  var data1 = JSON.parse(
                    data.toString().split("~")[0].replace(/\ʁ/g, "~")
                  );
                  data1.sort((a, b) => b.Udate1.localeCompare(a.Udate1));
                  this.dataview1.totalRecords =
                    this.dataview1.totalRecords + data1.length;
                  var len = data1.length;
                  for (let i = 0; i < len; i++) {
                    this.dataview1._value.push(data1[i]);
                  }
                  this.imagecount = (
                    Number(data.toString().split("~")[1]) +
                    Number(this.imagecount)
                  ).toString();
                  this.doccount = (
                    Number(data.toString().split("~")[2]) +
                    Number(this.doccount)
                  ).toString();
                  this.videocount = (
                    Number(data.toString().split("~")[3]) +
                    Number(this.videocount)
                  ).toString();
                  this.audiocount = (
                    Number(data.toString().split("~")[4]) +
                    Number(this.audiocount)
                  ).toString();
                  this.storycount = (
                    Number(data.toString().split("~")[5]) +
                    Number(this.storycount)
                  ).toString();
                  x["style"].cursor = "pointer";
                  x["style"]["pointer-events"] = "auto";
                  this.loading = false;
                }
              } else {
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
                //this.elementarr=[]
              }
            });
        } else if (this.srchtext.trim() != "") {
          this.srchtext = this.srchtext.trim();
          var srtxt = this.ExtractSearchingKeyword(this.srchtext);
          if (this.selectedcollpath != "") {
            srtxt = srtxt + "ֆ" + this.selectedcollpath;
          }
          this.dashboarservice
            .blindsearchwithdatatree(srtxt, "", tbastr, count, this.searchin)
            .subscribe((data: any[]) => {
              if (data != null) {
                if (
                  Number(this.dataview1.totalRecords) >= Number(this.totalcount)
                ) {
                  x["style"].cursor = "pointer";
                  x["style"]["pointer-events"] = "auto";
                  this.loading = false;
                } else {
                  var data1 = JSON.parse(
                    data.toString().split("~")[0].replace(/\ʁ/g, "~")
                  );
                  data1.sort((a, b) => b.Udate1.localeCompare(a.Udate1));
                  this.dataview1.totalRecords =
                    this.dataview1.totalRecords + data1.length;
                  var len = data1.length;
                  for (let i = 0; i < len; i++) {
                    this.dataview1._value.push(data1[i]);
                  }
                  this.imagecount = (
                    Number(data.toString().split("~")[1]) +
                    Number(this.imagecount)
                  ).toString();
                  this.doccount = (
                    Number(data.toString().split("~")[2]) +
                    Number(this.doccount)
                  ).toString();
                  this.videocount = (
                    Number(data.toString().split("~")[3]) +
                    Number(this.videocount)
                  ).toString();
                  this.audiocount = (
                    Number(data.toString().split("~")[4]) +
                    Number(this.audiocount)
                  ).toString();
                  this.storycount = (
                    Number(data.toString().split("~")[5]) +
                    Number(this.storycount)
                  ).toString();
                  x["style"].cursor = "pointer";
                  x["style"]["pointer-events"] = "auto";
                  this.loading = false;
                }
              } else {
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              }
            });
        } else {
        }
      }
    }

    this.removeselection();
    // }, Math.random() * 1000 + 250);
  }

  @HostListener("document:keydown", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    const ESCAPE_KEYCODE = 17;
    if (event.keyCode == 27) {
      this.DocumentUpdated = false;
    }
    if (event.keyCode == 27) {
      this.viewDocument = false;
    }
    if (event.keyCode == 27) {
      this.ImageUpdated = false;
    }
    if (event.keyCode == 27) {
      this.view = false;
    }
    if (event.keyCode == 27) {
      this.videoupdate = false;
    }
    if (event.keyCode == 27) {
      this.viewVideo = false;
    }
    if (event.keyCode == 27) {
      this.viewVideo = false;
    }
    if (event.keyCode == 27) {
      this.viewAudio = false;
    }
    if (event.keyCode == 27) {
      this.hideadvncsrch();
    }
    if (event.ctrlKey == true || event.metaKey == true) {
      if (event.keyCode === 65) {
        this.removeselection();
        var x: any;
        if (this.tablestyle == "grid") {
          x = document.getElementsByClassName("ui-dataview-content");
          for (let i = 0; i < x[0].children[0].children.length; i++) {
            this.setRow(x[0].children[0].children[i].id, event);
          }
        } else if (this.tablestyle == "list") {
          x = document.getElementsByClassName("ui-table-scrollable-body-table");
          for (let i = 0; i < x[0].children[0].children.length; i++) {
            this.setRow(x[0].children[0].children[i].id, event);
          }
        } else {
          x = document.getElementsByClassName("ui-table-tbody");
          for (let i = 0; i < x[0].children.length; i++) {
            this.setRow(x[0].children[i].id, event);
          }
        }
        //  x=document.getElementsByClassName("ui-dataview-content")
        // for(let i=0;i<x[0].children[0].children.length;i++){
        //   this.setRow(x[0].children[0].children[i].id,event)
        // }
      }
      if (event.keyCode === 81) {
        this.removeselection();
      }
    }
  }

  @HostListener("click", ["$event"]) onClick(event: KeyboardEvent) {
    //console.log("true");
    this.tcm.hide();
    if (
      event.ctrlKey == false &&
      event.metaKey == false &&
      event.shiftKey == false
    ) {
      if (
        this.rightclickmenuopen == false ||
        (this.conetxtmenudiv.el.nativeElement.innerHTML.indexOf(
          "display: block"
        ) > 0 &&
          this.rightclickmenuopen == true &&
          this.ismenuclick == false)
      ) {
        // if(event == null){
        //   this.removeselection()
        // }
        try {
          this.conetxtmenudiv.hide();
        } catch {}
      }
    }
  }

  @HostListener("dblclick", ["$event"]) dblClick(event2: KeyboardEvent) {
    // let _index = event2['path'][2]['id']
    // if (_index.length === 0) {
    // console.log("if removeselection");
    this.removeselection();
    // }
  }

  selectOneIndex(index, event) {
    if (
      event.ctrlKey == false &&
      event.metaKey == false &&
      event.shiftKey == false
    ) {
      this.removeselection();
      let _index = index;
      if (_index.length != 0) {
        this.indexCurrent = _index;
        document.getElementById(this.indexCurrent).style.background =
          "gainsboro";
        if (this.indexPrev != null) {
          document.getElementById(this.indexPrev).style.background =
            "transparent";
          this.indexPrev = this.indexCurrent;
          this.selectedIndexs[0] = _index;
        } else {
          this.indexPrev = this.indexCurrent;
          this.selectedIndexs.push(_index);
        }
      }
    }
  }

  showfulldiv() {
    const x = document.querySelector(".ui-dialog-content");
    x.classList.remove("hidemanual");
    this.showmin = 1;
    this.showmax = 0;
  }

  hidefulldiv() {
    const x = document.querySelector(".ui-dialog-content");
    x.classList.add("hidemanual");
    this.showmin = 0;
    this.showmax = 1;
  }

  showcartdiv(id, type, e) {
    if (e.ctrlKey == false && e.metaKey == false) {
      this.cartitemid1 = id;
      this.cartitemtype1 = type;
      this.showcart = true;
    }
  }

  //add item of folder to the cart
  addtocart() {
    if (
      this.addtocartaudio ||
      this.addtocartdoc ||
      this.addtocartimage ||
      this.addtocartstory ||
      this.addtocartvideo == 1
    ) {
      var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
      if (this.cartname1 == undefined) {
        this.messageservice.add({
          key: "message",
          severity: "warn",
          summary: "Warning Message",
          detail: "Something went wrong try again..",
        });
        this.clearcartname();
        return;
      }
      if (this.cartname1 != null) {
        if (this.cartitemid1 != null) {
          const lst = [];
          const lst1 = [];
          lst.push(this.cartitemid1);
          lst1.push(this.cartitemtype1);
          // console.log(this.cartname1, lst, lst1,);

          this.imageservice
            .addtocartmul(this.cartname1, lst, lst1, userid, "archieve")
            .subscribe((data: any) => {
              if (data != null) {
                this.messageservice.add({
                  key: "message",
                  severity: "success",
                  summary: "Success Message",
                  detail: "Add to Cart successfully 1",
                });
                this.clearcartname();
                this.search();
              }
            });
        } else {
          this.addtocartmultiple();
          this.search();
        }
      }
    }
  }

  async addtocartmultiple() {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    var lst = this.selectedIndexsid;
    var lst1 = this.selectedIndexstype;

    if (this.selectedIndexsid.length > 0) {
      this.imageservice
        .addtocartmul(this.cartname1, lst, lst1, userid, "archieve")
        .subscribe((data: any) => {
          let returnMsg = data;

          if (returnMsg == "Success") {
            this.messageservice.add({
              key: "message",
              severity: "success",
              summary: "Success Message",
              detail: "Add to Cart successfully",
            });
            this.clearcartname();
          }
        });
    } else {
      this.messageservice.add({
        key: "message",
        severity: "warn",
        summary: "warn Message",
        detail: "Add to Cart Failed",
      });
    }
  }

  search() {
    this.results = [];
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.imageservice.getResults(userid).subscribe((data: any[]) => {
      if (data != null) {
        for (let i = 0; i < data.length; i++) {
          this.results.push(data[i].cartname);
        }
      }
    });
  }

  filterBrands(event) {
    this.filteredBrands = [];
    for (let i = 0; i < this.results.length; i++) {
      let brand = this.results[i];
      if (brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        this.filteredBrands.push(brand);
      }
    }
  }

  clearcartname() {
    this.cartname1 = null;
    this.cartitemid1 = null;
    this.showcart = false;
    this.removeselection();
    this.rightclickmenuopen = false;
    this.ismenuclick = false;
  }

  showmulcartdiv() {
    this.showcart = true;
    this.ismenuclick = true;
  }

 

  //document popup//

  downloadDocument(filename, filepath, itemid) {
    this.storyservice
      .Testdownload(filename, filepath, itemid)
      .subscribe((data: any) => {
        if (data.size > 0) {
          saveAs(data, filename);
          // var b64Data1 = data.split(";")[1]
          // const byteCharacters = atob(b64Data1);

          // const byteNumbers = new Array(byteCharacters.length);
          // for (let i = 0; i < byteCharacters.length; i++) {
          //   byteNumbers[i] = byteCharacters.charCodeAt(i);
          // }
          // const byteArray = new Uint8Array(byteNumbers);
          // const blob = new Blob([byteArray], { type: "audio/*" });
          // //let byteCharacters = atob(data);

          // var url = window.URL.createObjectURL(blob);
          // var a = document.createElement('a');
          // document.body.appendChild(a);
          // a.setAttribute('style', 'display: none');
          // a.href = url;
          // a.download = filename;
          // a.click();
          // window.URL.revokeObjectURL(url);
          // a.remove();
        }
        this.downloadentry(itemid);
      });
  }
  downloadentry(itemid) {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.documentservice
      .downloadentry(itemid, userid)
      .subscribe((data: any[]) => {});
  }

  close() {
    this.viewDocument = false;
    this.showfulldiv();
  }

  showNoOfDownload(itemid) {
    // this.getdowloadimagelist=downloadList
    this.documentservice
      .GetDownloadedDocumentData(itemid)
      .subscribe((data: any[]) => {
        if (data != null) {
          this.downloadDocumentList = data;
          this.showDocumentDownload = true;
        }
      });
  }

  Textread(filename, filepath) {
    this.documentservice
      .getTextFileContend(filename, filepath)
      .subscribe((data: any) => {
        if (data != null) {
          this.text = data.replace(new RegExp("\n", "g"), "<br/>");
          this.text = this.text.replace(new RegExp("\r\n", "g"), "<br/>");
        }
      });
  }

  getpdfimg(itemid) {
    this.documentservice.getpdfimg(itemid).subscribe((data: any) => {
      if (data != null) {
        this.pdfimages = data;
      }
    });
  }

  showzoom(itemid, fname) {
    window.open(this.ApiServerPath + fname, "_blank", "popupwindow");
    // window.open("#/pdfpreview;id="+itemid+";fname="+fname,"_blank")
  }

  getdocumentdata(itemid, e, path) {
    if (e.ctrlKey == false && e.metaKey == false && e.shiftKey == false) {
      if (path.toLowerCase().indexOf("home/itg_events") > -1) {
        path = "event";
      } else {
        path = "";
      }
      this.dashboarservice
        .getdocumentdata(itemid, path)
        .subscribe((data: any[]) => {
          if (data.length > 0) {
            this.fullDocumentpopup(data[0]);
          }
        });
    }
  }

  fullDocumentpopup(documentList) {
    this.fullDocumentpathlist = documentList;
    this.ratingitemdoc = documentList.itemrating;
    setTimeout(() => {
      try {
        this.ytPlayer.nativeElement.src =
          this.ApiServerPath + documentList.OriginalPath;
      } catch (err) {}
    }, 300);

    //this.pdfpath = this.ApiServerPath + documentList.OriginalPath

    // if(this.fullDocumentpathlist.itemtype.toLowerCase() =="pdf"){
    // this.getpdfimg(this.fullDocumentpathlist.itemid)
    // }
    if (this.fullDocumentpathlist.itemtype.toLowerCase() == "txt") {
      this.Textread(documentList.itemfilename, documentList.OriginalPath);
    }
    this.viewDocument = true;
  }

  updatedocumentpopup(itemid, name, e, path) {
    if (e.ctrlKey == false && e.metaKey == false) {
      if (path.toLowerCase().indexOf("home/itg_events") > -1) {
        path = "event";
      } else {
        path = "normal";
      }
      this.DocumentUpdated = true;
      this.docidforedit = itemid;
      this.docfileforup = name;
      this.orgname = name;
      this.edittablefordoc = path;
    }
  }

  hidedocumentedit() {
    this.docidforedit = "";
    this.orgname = "";
    this.DocumentUpdated = false;
    this.treedropvisible = false;
    this.showfulldiv();
  }

  //document popup//
  //audio popup//

  //show number of download of the document
  audioshowNoOfDownload(itemid) {
    // this.getdowloadimagelist=downloadList
    this.audiouploadservice
      .GetDownloadedAudioData(itemid)
      .subscribe((data: any[]) => {
        if (data != null) {
          this.downloadAudioList = data;
          this.showAudioDownload = true;
        }
      });
  }

  //download audio
  downloadAudio(filename, filepath, itemid) {
    this.audiouploadservice.Testdownload(filepath).subscribe((data: any) => {
      if (data != null) {
        var b64Data1 = data.split(";")[1];
        const byteCharacters = atob(b64Data1);

        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "audio/*" });
        //let byteCharacters = atob(data);

        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }
      this.audiodownloadentry(itemid);
    });
  }

  //make entry of audio download
  audiodownloadentry(itemid) {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.audiouploadservice
      .downloadentry(itemid, userid)
      .subscribe((data: any[]) => {});
  }

  //get audio data
  getaudiodata(itemid, e, path) {
    if (e.ctrlKey == false && e.metaKey == false && e.shiftKey == false) {
      if (path.toLowerCase().indexOf("home/itg_events") > -1) {
        path = "event";
      } else {
        path = "";
      }
      this.dashboarservice
        .getaudiodata(itemid, path)
        .subscribe((data: any[]) => {
          if (data != null) {
            this.fullAudiopopup(data[0]);
          }
        });
    }
  }

  //audio metadata form popup
  fullAudiopopup(audioList) {
    this.Player.nativeElement.src = this.ApiServerPath + audioList.HiresPath;
    this.audioimage = "assets/image/Wave_bg.jpg";
    // this.fullAudiolist=null
    this.ratingitemaudio = audioList.itemrating;
    this.Audiopreviewpath = this.ApiServerPath + audioList.HiresPath;
    this.fullAudiolist = audioList;
    this.viewAudio = true;
  }

  closeaudio() {
    this.viewAudio = false;
    this.Player.nativeElement.src = "";
  }

  playfunction() {
    this.audioimage = "assets/image/waveform_easset.gif";
  }
  pausefunction() {
    this.audioimage = "assets/image/Wave_bg.jpg";
  }
  //audio popup//
  //image popup//

  hideimageedit() {
    this.idforimageedit = "";
    this.orgname = "";
    this.ImageUpdated = false;
    this.showfulldiv();
  }
  Cancel() {
    this.Player.nativeElement.src = "";
  }

  cancelcrp() {
    this.editdiv = 0;
    this.metadatadiv = 1;
    this.showfulldiv();
    this.view = false;
    if (this.cropper != null) {
      this.cropper.destroy();
      this.imageDestination = "";
      this.ButtonStatusimg = "Download";
      //   document.querySelector(".toolbox").removeAttribute("style")
      // document.querySelector(".toolbox").setAttribute("style","display:none")
      this.brightness = 0;
      this.contrast = 0;
      this.saturate = 0;
      this.grin = "";
      this.watermark = "India Today";
      this.watermarkposition = "topright";
      document.querySelector(".cropbtn").removeAttribute("disabled");
    }
  }

  Reset() {
    this.cropper.clear();
    this.editdiv = 0;
    this.metadatadiv = 1;
    if (this.cropper != null) {
      this.cropper.destroy();
      this.imageDestination = "";
      this.ButtonStatusimg = "Download";
      // document.querySelector(".toolbox").removeAttribute("style")
      // document.querySelector(".toolbox").setAttribute("style","display:none")
      this.brightness = 0;
      this.contrast = 0;
      this.saturate = 0;
      this.grin = "";
      this.watermark = "India Today";
      this.watermarkposition = "topright";
      document.querySelector(".cropbtn").removeAttribute("disabled");
    }
  }

  //download cropped image
  CropedImageDownload(filename, filepath, itemid) {
    if (this.imageDestination != "") {
      // this.ButtonStatus='Cropped Download '
      //var downloadpath = this.imageDestination
      this.ctx = this.cropimagecanvas.nativeElement.getContext("2d");
      this.ctx.clearRect(
        0,
        0,
        this.cropimagecanvas.nativeElement.width,
        this.cropimagecanvas.nativeElement.height
      );
      var image = new Image();
      image.src = this.imageDestination;
      this.cropimagecanvas.nativeElement.height = image.naturalHeight;
      this.cropimagecanvas.nativeElement.width = image.naturalWidth;
      if (this.contrast == 0) {
        this.ctx.filter = "contrast(none)";
      } else {
        this.ctx.filter = "contrast(" + this.contrast / 10 + ")";
      }
      if (this.brightness == 0) {
        this.ctx.filter = "brightness(none)";
      } else {
        this.ctx.filter = "brightness(" + this.brightness / 10 + ")";
      }
      if (this.saturate == 0) {
        this.ctx.filter = "saturate(none)";
      } else {
        this.ctx.filter = "saturate(" + this.saturate / 10 + ")";
      }
      if (this.grin == "") {
        this.ctx.filter = this.grin + "(none)";
      } else {
        this.ctx.filter = this.grin + "(1)";
      }
      //this.ctx.filter="brightness(25.5)";

      this.ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight);
      if (this.watermark != "") {
        this.ctx.globalAlpha = 0.5;
        if (this.watermarkposition == "topright") {
          var fntsize = image.naturalWidth / 20;
          this.ctx.font = fntsize + "px Comic Sans MS";
          this.ctx.fillStyle = "red";
          this.ctx.textAlign = "end";
          this.ctx.fillText(
            this.watermark,
            image.naturalWidth - 10,
            image.naturalHeight * 0.06,
            image.naturalWidth
          );
        } else {
          var fntsize = image.naturalWidth / 5;
          this.ctx.font = fntsize + "px Comic Sans MS";
          this.ctx.fillStyle = "red";
          this.ctx.translate(0, image.naturalWidth * 0.12);
          this.ctx.rotate(
            Math.atan(
              image.naturalHeight /
                (image.naturalWidth + image.naturalWidth * 0.05)
            )
          );
          this.ctx.fillText(this.watermark, 0, 0, image.naturalWidth);
        }
      }
      var downloadpath =
        this.cropimagecanvas.nativeElement.toDataURL("image/jpeg");
      this.LoadImageCrop(downloadpath, this.SaveCropImage, filename, itemid);
      this.Reset();
      this.downloadentryimg(itemid);
    } else {
      this.downloadFullImage(filename, filepath, itemid);
      this.downloadentryimg(itemid);
    }
  }
  private SaveCropImage(content: Blob, filename, itemid): void {
    saveAs(content, filename);
  }
  private LoadImageCrop(
    url: string,
    callback: Function,
    filename,
    itemid
  ): void {
    saveAs(url, filename);
  }

  //make entry of download
  downloadentryimg(itemid) {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.imageservice
      .downloadentry(itemid, userid)
      .subscribe((data: any[]) => {});
  }
  changecolor(id1) {
    var filter1 = "";
    var style = document
      .querySelector(".cropper-container")
      .getAttribute("style");
    var x = style.split(";");
    //var y=x[2]
    x[2] = "";
    var bvalue = this.brightness / 10;
    var cvalue = this.contrast / 10;
    var svalue = this.saturate / 10;
    if (bvalue > 0) {
      filter1 += "brightness(" + bvalue + ") ";
    }
    if (cvalue > 0) {
      filter1 += "contrast(" + cvalue + ") ";
    }
    if (svalue > 0) {
      filter1 += "saturate(" + svalue + ")";
    }
    if (this.grin != "") {
      filter1 += this.grin + "(1)";
    }
    x[2] = "filter:" + filter1;
    //filter=id1+"("+bvalue+");"
    if (filter1 != "") {
      style = x[0] + ";" + x[1] + ";" + x[2] + ";";
    } else {
      style = x[0] + ";" + x[1] + ";";
    }

    document.querySelector(".cropper-container").removeAttribute("style");
    document
      .querySelector(".cropper-container")
      .setAttribute("style", "" + style + "");
  }
  cropimages() {
    this.ButtonStatusimg = "Cropped Download ";
    document.querySelector(".cropbtn").setAttribute("disabled", "true");
    // document.querySelector(".toolbox").removeAttribute("style")
    // document.querySelector(".toolbox").setAttribute("style","display:block")
    this.metadatadiv = 0;
    this.editdiv = 1;
    this.cropper = new Cropper(this.imageElement.nativeElement, {
      zoomable: false,
      scalable: false,
      aspectRatio: 0,
      autoCropArea: 1,
      checkCrossOrigin: false,
      movable: false,
      viewMode: 2,
      crop: () => {
        const canvas = this.cropper.getCroppedCanvas();
        this.imageDestination = canvas.toDataURL("image/jpeg");
      },
    });
  }

  //donload full image
  downloadFullImage(filename, filepath, itemid) {
    this.storyservice
      .Testdownload(filename, filepath, itemid)
      .subscribe((data: any) => {
        if (data.size > 0) {
          saveAs(data, filename);
          // var b64Data1 = data.split(";")[1]
          // const byteCharacters = atob(b64Data1);

          // const byteNumbers = new Array(byteCharacters.length);
          // for (let i = 0; i < byteCharacters.length; i++) {
          //   byteNumbers[i] = byteCharacters.charCodeAt(i);
          // }
          // const byteArray = new Uint8Array(byteNumbers);
          // const blob = new Blob([byteArray], { type: "image/jpeg" });
          // //let byteCharacters = atob(data);

          // var url = window.URL.createObjectURL(blob);
          // var a = document.createElement('a');
          // document.body.appendChild(a);
          // a.setAttribute('style', 'display: none');
          // a.href = url;
          // a.download = filename;
          // a.click();
          // window.URL.revokeObjectURL(url);
          // a.remove(); // remove the element
          // const file = new File([blob], filename);
        }
      });
  }

  //number of download
  showNoOfDownloadimg(itemid) {
    // this.getdowloadimagelist=downloadList
    this.imageservice
      .GetDownloadedImageData(itemid)
      .subscribe((data: any[]) => {
        if (data != null) {
          this.downloadListimg = data;
          this.showDownloadimg = true;
        }
      });
  }

  //image metadata
  getimagedata(itemid, e, path) {
    // console.log("path", path);
    if (e.ctrlKey == false && e.metaKey == false && e.shiftKey == false) {
      if (path.toLowerCase().includes("itg_events")) {
        path = "event";
      } else {
        path = "";
      }

      this.dashboarservice
        .getimagedata(itemid, path)
        .subscribe((data: any[]) => {
          if (data.length > 0) {
            this.fullimagepopup(data[0]);
          }
        });
    }
  }

  //image metadata form popup
  fullimagepopup(imageList) {
    this.downloaditems = [
      {
        label: "Low",
        command: () => {
          this.CropedImageDownload(
            imageList.itemfilename,
            imageList.thumbpath,
            imageList.itemid
          );
        },
      },
      {
        label: "Medium",
        command: () => {
          this.CropedImageDownload(
            imageList.itemfilename,
            imageList.HiresPath,
            imageList.itemid
          );
        },
      },
      {
        label: "High",
        command: () => {
          this.CropedImageDownload(
            imageList.itemfilename,
            imageList.OriginalPath,
            imageList.itemid
          );
        },
      },
    ];
    this.fullpathlist = imageList;
    this.ratingitemimg = imageList.itemrating;
    this.imageservice
      .Testdownload(imageList.orgfilename, imageList.OriginalPath)
      .subscribe((data: any) => {
        if (data != "error") {
          // var b64Data1 = data.split(";")[1]
          // this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
          //   + b64Data1);
          this.imagePath = this.ApiServerPath + imageList.HiresPath;
          // console.log(this.imagePath);
        } else {
          this.imagePath = "assets/image/noimagehires.png";
        }
        this.view = true;
      });
  }

  showeditdiv(path, itemid) {
    this.dashboarservice
      .createlog(
        itemid,
        "Edit_Download",
        JSON.parse(localStorage.getItem("userdetails"))[0]["UserId"],
        "transaction_image"
      )
      .subscribe((data: any) => {});
    this.showeditor = true;
    this.ytPlayer1.nativeElement.src = environment.editorpath + path;
    //this.iframepath = environment.editorpath + path
  }

  hideeditor() {
    this.showeditor = false;
    this.iframepath = "";
  }

  updateimagepopup(itemid, name, e, path) {
    if (e.ctrlKey == false && e.metaKey == false) {
      if (path.toLowerCase().indexOf("home/itg_events") > -1) {
        path = "event";
      } else {
        path = "normal";
      }
      this.ImageUpdated = true;
      this.idforimageedit = itemid;
      this.imagenameforedit = name;
      this.edittable = path;
      this.orgname = name;
    }
  }
  //image popup//

  //video popup//

  //download video
  exportvideoforlocal(path, id, filename) {
    this.dashboarservice
      .exportvideoforlocal(path, id, filename)
      .subscribe((data: any[]) => {
        if (data != null) {
          this.messageservice.add({
            key: "message",
            severity: "info",
            summary: "Success Message",
            detail: "Successfully Export",
          });
        }
      });
  }

  //video  metadata
  getvideodata(itemid, e, path) {
    if (e.ctrlKey == false && e.metaKey == false && e.shiftKey == false) {
      if (path.toLowerCase().indexOf("home/itg_events") > -1) {
        path = "event";
      } else {
        path = "";
      }
      this.dashboarservice
        .getvideodata(itemid, path)
        .subscribe((data: any[]) => {
          if (data != null) {
            this.fullvideopopup(data[0]);
          }
        });
    }
  }
  fullvideopopup(videolist) {
    this.videoElement.nativeElement.src =
      this.ApiServerPath + videolist.HiresPath;
    this.ratingitemvideo = videolist.itemrating;
    this.getgroupimage(videolist.groupid);
    //  this.videopreviewpath=videolist.HiresPath
    this.fullvideolist = videolist;
    this.viewVideo = true;
    this.Buttons = "Play";
  }

  popupClose() {
    this.viewVideo = false;
    document.getElementById("play").style.backgroundImage =
      "url('/assets/image/play.png')";
  }
  cropvideo(path, orgname, itemid) {
    var person = prompt("Please enter filename", "");
    if (person == null || person == "") {
    } else {
      var ext = orgname.split(".")[orgname.split(".").length - 1];
      var userid = JSON.parse(localStorage.getItem("userdetails"))[0].LoginId;

      this.videogif.nativeElement.style.display = "block";
      this.videoElement.nativeElement.pause();
      // this.videogif.nativeElement.src='assets/image/2.gif'
      orgname = person.split(".")[0] + "." + ext;
      this.videoservice
        .ffmpeg(path, orgname, this.durationarray, userid)
        .subscribe((data: any[]) => {
          if (data.length > 0) {
            this.messageservice.add({
              key: "message",
              severity: "info",
              summary: "Success Message",
              detail: "Successfully Export",
            });
            //this.downloadVideo(orgname,path,userid)
            this.videogif.nativeElement.style.display = "none";
            this.close();
          }
        });
    }
  }

  downloadVideo(filename, filepath, userid, itemid) {
    this.videoservice
      .Testdownload(itemid, filename, filepath)
      .subscribe((data: any) => {
        if (data != null) {
          var b64Data1 = data.split(";")[1];
          const byteCharacters = atob(b64Data1);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "video/*" });
          //let byteCharacters = atob(data);

          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
          this.videoservice.deleteTempFile(userid).subscribe((data: any) => {
            if (data != null) {
            }
          });
        }
        this.dashboarservice
          .createlog(itemid, "Download", userid, "transaction_video")
          .subscribe((data: any) => {});
      });
  }

  markin() {
    if (this.ButtonStatus == "MarkIn") {
      this.ButtonStatus = "MarkOut";
      document.getElementById("markslider").style.display = "block";
      document.getElementById("markslider").innerHTML +=
        "<div id='markinvl" +
        this.markinvl +
        "' class='markin' style='height:40px; width:13px; border-right:4px solid #000;margin:-40px 0px 0px 0px;float:left'></div>";
      var timebar = $(".timeBar").css("width");
      var markinwidth = $("#markinvl" + this.markinvl).css("width");
      var markoutwidth = $("#markoutval" + this.markinvl).css("width");
      var markSetwidth = Number(13) + Number(timebar.replace("px", ""));
      $("#markinvl" + this.markinvl).css("width", markSetwidth);
      this.tooltip("markinvl" + this.markinvl);
      this.getProgressCurrentTime(markSetwidth) + ",";
      this.markinvl = this.markinvl + 1;
    } else {
      this.ButtonStatus = "MarkIn";
      document.getElementById("markslider").style.display = "block";
      document.getElementById("markslider").innerHTML +=
        "<div id='markoutval" +
        this.markoutval +
        "' class='markout' style='height:40px; width:13px; border-right:4px solid #000;background: rgba(102, 153, 200,0.7);margin:-40px 0px 0px 0px;float:left'></div>";
      var timebar = $(".timeBar").css("width");
      var markinwidth = $("#markinvl" + this.markoutval).css("width");
      var markSetwidth =
        Number(15) +
        Number(timebar.replace("px", "")) -
        Number(markinwidth.replace("px", ""));
      $("#markoutval" + this.markoutval).css("width", markSetwidth);
      $("#markoutval" + this.markoutval).css("margin-left", markinwidth);
      this.tooltip("markoutval" + this.markoutval);
      this.markoutval = this.markoutval + 1;
      this.getProgressCurrentTime(markSetwidth) + ",";
    }
  }

  tooltip(id) {
    // var MarkInValue;
    //  var MarkOutValue;
    var videoCurrentTime12 = this.videoElement.nativeElement.currentTime;
    this.minutes = "0" + Math.floor(videoCurrentTime12 / 60);
    var seconds = "0" + Math.floor(videoCurrentTime12 - this.minutes * 60);
    var dur = this.minutes.substr(-2) + ":" + seconds.substr(-2);
    document.getElementById(id).innerHTML +=
      "<span style='float:right;font-size:13px;'>" + dur + "</span>";
    $(id).attr("title", dur);
    if (id == "markinvl" + this.markinvl) {
      this.MarkInValue = videoCurrentTime12;
    }
    if (id == "markoutval" + this.markoutval) {
      this.MarkOutValue = videoCurrentTime12;
      this.totalDuration = this.MarkOutValue - this.MarkInValue;
      this.markinArray.push(this.MarkInValue);
      this.totaldurarray.push(this.totalDuration);
      this.durationarray = this.markinArray.concat(this.totaldurarray);
    }
    //  this.totalDuration=this.MarkOutValue-this.MarkInValue
    //  this.markinArray.push(this.MarkInValue)
    //  this.totaldurarray.push(this.totalDuration)
    //  this.durationarray.concat(this.markinArray,this.totaldurarray)
  }

  getProgressCurrentTime(x) {
    var video = $("#myVideo");
    var progress = $(".progress")[0];

    //calculate drag position
    //and update video currenttime
    //as well as progress bar
    var maxduration = this.videoElement.nativeElement.duration;
    var position = x - progress.offsetLeft;
    var percentage = (100 * position) / progress.clientWidth;
    if (percentage > 100) {
      percentage = 100;
    }
    if (percentage < 0) {
      percentage = 0;
    }
    var Ctime = (maxduration * percentage) / 100;
    return Ctime;
  }

  play() {
    var videoCurrentTime1 = this.videoElement.nativeElement.currentTime;
    var totalduration1 = this.videoElement.nativeElement.duration;
    var video = $("#myMovie");
    video.bind("timeupdate", function () {
      var videoCurrentTime = videoCurrentTime1;
      var totalduration = totalduration1;
      var minutes: any;
      minutes = "0" + Math.floor(videoCurrentTime / 60);
      var seconds = "0" + Math.floor(videoCurrentTime - minutes * 60);
      var dur = minutes.substr(-2) + ":" + seconds.substr(-2);
      var minutes_d: any;
      minutes_d = "0" + Math.floor(totalduration / 60);
      var seconds_d = "0" + Math.floor(totalduration1 - minutes_d * 60);
      var dur_d = minutes_d.substr(-2) + ":" + seconds_d.substr(-2);
      $("#currentduration").html(dur);
      $("#totalduration").html(dur_d);
    });

    var CurrentPosition = this.videoElement.nativeElement.currentTime;
    var dur = this.videoElement.nativeElement.duration;
    video.on("timeupdate", function () {
      var currentPos = CurrentPosition;
      var maxduration: any;
      maxduration = dur;
      var perc = (100 * currentPos) / maxduration;
      $(".timeBar").css("width", perc + "%");
      $(".current").text(timeFormat(currentPos));
    });
    var timeFormat = function (seconds) {
      var m: any;
      m =
        Math.floor(seconds / 60) < 10
          ? "0" + Math.floor(seconds / 60)
          : Math.floor(seconds / 60);
      var s =
        Math.floor(seconds - m * 60) < 10
          ? "0" + Math.floor(seconds - m * 60)
          : Math.floor(seconds - m * 60);
      return m + ":" + s;
    };
    var perc = (100 * CurrentPosition) / totalduration1;
    $(".timeBar").css("width", perc + "%");

    if (perc == 100) {
      document.getElementById("play").style.backgroundImage =
        "url('/assets/image/play.png')";
    }
  }

  timer() {
    if (this.playStatus == "Pause") {
      document.getElementById("play").style.backgroundImage =
        "url('/assets/image/pause.png')";
      this.playStatus = "Play";
      this.videoElement.nativeElement.play();
    } else {
      document.getElementById("play").style.backgroundImage =
        "url('/assets/image/play.png')";
      this.playStatus = "Pause";
      this.videoElement.nativeElement.pause();
    }
    setInterval(() => {
      this.play();
    }, 1000);
  }

  fullscreen() {
    var videofullscreen = document.getElementById("myMovie");
    videofullscreen.requestFullscreen();
  }
  MuteUnmute() {
    //  var videofullscreen=document.getElementById('myMovie')
    if (this.volumeStatus == "unmute") {
      this.videoElement.nativeElement.muted = false;
      document.getElementById("volume").style.backgroundImage =
        "url('/assets/image/volume.png')";
      this.volumeStatus = "mute";
    } else {
      this.videoElement.nativeElement.muted = true;
      document.getElementById("volume").style.backgroundImage =
        "url('/assets/image/mute.png')";
      this.volumeStatus = "unmute";
    }
  }

  updateprogressbar(e) {
    var progress = $(".progress");
    //calculate drag position
    //and update video currenttime
    //as well as progress bar
    var maxduration = this.videoElement.nativeElement.duration;
    var position = e.pageX - progress.offset().left;
    var percentage = (100 * position) / progress.width();
    if (percentage > 100) {
      percentage = 100;
    }
    if (percentage < 0) {
      percentage = 0;
    }
    $(".timeBar").css("width", percentage + "%");
    this.videoElement.nativeElement.currentTime =
      (maxduration * percentage) / 100;
  }

  getExportData(itemid, itemname) {
    this.exportitemid = itemid;
    this.exportitemname = itemname;
    this.videoservice
      .GetExportButton()
      .subscribe((data: exportConfigurationModel[]) => {
        if (data != null) {
          this.ExportList = data;
        }
      });
  }

  ExportVideo(exportid, filepath) {
    this.videoservice
      .InsertExportData(
        exportid,
        this.exportitemid,
        this.exportitemname,
        filepath,
        ""
      )
      .subscribe((data: any[]) => {
        if (data != null) {
          this.ExportEntry(this.exportitemid, exportid, this.exportitemname);
          this.exportitemid = 0;
          this.exportitemname = "";
          this.messageservice.add({
            key: "message1",
            severity: "info",
            summary: "Success Message",
            detail: "Successfully Export",
          });
        }
      });
  }

  showNoOfDownloadvideo(itemid) {
    // this.getdowloadimagelist=downloadList
    this.videoservice
      .GetDownloadedVideoData(itemid)
      .subscribe((data: any[]) => {
        if (data != null) {
          this.downloadVideoList = data;
          this.showVideoDownload = true;
        }
      });
  }

  ExportEntry(itemid, exportid, filename) {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.videoservice
      .ExportEntry(itemid, exportid, filename, userid)
      .subscribe((data: any[]) => {});
  }
  clearvideo() {
    document.getElementById("markslider").innerHTML = "";
    this.durationarray = [];
    this.markinArray = [];
    this.totaldurarray = [];
    this.ButtonStatus = "MarkIn";
  }

  hidevideo() {
    this.videoElement.nativeElement.src = "";
    document.getElementById("markslider").innerHTML = "";
    this.ButtonStatus = "MarkIn";
    this.viewVideo = false;
    this.showfulldiv();
  }

  updatevideopopup(itemid, name, e) {
    if (e.ctrlKey == false && e.metaKey == false) {
      this.videoupdate = true;
      this.videofileforup = name;
      this.videoidforedit = itemid;
      this.orgname = name;
      //this.updatedocument(documentList)
    }
  }

  hidevideoedit() {
    this.videoidforedit = "";
    this.orgname = "";
    this.videoupdate = false;
    this.showfulldiv();
  }

  //video popup//

  getstorydata(itemid, e, path) {
    if (e.ctrlKey == false && e.metaKey == false && e.shiftKey == false) {
      if (path.toLowerCase().indexOf("home/itg_events") > -1) {
        path = "event";
      } else {
        path = "";
      }
      this.dashboarservice
        .getstorydata(itemid, path)
        .subscribe((data: any[]) => {
          if (data != null) {
            this.fullstory(data[0]);
          }
        });
    }
  }

  fullstory(storylist) {
    // this.headlineFont=storylist.cssclass
    // this.storyfont=storylist.cssclass
    this.fullstorylist = storylist;
    this.headline = storylist.itemcaption;
    this.ratingitemstory = storylist.itemrating;
    this.storyread(storylist.itemfilename, storylist.HiresPath);
    this.viewStory = true;
    this.getAttachment(storylist.itemid, this.attachmentlist);
  }

  closestory() {
    this.viewStory = false;
  }
  storyread(filename, filepath) {
    this.storyservice
      .getTextFileContend(filename, filepath)
      .subscribe((data: any) => {
        if (data != null) {
          this.text = data.replace(new RegExp("\n", "g"), "<br/>");
          this.text = this.text.replace(new RegExp("\r\n", "g"), "<br/>");
        }
      });
  }

  getAttachment(parentid, attachmentlist) {
    // this.selectedAttachmentlist=attachmentlist
    // document.getElementById("markslider").innerHTML +=;
    this.storyservice.getAttachment(parentid).subscribe((data: any) => {
      if (data != null) {
        this.selectedAttachmentlist = data;
        //alert(data)
      }
    });
  }

  downloadAttachment(filename, filepath, itemid) {
    this.storyservice
      .Testdownload(filename, filepath, itemid)
      .subscribe((data: any) => {
        if (data != null) {
          var b64Data1 = data.split(";")[1];
          const byteCharacters = atob(b64Data1);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "document/*" });
          //let byteCharacters = atob(data);

          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        }
        this.dashboarservice
          .createlog(
            itemid,
            "Download",
            JSON.parse(localStorage.getItem("userdetails"))[0]["UserId"],
            "transaction_story"
          )
          .subscribe((data: any) => {});
        //this.downloadentry(itemid)
      });
  }

  getBackground(color) {
    return this.sanitizer.bypassSecurityTrustStyle(color);
  }
  exportExcel1() {
    var tmparr = [];
    var tmparr2 = [];
    tmparr2[0] = [];
    for (let i = 0; i < this.logcolumns.length; i++) {
      this.columnsset.push("Filename", "Filepath", "FileType");
      if (this.columnsset.indexOf("Filename") > -1) {
        tmparr2[0].Filename = this.logcolumns[i].orgfilename;
      }
      if (this.columnsset.indexOf("Filepath") > -1) {
        tmparr2[0].Filepath = this.logcolumns[i].OriginalPath;
      }
      if (this.columnsset.indexOf("FileType") > -1) {
        tmparr2[0].Filetype = this.logcolumns[i].itemformat;
      }
      tmparr.push(tmparr2[0]);
      tmparr2[0] = [];
    }

    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tmparr);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      this.saveAsExcelFile(excelBuffer, "FileReport");
    });
  }

  saveAsExcelFile1(buffer: any, fileName: string): void {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  }

  exportExcel() {
    var tmparr = [];
    var tmparr1 = [];
    tmparr1[0] = [];
    for (let i = 0; i < this.dataview1._value.length; i++) {
      var tmpdate = "";

      if (this.dataview1._value[i].itemtype == "image") {
        tmpdate = this.dataview1._value[i].shootdatemod;
      } else {
        tmpdate = this.dataview1._value[i].Udate;
      }
      if (this.selectedtreecolumn.indexOf("filenamecol") > -1) {
        tmparr1[0].Filename = this.dataview1._value[i].orgfilename;
      }
      if (this.selectedtreecolumn.indexOf("datecol") > -1) {
        tmparr1[0].Date = tmpdate;
      }
      if (this.selectedtreecolumn.indexOf("descriptioncol") > -1) {
        tmparr1[0].Description = this.dataview1._value[i].itemcaption;
      }
      if (this.selectedtreecolumn.indexOf("keywordcol") > -1) {
        tmparr1[0].Keywords = this.dataview1._value[i].itemkeyword;
      }
      if (this.selectedtreecolumn.indexOf("createdcol") > -1) {
        tmparr1[0].CreatedDate = this.dataview1._value[i].uploaddate;
      }
      if (this.selectedtreecolumn.indexOf("uploadbycol") > -1) {
        tmparr1[0].UploadBy = this.dataview1._value[i].uploadby;
      }
      if (this.selectedtreecolumn.indexOf("lastmodifycol") > -1) {
        tmparr1[0].LastModifyDate = this.dataview1._value[i].lastmodifydate;
      }
      if (this.selectedtreecolumn.indexOf("lastmodifiercol") > -1) {
        tmparr1[0].LastModifyBy = this.dataview1._value[i].lastmodifyby;
      }
      if (this.selectedtreecolumn.indexOf("pagenocol") > -1) {
        tmparr1[0].Pages = this.dataview1._value[i].samplerate;
      }
      if (this.selectedtreecolumn.indexOf("noofpagescol") > -1) {
        tmparr1[0].NoOfPages = this.dataview1._value[i].totalbitrate;
      }
      if (this.selectedtreecolumn.indexOf("collectioncol") > -1) {
        tmparr1[0].Path = this.dataview1._value[i].collpath;
      }
      if (this.selectedtreecolumn.indexOf("authorcol") > -1) {
        tmparr1[0].Path = this.dataview1._value[i].itemauthor;
      }
      if (this.selectedtreecolumn.indexOf("magsectioncol") > -1) {
        tmparr1[0].Path = this.dataview1._value[i].composer;
      }
      if (this.selectedtreecolumn.indexOf("sourcecol") > -1) {
        tmparr1[0].Path = this.dataview1._value[i].source;
      }
      if (this.selectedtreecolumn.indexOf("bysubjectcol") > -1) {
        tmparr1[0].Path = this.dataview1._value[i].bysubject;
      }
      if (this.selectedtreecolumn.indexOf("titlecol") > -1) {
        tmparr1[0].Path = this.dataview1._value[i].title;
      }
      if (this.selectedtreecolumn.indexOf("photographercol") > -1) {
        tmparr1[0].Path = this.dataview1._value[i].creator;
      }
      if (this.selectedtreecolumn.indexOf("Yearcol") > -1) {
        tmparr1[0].Path = this.dataview1._value[i].itemyear;
      }

      //this.selectedtreecolumn.push("filenamecol","datecol","descriptioncol","createdcol","uploadbycol","lastmodifycol","lastmodifiercol","actioncol","pagenocol","noofpagescol","collectioncol","keywordcol")
      tmparr.push(tmparr1[0]);
      tmparr1[0] = [];
    }
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tmparr);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      this.saveAsExcelFile(excelBuffer, "Report");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  }

  showperfrence() {
    this.colperferencediv = true;
  }
  savetableperfrence() {
    var userperf = JSON.parse(localStorage.getItem("userperference"));
    if (userperf.length == 0) {
      userperf["tablecol"] = JSON.stringify(this.selectedtreecolumn);
    } else {
      userperf[0].tablecol = JSON.stringify(this.selectedtreecolumn);
    }

    localStorage.removeItem("userperference");
    localStorage.setItem("userperference", JSON.stringify(userperf));
    //JSON.parse(localStorage.getItem("userperference"))[0].tablecol=JSON.stringify(this.selectedtreecolumn)
    this.userser
      .saveperference(
        this.rowperpage,
        this.tablestyle,
        JSON.stringify(this.selectedtreecolumn)
      )
      .subscribe((data: any[]) => {
        this.colperferencediv = false;
      });
  }
  hidecolperference() {
    this.colperferencediv = false;
  }
  autoComplete($event) {
    $("#ulautocomplete").show();
    let search1 = (<HTMLInputElement>document.getElementById("userIdFirstWay"))
      .value;
    var search = search1.split(" ")[search1.split(" ").length - 1];
    if (search.length > 2) {
      this.dashboarservice.getdictionary(search).subscribe((response) => {
        this.autoCompletArr = response.map((item) => {
          return item.dictword;
        });
      });
    }
  }

  setsearchkey(e) {
    this.srchtext = this.srchtext.substring(0, this.srchtext.lastIndexOf(" "));
    this.srchtext = this.srchtext.trim() + " " + e.target.innerText;
    $("#ulautocomplete").hide();
  }

  checkrelationid() {
    this.imageservice
      .checkrelationid(this.relationname)
      .subscribe((data: any) => {
        if (data.length > 0) {
          if (
            confirm(
              "Group exist do you want to merge these item(s) into this group?"
            )
          ) {
            this.setrealtionshipmul();
          } else {
          }
        } else {
          this.setrealtionshipmul();
        }
      });
  }

  setrealtionshipmul() {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    var lst = this.selectedIndexsid;
    var lst1 = this.selectedIndexstype;
    this.imageservice
      .setrealtionshipall(this.relationname, lst, lst1, userid, "archieve")
      .subscribe((data: any) => {
        if (data != null) {
          this.messageservice.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail: "Relationed Successfully",
          });
          this.clearrelation();
        }
      });
  }

  clearrelation() {
    this.relationname = null;
    this.showrelation = false;
    this.removeselection();
    this.rightclickmenuopen = false;
    this.ismenuclick = false;
  }

  showmulrelationpopup() {
    this.showrelation = true;
    this.ismenuclick = true;
  }

  getrelationlist() {
    this.relationlist = [];
    this.imageservice.allrealtionlist().subscribe((data: any[]) => {
      if (data != null) {
        for (let i = 0; i < data.length; i++) {
          this.relationlist.push(data[i].groupname);
        }
      }
    });
  }

  filterrealtion(event) {
    this.filteredrelation = [];
    if (event.query != "") {
      //this.glbkeywordsgst=event.query
      this.imageservice
        .getrelationsrch(event.query)
        .subscribe((data: any[]) => {
          if (data.length > 0) {
            this.relationlist = [];

            for (let i = 0; i < data.length; i++) {
              let brand = data[i].groupname;
              this.filteredrelation.push(brand);
              this.relationlist.push(brand);
            }
          }
        });
    } else {
      for (let i = 0; i < this.relationlist.length; i++) {
        let brand = this.relationlist[i];
        if (brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
          this.filteredrelation.push(brand);
        }
      }
    }
  }

  showperpopup() {
    this.selectedcollpath = this.tcmnodekey.node.data;
    this.dashboarservice
      .gettreeper(this.selectedkey)
      .subscribe((data: any[]) => {
        if (data != null) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].UserId != "53") {
              this.treeusers.push(data[i].UserId);

              // console.log("array", this.treeusers);
            }
          }
          //this.treeusers=data
          this.treeperpopup = true;
        }
      });
  }

  chnguserper(e) {
    // console.log(e)
  }

  savetreepermission() {
    var users = "";
    if (this.treeusers.length > 0) {
      for (let i = 0; i < this.treeusers.length; i++) {
        if (i != this.treeusers.length - 1) {
          users += "," + this.treeusers[i];
        }
        if (i == this.treeusers.length - 1) {
          users += "," + this.treeusers[i] + ",";
          this.dashboarservice
            .savetreepermission(users, this.selectedcollpath)
            .subscribe((data: any) => {
              if (data != null) {
                this.messageservice.add({
                  key: "message",
                  severity: "success",
                  summary: "Success Message",
                  detail: "Permissions Assigned",
                });
                this.treeperpopup = false;
                this.selectedcollpath = "";
              }
            });
        }
      }
    } else {
      this.dashboarservice
        .savetreepermission(",", this.selectedcollpath)
        .subscribe((data: any) => {
          if (data != null) {
            this.messageservice.add({
              key: "message",
              severity: "success",
              summary: "Success Message",
              detail: "Permissions Assigned",
            });
            this.treeperpopup = false;
            this.selectedcollpath = "";
          }
        });
    }
  }

  cleartreeper() {
    this.treeusers = [];
    this.selectedcollpath = "";
  }

  deleteImage(itemid, orgfilename, $event) {
    this.confirmationService.confirm({
      message: "Are you sure that you want to Delete?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        // console.log(itemid, orgfilename);
        this.imageservice.TrashImages(itemid).subscribe((data: any) => {
          this.messageservice.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail:
              "File Deleted Successfully............. Please refresh folder",
          });
        });
      },
    });
  }

  deleteAuio(itemid, orgfilename, $event) {
    this.confirmationService.confirm({
      message: "Are you sure that you want to Delete?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.audiouploadservice.deleteAudio(itemid).subscribe((data: any) => {
          this.messageservice.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail:
              "File Deleted Successfully............. Please refresh folder",
          });
        });
      },
    });
  }
  deleteVideo(itemid, orgfilename, $event) {
    this.confirmationService.confirm({
      message: "Are you sure that you want to Delete?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.videoservice.deleteVideo(itemid).subscribe((data: any) => {
          this.messageservice.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail:
              "File Deleted Successfully............. Please refresh folder",
          });
        });
      },
    });
  }

  deleteDocument(itemid, orgfilename, $event) {
    this.confirmationService.confirm({
      message: "Are you sure that you want to Delete?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.documentservice.deleteDocument(itemid).subscribe((data: any) => {
          this.messageservice.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail:
              "File Deleted Successfully............. Please refresh folder",
          });
        });
      },
    });
  }
}
