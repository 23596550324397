import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl,Validators  } from '@angular/forms';
import { ImageuploadService } from 'src/app/services/files/imageupload.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageUploadFormModel } from 'src/app/model/file/imgaeuploadformModel';
import { ImageUploadComponent } from '../image-upload/image-upload.component';
import { MessageService } from 'primeng/api';
import { formatDate } from '@angular/common';
import { DashboardService } from 'src/app/services/files/dashboard.service';

@Component({
  selector: 'app-image-edit-form',
  templateUrl: './image-edit-form.component.html',
  styleUrls: ['./image-edit-form.component.css'],
  providers: [ImageUploadComponent]
})
export class ImageEditFormComponent implements OnInit {
  @Output() CloseDialogUpdate = new EventEmitter()
  @Output() closeForm = new EventEmitter<void>();
  Image_Edit_form: FormGroup
  selectedimage: ImageUploadFormModel;
  idforupdate:string=""
  imageuploadinformation: ImageUploadFormModel;
  collpath:string=""
  filteredkeywords: any[];
  allkeywords:any[]
  glbkeywordsgst:string=""
  keywords:any[]=[]
  orgnamearr:string=""

  constructor(public imageservice: ImageuploadService,public dashboadservice:DashboardService,  private formBuilder: FormBuilder,  public sanitizer: DomSanitizer,public imagecom:ImageUploadComponent,private messageservice: MessageService) { }

  ngOnInit() {
    this.CreateImageEditForm()
    //this.getallkeyword()
    this.selectedimage = new ImageUploadFormModel();
    // console.log("inside image edit form");
    
  }

  @Input()
  selecttab:string;
  @Input()
  selectValue: string;
  @Input()
  orgname:string
  @Input()
  multipleSelectedImage:boolean
  @Input()
  selectedImagesidss:string[]
  


  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectValue']) {
      if(this.selectValue !=""){
        this.idforupdate=this.selectValue
        this.orgnamearr=this.orgname
        this.updatepopup(this.idforupdate,this.selecttab)
      }
    
      console.log("selectedImageIds.....",this.selectedImagesidss);
      console.log("multipleSelectedImage value.....",this.multipleSelectedImage);
      
    }
    let keywordsub = this.dashboadservice.getkeywordlist().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.allkeywords = data
      }
      keywordsub.unsubscribe()
    })
}
  CreateImageEditForm() {
    this.Image_Edit_form = this.formBuilder.group({
      CategoryId: [""],
      SubCategoryId: [""],
      CountryId: [""],
      StateId: [""],
      CityId: [""],
      EventId: [""],
      OriginalPath: [""],
      itemfiename: [""],
      itemsize: [""],
      itemlength: [""],
      itemformat: [""],
      resolution: [""],
      itemcaption: [""],
      itemkeyword: [, [Validators.required]],
      location: [""],
      itemauthor: [""],
      eventdate: [""],
      itemrating: [""],
      title: ['', [Validators.required]],
      source: [""],
      photogrphername: [""],
      copyright: [""],
      orietation: [""],
      shootdate: [""],
      itemid: [""],
      itempermission:[null],
      userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId
    })
  }

  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }

  onClose() {
    this.closeForm.emit();
  }

  updateimages(imageeditlist) {
  // if (imageeditlist.itemkeyword) {
  //     imageeditlist.itemkeyword = imageeditlist.itemkeyword.split(',').map(keyword => keyword.trim());
  // } else {
  //   imageeditlist.itemkeyword= [];
  // }
  // Ensure itemkeyword is converted to an array properly
  if (imageeditlist.itemkeyword) {
    if (typeof imageeditlist.itemkeyword === 'string') {
      // Split the string into an array of trimmed keywords
      imageeditlist.itemkeyword = imageeditlist.itemkeyword
        .split(',')
        .map(keyword => ({ keywordname: keyword.trim() })) // Create object for each keyword
        .filter(keyword => keyword.keywordname); // Remove empty objects
    } else if (!Array.isArray(imageeditlist.itemkeyword)) {
      // If it's not a string or array, set it as an empty array
      imageeditlist.itemkeyword = [];
    }
  } else {
    // If itemkeyword is undefined or null, initialize as an empty array
    imageeditlist.itemkeyword = [];
  }

    if(this.idforupdate.split("~")[1] =="" || this.idforupdate.split("~")[1] ==undefined){
      this.collpath=imageeditlist.collpath
    }
    else{
      imageeditlist.orgfilename = ""
      this.collpath=""
    }
    this.keywords=[]
// if(imageeditlist.itemkeyword !=""){
//   for(let i=0;i<imageeditlist.itemkeyword.split(";").length;i++){
//     this.keywords.push({keywordname:imageeditlist.itemkeyword.split(";")[i].toString().trim()})
//   }
// }
    //this.getSubcategorydata(imageeditlist.categoryid)
    this.Image_Edit_form.setValue({
      itemid: imageeditlist.itemid,
      CategoryId: imageeditlist.categoryid,
      SubCategoryId: imageeditlist.subcategoryid,
      CountryId: imageeditlist.countryid,
      StateId: imageeditlist.stateid,
      CityId: imageeditlist.cityid,
      EventId: imageeditlist.eventname,
      OriginalPath: imageeditlist.OriginalPath,
      itemfiename: imageeditlist.orgfilename,
      itemsize: imageeditlist.itemsize,
      itemlength: imageeditlist.itemlength,
      itemformat: imageeditlist.itemformat,
      resolution: imageeditlist.resolution,
      itemcaption: imageeditlist.itemcaption,
      // itemkeyword: imageeditlist.itemkeyword ? imageeditlist.itemkeyword.split(',').map(keyword => keyword.trim()) : [],
      itemkeyword: imageeditlist.itemkeyword,
      location: imageeditlist.location,
      itemauthor: imageeditlist.itemauthor,
      eventdate: imageeditlist.eventdate,
      itemrating: imageeditlist.itemrating,
      title: imageeditlist.title,
      source: imageeditlist.Source,
      photogrphername: imageeditlist.creator,
      copyright: imageeditlist.copyright,
      orietation: imageeditlist.genre,
      shootdate: imageeditlist.shootdate,
      itempermission:imageeditlist.itempermission,
      userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId
    })
  }

  updatepopup(itemid,path) {
    if(itemid.indexOf("~")>-1){
      itemid=itemid.split("~")[0]
    }
    this.imageservice.getitems(itemid.trim(),path).subscribe((data: any[]) => {
      if (data.length>0) {
        //this.selectedimage = data[0]
        this.updateimages(data[0])
      }
    })
  }


  filenameupdatekey: boolean = false;
  SaveAfterUpdate(formval, event: any) {
    debugger;
    this.imageuploadinformation = formval.value;
    this.imageuploadinformation.orgfilename = this.orgnamearr;
    this.imageuploadinformation.Source = this.imageuploadinformation.source;
    this.imageuploadinformation.categoryid = this.imageuploadinformation.CategoryId;
    this.imageuploadinformation.subcategoryid = this.imageuploadinformation.SubCategoryId;
    this.imageuploadinformation.stateid = this.imageuploadinformation.StateId;
    this.imageuploadinformation.cityid = this.imageuploadinformation.CityId;
    this.imageuploadinformation.eventname = this.imageuploadinformation.EventId;
    this.imageuploadinformation.creator = this.imageuploadinformation.photogrphername;
    this.imageuploadinformation.genre = this.imageuploadinformation.orietation;
  
    if (this.imageuploadinformation.eventdate == "") {
      this.imageuploadinformation.eventdate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    }
    if (this.imageuploadinformation.itempermission == null) {
      this.imageuploadinformation.itempermission = "1";
    }
    let tmpkeywords = "";
    if (this.imageuploadinformation.itemkeyword && Array.isArray(this.imageuploadinformation.itemkeyword)) {
      for (let i = 0; i < this.imageuploadinformation.itemkeyword.length; i++) {
        tmpkeywords += this.imageuploadinformation.itemkeyword[i].keywordname + ", ";
      }
      this.imageuploadinformation.itemkeyword = tmpkeywords.trim().substring(0, tmpkeywords.trim().length - 1);
    } else {
      this.imageuploadinformation.itemkeyword = "";
    }


    // this.multipleSelectedImage = this.selectedImagesidss && this.selectedImagesidss.length > 0 ? true : false;
    

    if (formval.valid) {

        // const updatePromises = this.selectedImagesidss.map((imageId) => {
        //   const imageUpdateData = { ...this.imageuploadinformation, itemid: imageId };
        //   return this.imageservice.UpdateImages(imageUpdateData).toPromise();
        // });

        const updatePromises = this.selectedImagesidss && this.selectedImagesidss.length > 0
      ? this.selectedImagesidss.map((imageId) => {
          const imageUpdateData = { ...this.imageuploadinformation, itemid: imageId, filenameupdatekey:this.multipleSelectedImage };
          return this.imageservice.UpdateImages(imageUpdateData).toPromise();
        })
      : [this.imageservice.UpdateImages(this.imageuploadinformation).toPromise()];
      
        Promise.all(updatePromises)
        .then((responses) => {
          const allSuccessful = responses.every((data: any) => data != null);
          // console.log("save after promise and data",responses);
          
          // this.selectedImagesidss=[];
          
          if (allSuccessful) {
            this.messageservice.add({ key: 'message', severity: 'success', summary: 'Success Message', detail: 'Successfully Updated All Images' });
            this.CloseDialogUpdate.emit(event);
            this.glbkeywordsgst = "";
            this.keywords = [];
            const eventData = { message: this.imageuploadinformation };
            this.dashboadservice.emitEvent(eventData);
            this.Image_Edit_form.reset();
          } else {
            this.messageservice.add({ key: 'message', severity: 'warn', summary: 'Warn Message', detail: 'Some Updates Failed' });
          }
          // this.Image_Edit_form.reset();
          // this.selectedImagesidss=[];
        })
        .catch((error) => {
          console.error("Error updating images:", error);
          this.messageservice.add({ key: 'message', severity: 'error', summary: 'Error Message', detail: 'An Error Occurred While Updating' });
        })
        .then(() => {
          // Perform cleanup here instead of using `finally`
          this.dashboadservice.closeimagedialog();
          this.multipleSelectedImage = false;
          this.selectedImagesidss=[];
        });
        this.Image_Edit_form.reset();
        this.selectedImagesidss=[];
      
    }
    
    else {
      this.messageservice.add({ key: 'message', severity: 'warn', summary: 'Warn Message', detail: 'Please Add Title & Keywords' });
    }
    // this.Image_Edit_form.reset();
    // this.selectedImagesidss=[];
    
  }
  
  

  // currently working...........................................................
  SaveAfterUpdatee(formval,event:any) {
    debugger
    this.imageuploadinformation = formval.value
    this.imageuploadinformation.orgfilename=this.orgnamearr
    this.imageuploadinformation.Source=this.imageuploadinformation.source
    this.imageuploadinformation.categoryid=this.imageuploadinformation.CategoryId
    this.imageuploadinformation.subcategoryid=this.imageuploadinformation.SubCategoryId
    this.imageuploadinformation.stateid=this.imageuploadinformation.StateId
    this.imageuploadinformation.cityid=this.imageuploadinformation.CityId
    this.imageuploadinformation.eventname=this.imageuploadinformation.EventId
    this.imageuploadinformation.creator=this.imageuploadinformation.photogrphername
    this.imageuploadinformation.genre=this.imageuploadinformation.orietation
    
    if(this.imageuploadinformation.eventdate==""){
      this.imageuploadinformation.eventdate=formatDate(new Date(), 'yyyy-MM-dd', 'en');
    }
    if(this.imageuploadinformation.itempermission==null){
      this.imageuploadinformation.itempermission="1"
    }
    var tmpkeywords=""
    if (this.imageuploadinformation.itemkeyword && Array.isArray(this.imageuploadinformation.itemkeyword)) {
      for (let i = 0; i < this.imageuploadinformation.itemkeyword.length; i++) {
        tmpkeywords += this.imageuploadinformation.itemkeyword[i].keywordname + ", ";
      }
      this.imageuploadinformation.itemkeyword = tmpkeywords.trim().substring(0, tmpkeywords.trim().length - 1);
    } else {
      this.imageuploadinformation.itemkeyword = "";
    }
    
      // for(let i=0;i<this.imageuploadinformation.itemkeyword.length;i++){
      //   tmpkeywords +=this.imageuploadinformation.itemkeyword[i].keywordname +", "
      // }
      // this.imageuploadinformation.itemkeyword=tmpkeywords.trim().substring(0,tmpkeywords.trim().length-1)

      this.imageuploadinformation.itemid = this.idforupdate

      if (formval.valid){
        this.imageservice.UpdateImages(this.imageuploadinformation).subscribe((data: any) => {
          if (data != null) {
            this.messageservice.add({ key: 'message', severity: 'success', summary: 'Success Message', detail: 'Successfully Updated' });
            this.CloseDialogUpdate.emit(event);
             // Revert itemkeyword back to an array of objects for UI compatibility
                // if (tmpkeywords) {
                //     this.imageuploadinformation.itemkeyword = tmpkeywords.split(',').map((keyword: string) => ({ keywordname: keyword.trim() }));
                // } else {
                //     this.imageuploadinformation.itemkeyword = [];
                // }
            this.glbkeywordsgst=""
            this.keywords=[];
            const eventData = { message:this.imageuploadinformation };
            this.dashboadservice.emitEvent(eventData);
            this.Image_Edit_form.reset();
          }  
          else {
            this.messageservice.add({ key: 'message', severity: 'warn', summary: 'Warn Message', detail: 'Updation Failed' });
          }
        });
        //close the meta data edit popup here
        this.dashboadservice.closeimagedialog();
        // this.dashboadservice.getFolderData('');
      } else {
        this.messageservice.add({ key: 'message', severity: 'warn', summary: 'Warn Message', detail: 'Please Add Title & Keywords' });
      }
  }


  //older function 
// SaveAfterUpdate(formval, event: any) {
//     this.imageuploadinformation = formval.value;
//     this.imageuploadinformation.itemid = this.idforupdate;
//     this.imageuploadinformation.orgfilename = this.orgnamearr;
//     this.imageuploadinformation.Source = this.imageuploadinformation.source;
//     this.imageuploadinformation.categoryid = this.imageuploadinformation.CategoryId;
//     this.imageuploadinformation.subcategoryid = this.imageuploadinformation.SubCategoryId;
//     this.imageuploadinformation.stateid = this.imageuploadinformation.StateId;
//     this.imageuploadinformation.cityid = this.imageuploadinformation.CityId;
//     this.imageuploadinformation.eventname = this.imageuploadinformation.EventId;
//     this.imageuploadinformation.creator = this.imageuploadinformation.photogrphername;
//     this.imageuploadinformation.genre = this.imageuploadinformation.orietation;

//     if (this.imageuploadinformation.eventdate === "") {
//         this.imageuploadinformation.eventdate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
//     }
//     if (this.imageuploadinformation.itempermission == null) {
//         this.imageuploadinformation.itempermission = "1";
//     }

//     let tmpkeywords = "";
//     if (this.imageuploadinformation.itemkeyword && Array.isArray(this.imageuploadinformation.itemkeyword)) {
//         for (let i = 0; i < this.imageuploadinformation.itemkeyword.length; i++) {
//             tmpkeywords += this.imageuploadinformation.itemkeyword[i].keywordname + ", ";
//         }
//         this.imageuploadinformation.itemkeyword = tmpkeywords.trim().substring(0, tmpkeywords.trim().length - 1);
//     } else {
//         this.imageuploadinformation.itemkeyword = "";
//     }

//     if (formval.valid) {
//         this.imageservice.UpdateImages(this.imageuploadinformation).subscribe((data: any) => {
//             if (data != null) {
//                 this.messageservice.add({
//                     key: 'message',
//                     severity: 'success',
//                     summary: 'Success Message',
//                     detail: 'Successfully Updated',
//                 });

//                 // Convert the string back into an array for the autocomplete component
//                 if (tmpkeywords) {
//                     this.imageuploadinformation.itemkeyword = tmpkeywords.split(',').map((keyword: string) => ({
//                         keywordname: keyword.trim()
//                     }));
//                 } else {
//                     this.imageuploadinformation.itemkeyword = [];
//                 }

//                 // Patch the keywords back into the form
//                 this.Image_Edit_form.patchValue({
//                     itemkeyword: this.imageuploadinformation.itemkeyword,
//                 });

//                 this.glbkeywordsgst = "";
//                 this.keywords = [];
//                 const eventData = { message: this.imageuploadinformation };
//                 this.dashboadservice.emitEvent(eventData);
//                 this.Image_Edit_form.reset();
//             } else {
//                 this.messageservice.add({
//                     key: 'message',
//                     severity: 'warn',
//                     summary: 'Warn Message',
//                     detail: 'Updation Failed',
//                 });
//             }
//         });

//         // Close the metadata edit popup
//         this.dashboadservice.closeimagedialog();
//     } else {
//         this.messageservice.add({
//             key: 'message',
//             severity: 'warn',
//             summary: 'Warn Message',
//             detail: 'Please Add Title & Keywords',
//         });
//     }
// }


  
  getallkeyword(){
    this.dashboadservice.getkeywords("").subscribe((data:any[])=>{
      if(data.length>0){
        this.allkeywords=data
      }
    })
  }

  // filterkeyword(e){
  //   if(e.query !=""){
  //     this.glbkeywordsgst=e.query
  //     this.dashboadservice.getkeywords(this.glbkeywordsgst).subscribe((data:any[])=>{
  //       if(data.length>0){
  //         this.allkeywords=data
  //       }
  //     })
  //   }
    
  //   let query = e.query;
  //   // this.filteredkeywords = this.filterCountry(query, this.allkeywords);
  //   this.filteredkeywords = this.allkeywords;
        
  // }
  filterkeyword(e: any) {
    if (e.query && e.query.trim() !== "") {
        this.glbkeywordsgst = e.query.trim(); // Avoid extra spaces in the query

        // Fetch keywords from the service
        this.dashboadservice.getkeywords(this.glbkeywordsgst).subscribe((data: any[]) => {
            if (Array.isArray(data) && data.length > 0) {
                // Update all keywords with the service response
                this.allkeywords = data;
            } else {
                // If no results from API, include the query in the keywords list
                const newKeyword = { keywordname: e.query.trim() }; // Create a new keyword object
                const isExisting = this.allkeywords.some(
                    (keyword) => keyword.keywordname.toLowerCase() === newKeyword.keywordname.toLowerCase()
                );

                // Avoid duplicates in the keywords array
                if (!isExisting) {
                    this.allkeywords = [...(this.allkeywords || []), newKeyword];
                }
            }
            this.updateFilteredKeywords(e.query); // Update filtered keywords based on the query
        });
    } else {
        // Clear suggestions if query is empty
        this.filteredkeywords = [];
    }
}

// Helper method to filter keywords locally
updateFilteredKeywords(query: string) {
    this.filteredkeywords = (this.allkeywords || []).filter((keyword) =>
        keyword.keywordname.toLowerCase().includes(query.toLowerCase())
    );
}



  filterCountry(query, countries: any[]):any[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered : any[] = [];
    if(countries){
      for(let i = 0; i < countries.length; i++) {
          let country = countries[i];
          if(country.keywordname.toLowerCase().indexOf(query.toLowerCase()) == 0) {
              filtered.push(country);
          }
      }
    }
    return filtered;
}




}
